import React from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";

const cnBaseInput = classnames(
  "rounded",
  "p-2",
  "border",
  "border-gray-300",
  "placeholder-gray-400",
  "w-full"
);
const cnBaseLabel = classnames("mb-1", "block");

const DSInput = ({
  label,
  id,
  value,
  placeholder,
  error,
  errorHint,
  type,
  name,
  onChange,
  onClick,
  onKeyUp,
  disabled,
  autocomplete,
  readOnly,
}) => {
  const cnLabel = classnames(cnBaseLabel, {
    "text-danger": error,
  });

  const cnInput = classnames(cnBaseInput, {
    "border-danger": error,
    "bg-white": !disabled,
    "bg-gray-200": disabled,
  });

  return (
    <div className="w-full text-left">
      { label &&
        <label htmlFor={id} className={cnLabel}>
          {label}
        </label>
      }
      <input
        type={type}
        className={cnInput}
        id={id}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onClick={onClick}
        onKeyUp={onKeyUp}
        disabled={disabled}
        autoComplete={autocomplete}
        readOnly={readOnly}
      />
      {error && errorHint && (
        <p className="text-xs mt-1 text-danger">{errorHint}</p>
      )}
    </div>
  );
};

DSInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorHint: PropTypes.string,
  type: PropTypes.oneOf(["text", "email", "password"]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  readOnly: PropTypes.bool,
};

DSInput.defaultProps = {
  id: null,
  label: null,
  value: undefined,
  placeholder: null,
  error: false,
  errorHint: null,
  type: "text",
  name: null,
  onChange: null,
  onClick: null,
  onKeyUp: null,
  disabled: false,
  autocomplete: "on",
  readOnly: false,
};

export default DSInput;
