import React from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";

const cnContainer = classnames("w-full", "text-left");
const cnBaseInput = classnames(
  "rounded",
  "p-2",
  "border",
  "border-gray-300",
  "placeholder-gray-400",
  "w-full"
);
const cnBaseLabel = classnames("mb-1", "block");
const cnError = classnames("text-xs", "mt-1", "text-danger")

const DSSelect = ({
  label,
  id,
  value,
  placeholder,
  error,
  errorHint,
  name,
  onChange,
  disabled,
  options,
}) => {
  const cnLabel = classnames(cnBaseLabel, {
    "text-danger": error,
  });

  const cnInput = classnames(cnBaseInput, {
    "border-danger": error,
    "bg-white": !disabled,
    "bg-gray-200": disabled,
  });

  const props = {
    className: cnInput,
    id,
    placeholder,
    name,
    onChange,
    disabled,
  }

  if (value)
    Object.assign(props, { value })

  return (
    <div className={cnContainer}>
      { label &&
        <label htmlFor={id} className={cnLabel}>
          {label}
        </label>
      }
      <select {...props}>
        {
          /* eslint-disable react/prop-types */
          options.map((d) => (
            <option key={d.value} value={d.value}>{d.label}</option>
          ))
        }
      </select>
      {error && errorHint && (
        <p className={cnError}>{errorHint}</p>
      )}
    </div>
  );
};

DSSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorHint: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
      ]),
      label: PropTypes.string,
    })
  ).isRequired
};

DSSelect.defaultProps = {
  value: '',
  label: null,
  placeholder: null,
  error: false,
  errorHint: null,
  onChange: null,
  disabled: false,
};

export default DSSelect;
