const initialState = {};

const actions = {
  SET_KEYWORD: "SET_KEYWORD",
  UPDATE_COLUMNS: "UPDATE_COLUMNS",
};

const SankeyReducerActions = Object.freeze(actions);

/* Actions */
const setKeywordAction = ({ keyword }) => ({
  type: SankeyReducerActions.SET_KEYWORD,
  payload: { ...keyword },
});

const updateColumnsAction = (params) => ({
  type: SankeyReducerActions.UPDATE_COLUMNS,
  payload: params,
});

/* Reducer */
const sankeyReducer = (state, action) => {
  switch (action.type) {
    case SankeyReducerActions.SET_KEYWORD: {
      const { keyword_id: id, value: keyword } = action.payload;
      return { ...state, id, columns: [keyword] };
    }
    case SankeyReducerActions.UPDATE_COLUMNS: {
      const { index, value } = action.payload;
      const columns = state.columns.slice(0, index);
      columns.push(value);
      return { ...state, columns };
    }
    default:
      return state;
  }
};

export {
  initialState,
  SankeyReducerActions,
  setKeywordAction,
  updateColumnsAction,
};
export default sankeyReducer;
