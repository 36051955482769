import * as d3 from 'd3';

const GraphMaxRepetitions = (
  element,
  topTen,
) => {
	const { scrollHeight: height } = element;
	const { scrollHeight: width } = element;

  const radarData = [topTen.reduce((acc, { relations, count }) => {
    acc[relations] = count;
    return acc
  }, {})];

  const svg = d3.select(element)
    .attr("viewBox", `-${width} -${height} ${width * 2} ${height * 2}`)
    .attr("width", width)
    .attr("height", height)
    .style("text-align", "center");

  svg.selectAll("g").remove();

  const radialScale = d3.scaleLinear()
    .domain([0, topTen[0]?.count])
    .range([0, height - 200]);

  function angleToCoordinate(angle, value) {
    const x = Math.cos(angle) * radialScale(value);
    const y = Math.sin(angle) * radialScale(value);
    return {"x": width / 2 + x, "y": height / 2 - y};
  }

  function getPathCoordinates(dataPoint) {
    const coordinates = [];
    for (let i = 0; i < topTen.length; i += 1) {
      const ftName = topTen[i].relations;
      const angle = (Math.PI / 2) + (2 * Math.PI * i / topTen.length);
      coordinates.push(angleToCoordinate(angle, dataPoint[ftName]));
    }
    return coordinates;
  }

  const line = d3.line()
    .x(d => d.x)
    .y(d => d.y);

  const colors = ["#e60000"];

  const radar = svg.append('g').attr('style', 'transform: translate(-25%, -25%)');

  // draw the path element
  radar.selectAll("path")
    .data(radarData)
    .join(
      enter => enter.append("path")
        .datum(d => getPathCoordinates(d))
        .attr("d", line)
        .attr("stroke-width", 3)
        .attr("stroke", (_, i) => colors[i])
        .attr("fill", (_, i) => colors[i])
        .attr("stroke-opacity", 1)
        .attr("opacity", 0.5)
    );

  const ticks = topTen.reduce((acc, { count }) => {
    if (!acc.includes(count)) acc.push(count);
    return acc;
  }, []);

  const container = svg.append('g');

  container.selectAll("circle")
    .data(ticks)
    .join(
      enter => enter.append("circle")
        .attr("cy", width / 2)
        .attr("cy", 0)
        .attr("fill", "none")
        .attr("stroke", "#ddd")
        .attr("r", d => radialScale(d))
    );

  container.selectAll(".ticklabel")
    .data(ticks)
    .join(
      enter => enter.append("text")
        .style("font-size", "20px")
        .attr("x", 0)
        .attr("y", d => - radialScale(d) - 2)
        .text(d => d.toString())
    );

  const featureData = topTen.map(({ relations }, i) => {
    const angle = (Math.PI / 2) + (2 * Math.PI * i / topTen.length);
    return {
      "name": relations,
      "angle": angle,
      "line_coord": angleToCoordinate(angle, topTen[0].count),
      "label_coord": angleToCoordinate(angle, topTen[0].count + 5)
    };
  });

  const data = svg.append('g').attr('style', 'transform: translate(-25%, -25%)');

  // draw axis line
  data.selectAll("line")
    .data(featureData)
    .join(
      enter => enter.append("line")
        .attr("x1", width / 2)
        .attr("y1", height / 2)
        .attr("x2", d => d.line_coord.x)
        .attr("y2", d => d.line_coord.y)
        .attr("stroke", "#ccc")
    );

  // draw axis label
  data.selectAll(".axislabel")
    .data(featureData)
    .join(
      enter => enter.append("text")
        .attr("x", d => d.label_coord.x)
        .attr("y", d => d.label_coord.y)
        .style("font-size", "22px")
        .style("font-weight", "bold")
        .text(d => d.name)
    );

  return svg.node();
};

export default GraphMaxRepetitions;