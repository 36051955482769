import axios from "axios";
import manageStorage from "hooks/manageStorage";
import refreshToken from "hooks/refreshToken";

const { get } = manageStorage();

const getUsersKeywords = (params) => {
  const urlSearchParams = new URLSearchParams(params);

  refreshToken();

  return axios
    .get(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/userKeyword?${urlSearchParams}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${get().data.tokens.access.token}`,
          "content-type": "application/json",
        },
      }
    )
    .then((res) => res);
}

const updateUsersKeywords = ({ params, values }) => {
  const urlSearchParams = new URLSearchParams(params);

  refreshToken();

  return axios
    .patch(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/userKeyword?${urlSearchParams}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${get().data.tokens.access.token}`,
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);
};

export { getUsersKeywords, updateUsersKeywords };
