import React from "react";
import PropTypes from "prop-types";

const DSFormRadio = ({ label, name, value, onChange }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="inline-flex items-center space-x-3">
    <input
      type="radio"
      className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      name={name}
      value={value}
      onChange={onChange}
    />
    <span>{label}</span>
  </label>
);

DSFormRadio.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

DSFormRadio.defaultProps = {
  onChange: null,
};

export default DSFormRadio;
