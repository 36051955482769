import axios from "axios";
import manageStorage from "hooks/manageStorage";
import refreshToken from "hooks/refreshToken";

const { get } = manageStorage();

const generateKeyword = (values) => {
  refreshToken();

  return axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/scrapinghubJob`,
      values,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${get().data.tokens.access.token}`,
          "content-type": "application/json",
        },
      }
    )
    .then((res) => res);
};

export default generateKeyword;
