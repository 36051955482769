import React from "react";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";

const cnBase = classnames("rounded px-3 py-2");
const cnVariantPrimary = classnames(
  "border",
  "border-primary",
  "bg-primary",
  "text-white",
  "transition",
  "transition-colors",
  "hover:bg-white",
  "hover:text-primary"
);
const cnVariantSecondary = classnames(
  "bg-white",
  "text-primary",
  "hover:bg-primary",
  "hover:text-white"
);
const cnVariantDisabled = classnames(
  "bg-gray-300",
  "border-gray-300",
  "pointer-events-none",
  "text-gray-400"
);

// eslint-disable-next-line react/prop-types
const DSButton = ({
  variant,
  type,
  children,
  className,
  disabled = false,
  onClick,
}) => {
  const cn = classnames(
    cnBase,
    {
      [cnVariantPrimary]: variant === "primary",
      [cnVariantSecondary]: variant === "secondary",
    },
    {
      [cnVariantDisabled]: disabled === true,
    },
    className
  );

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={cn} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

DSButton.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]),
  type: PropTypes.oneOf(["button", "submit"]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

DSButton.defaultProps = {
  variant: "primary",
  type: "button",
  disabled: false,
  onClick: null,
  className: null,
};

export default DSButton;
