import React, { useContext } from "react";
import { useQuery } from "react-query";
// import PropTypes from "prop-types";
import { DSKeyword } from "components/DS";
import SankeyContext from "routes/Sankey/SankeyContext";
import { getKeywordRelations } from "api/keywordRelations";

/* DSKeyword Wrapper */
const SankeyKeywordRelation = ({ keyword, onClick, highlight, iconList }) => {
  const { state } = useContext(SankeyContext);

  const { data, isLoading } = useQuery(
    ["sankeyKeywordRelations", state.id, keyword],
    () =>
      getKeywordRelations({
        keyword_id: state.id,
        keyword,
      })
  );


  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <DSKeyword
      keyword={keyword}
      onClick={onClick}
      highlight={highlight}
      iconList={iconList}
      weight={data?.data?.results[0]?.weight * 10 || 0}
      loadingInfo={isLoading}
    />
  );
};

SankeyKeywordRelation.propTypes = {
  ...DSKeyword.propTypes,
};

export default SankeyKeywordRelation;
