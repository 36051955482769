/* eslint no-underscore-dangle: 0 */
import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";
import { useFormik } from "formik";
import * as Yup from "yup";

import { getUsers, deleteUser } from "api/users";

import NotificationContext from "context/NotificationsContext";

import { DSButton, DSIcon, DSInput, DSSelect } from "components/DS";

import { LANG } from 'hooks/manageLanguage';

const searchSchema = Yup.object().shape({
  searchBy: Yup.string().required(LANG.usersList.form.errors.searchBy),
  searchValue: Yup.string().required(LANG.usersList.form.errors.searchValue),
});

const cnButtonBase = classnames(
  "flex",
  "items-center",
  "justify-center",
  "w-9",
  "h-9",
  "rounded-full",
  "ml-2",
  "border",
  "transition",
  "transition-colors",
);
const cnReadOnly = classnames("sr-only");
const cnButtonIcon = classnames(
  "w-6",
  "h-auto",
);
const cnLegend = classnames(
  "text-xl",
  "bg-gray-50",
  "pt-10",
  "pb-8",
  "w-full",
  "text-center",
);
const cnListTitles = classnames(
  "w-full",
  "flex",
  "items-center",
  "justify-between",
  "bg-gray-600",
  "text-white",
  "py-3",
);
const cnListStatusContainer = classnames(
  "min-w-2",
  "text-right",
  "px-4",
  "flex",
  "justify-start",
  "text-0",
);
const cnListStatus = classnames(
  "rounded-xl",
  "w-2",
  "h-2",
  "text-0"
);
const cnListTitlesBase = classnames(
  "w-2/6",
  "text-left",
  "px-4",
);
const cnListTitlesActions = classnames(
  cnListTitlesBase,
  "flex",
  "justify-end",
);
const cnListElementContentBase = classnames(
  "w-full",
  "flex",
  "items-center",
  "justify-between",
  "py-3",
);
const cnListContentStatusContainer = classnames(
  "text-right",
  "px-4",
  "flex",
  "justify-start",
);
const cnStatusIconBase = classnames(
  "rounded-xl",
  "w-2",
  "h-2",
  "text-0",
);
const cnListContent = classnames(
  "w-2/6",
  "text-left",
  "px-4",
  "overflow-ellipsis",
  "overflow-hidden",
  "hover:overflow-visible",
);
const cnListContentButtons = classnames(
  "w-2/6",
  "text-right",
  "px-4",
  "flex",
  "justify-end",
);

const cnSearchBar = classnames(
  "w-full",
  "bg-white",
  "flex",
  "items-start",
  "py-2",
)

const cnSearchField = classnames(
  "px-10",
  "w-1/3",
);

const cnSearchButtons = classnames(
  "flex",
  "items-center",
  "px-10",
  "w-1/3",
  "mt-7",
);

const cnPagination = classnames(
  "w-full",
  "flex",
  "items-center",
  "justify-between",
  "bg-gray-600",
  "py-3",
);

const cnPaginationList = classnames(
  "w-full",
  "flex",
  "items-center",
  "justify-center",
);

const cnPaginationButton = classnames(
  "rounded-full",
  "border",
  "border-white",
  "text-white",
  "p-1",
  "h-8",
  "w-8",
  "mx-2",
  "hover:bg-white",
  "hover:text-gray-600",
);

const cnPaginationButtonDisabled = classnames(
  cnPaginationButton,
  "pointer-events-none",
  "opacity-50",
)

const cnPaginationPage = classnames(
  "text-white",
  "mx-2",
);

const UsersList = ({ editUser }) => {
  const { setNotification } = useContext(NotificationContext);
  const [ search, setSearch ] = useState({ page: 1 })
  const { data, isLoading, refetch } = useQuery(["users"], () => getUsers(search));
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const deleteMutation = useMutation((values) => deleteUser(values), {
    onSuccess: () => {
      refetch();

      setNotification({
        icon: 'like',
        text: LANG.usersList.list.results.delete.ok,
        color: 'success'
      });
    },
    onError: () => {
      setNotification({
        icon: 'error',
        text: LANG.usersList.list.results.delete.ko,
        color: 'danger'
      });
    }
  });

  const cnEditButton = classnames(
    cnButtonBase,
    {
      "text-blue-600 border-current hover:bg-blue-600 hover:border-blue-600 hover:text-white": !deleteMutation.isLoading,
      "bg-gray-300 text-gray-400 border-gray-300 pointer-events-none": deleteMutation.isLoading,
    }
  );
  const cnRemoveButton = classnames(
    cnButtonBase,
    {
      "text-danger border-current hover:bg-danger hover:border-danger hover:text-white": !deleteMutation.isLoading,
      "bg-gray-300 text-gray-400 border-gray-300 pointer-events-none": deleteMutation.isLoading,
    }
  );

  const edit = (id) => {
    editUser(id);
  };

  const remove = (id) => {
    deleteMutation.mutate(id);
  };

  const EditUser = (userKey) => {
    const onClick = () => {
      edit(Object.values(userKey)[0]);
    };
    return (
      <button
        type="button"
        className={cnEditButton}
        onClick={onClick}
      >
        <span className={cnReadOnly}>{LANG.usersList.list.buttons.edit}</span>
        <DSIcon id="edit" className={cnButtonIcon} />
      </button>
    );
  };

  const RemoveUser = (userKey) => {
    const onClick = () => {
      remove(Object.values(userKey)[0]);
    }
    return (
      <button
        type="button"
        className={cnRemoveButton}
        onClick={onClick}
      >
        <span className={cnReadOnly}>{LANG.usersList.list.buttons.delete}</span>
        <DSIcon id="delete" className={cnButtonIcon} />
      </button>
    );
  };

  const updatePage = (e) => {
    setSearch({ ...search, page: e.target.value });
  }

  const formik = useFormik({
    initialValues: {
      searchBy: "name",
      searchValue: "",
    },
    validationSchema: searchSchema,
    onSubmit: (values) => {
      const obj = {
        like: values.searchBy,
        page: 1,
      };
      obj[values.searchBy] = values.searchValue;
      setSearch(obj);
    },
  });

  const Pagination = () =>
    <nav className={cnPagination}>
      <ul className={cnPaginationList}>
        <li>
          <button
            type='button'
            className={Number(search.page) !== 1 ? cnPaginationButton : cnPaginationButtonDisabled}
            value={1}
            onClick={updatePage}
          >{'<<'}</button>
        </li>
        <li>
          <button
            type='button'
            className={Number(search.page) !== 1 ? cnPaginationButton : cnPaginationButtonDisabled}
            value={search.page - 1 >= 1 ? search.page - 1 : 1}
            onClick={updatePage}
          >{'<'}</button>
        </li>
        <li className={cnPaginationPage}>[ {search.page} / {data.data.totalPages} ]</li>
        <li>
          <button
            type='button'
            className={Number(search.page) !== data.data.totalPages ? cnPaginationButton : cnPaginationButtonDisabled}
            value={search.page + 1 <= data.data.totalPages ? search.page + 1 : data.data.totalPages}
            onClick={updatePage}
          >{'>'}</button>
        </li>
        <li>
          <button
            type='button'
            className={Number(search.page) !== data.data.totalPages ? cnPaginationButton : cnPaginationButtonDisabled}
            value={data.data.totalPages}
            onClick={updatePage}
          >{'>>'}</button>
        </li>
      </ul>
    </nav>

  const resetSearch = () => {
    formik.resetForm();
    setSearch({ page: 1 })
  };

  useEffect(() => {
    refetch();
  }, [search]);

  return (
    <>
      <fieldset>
        <legend className={cnLegend}>
          {LANG.usersList.title}
        </legend>
      </fieldset>
      { isLoading ? (
        <p>{LANG.usersList.loading}</p>
      ) : (
        data && (
          <>
            <form onSubmit={formik.handleSubmit} className={cnSearchBar}>
              <div className={cnSearchField}>
                <DSSelect
                  label={LANG.usersList.form.searchBy.title}
                  id='searchBy'
                  name='searchBy'
                  value={formik.values.searchBy}
                  onChange={(e) => formik.setValues({...formik.values, searchBy: e.target.value})}
                  options={LANG.usersList.form.searchBy.values}
                  error={formik.touched.searchBy && !!formik.errors.searchBy}
                  errorHint={formik.errors.searchBy}
                  classes='flex whitespace-nowrap'
                />
              </div>
              <div className={cnSearchField}>
                <DSInput
                  label={LANG.usersList.form.text}
                  id="text"
                  type="text"
                  name="searchValue"
                  onChange={formik.handleChange}
                  value={formik.values.searchValue}
                  error={formik.touched.searchValue && !!formik.errors.searchValue}
                  errorHint={formik.errors.searchValue}
                />
              </div>
              <div className={cnSearchButtons}>
                <DSButton className='w-full' type='submit'>{LANG.usersList.form.cta}</DSButton>
                <button type='button' onClick={resetSearch}>
                  <DSIcon id="close" className='bg-gray-200 text-white rounded-full p-1 ml-4 w-8 h-auto hover:bg-gray-300' />
                  <span className='sr-only'>{LANG.usersList.form.remove}</span>
                </button>
              </div>
            </form>
            <ul>
              <li key='titles' className={cnListTitles}>
                <div className={cnListStatusContainer}>
                  <span className={cnListStatus}>{LANG.usersList.list.status}</span>
                </div>
                <div className={cnListTitlesBase}>{LANG.usersList.list.name}</div>
                <div className={cnListTitlesBase}>{LANG.usersList.list.email}</div>
                <div className={cnListTitlesActions}>{LANG.usersList.list.actions}</div>
              </li>
              { data.data.results?.map((d, k) => {
                const expired = new Date(d.last_login) < date;
                const cnListElementContent = classnames(
                  cnListElementContentBase,
                  {
                    "bg-warning-light": d.activationToken,
                    "bg-gray-100": k % 2 !== 0 && !expired && !d.activationToken,
                    "bg-white": k % 2 === 0 && !expired && !d.activationToken,
                    "bg-red-100": expired && !d.activationToken,
                  }
                );
                const cnStatusIcon = classnames(
                  cnStatusIconBase,
                  {
                    "bg-warning animate-notificationDot": d.activationToken,
                    "bg-danger": expired && !d.activationToken,
                    "bg-success": !expired && !d.activationToken,
                  }
                );
                return(
                  <li key={d.id} className={cnListElementContent}>
                    <div className={cnListContentStatusContainer}>
                      <span className={cnStatusIcon}>{LANG.usersList.list.icons.inactive}</span>
                      <span className={cnReadOnly}>
                        { d.activationToken && LANG.usersList.list.icons.inactive }
                        { !d.activationToken && expired ? LANG.usersList.list.icons.expired : LANG.usersList.list.icons.active }
                      </span>
                    </div>
                    <div className={cnListContent}>{d.name}</div>
                    <div className={cnListContent}>{d.email}</div>
                    <div className={cnListContentButtons}>
                      <EditUser userKey={d._id} />
                      <RemoveUser userKey={d._id} />
                    </div>
                  </li>
                )
              })}
              { [...Array(10 - data.data.results.length).keys()].map((el, k) => {
                const cnListElementContent = classnames(
                  cnListElementContentBase,
                  "min-h-empty-user-list",
                  {
                    "bg-gray-100": (data.data.results.length + k) % 2 !== 0,
                    "bg-white": (data.data.results.length + k) % 2 === 0,
                  }
                );
                return <li key={el} className={cnListElementContent} />
              }) }
            </ul>
            <Pagination />
          </>
        )
      )}
    </>
  );
};

UsersList.propTypes = {
  editUser: PropTypes.func.isRequired,
};

export default UsersList;
