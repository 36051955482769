const en = {
  language: 'English',
  locale: 'en-EN',
  menu: {
    contentsAnalysis: 'Contents analysis',
    glossary: 'List of terms',
    faqs: 'FAQs',
    user: {
      configure: 'Configuration',
      logout: 'Logout',
    }
  },
  configuration: {
    loading: 'Loading user information...',
    title: 'User configuration',
    name: 'Name',
    email: 'Email',
    password: 'Password',
    communications: {
      active: 'I would like to recieve email communications',
      inactive: 'I don\'t want to recieve email communications',
    },
    cta: 'Update configuration',
  },
  components: {
    datepicker: {
      locale: 'en',
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  formKeywords: {
    title: 'Here you can search for the terms we have information about. Enter your search and we will show you the information we have saved:',
    form: {
      keyword: {
        title: 'What are you looking for?',
        placeholder: 'Example: vodafone',
      },
      date: {
        title: 'Select a date from those available',
        tooltip: {
          title: 'Date Picker',
          description: 'Some of the searches are automatically renewed. In this calendar you can see if there are results from different dates. If you want any search to have this automatic refresh, you can do it from the "Search list", marking the terms you want as favorites.',
        },
        legend: 'Marked days with a double circle also have information from related Google questions',
      },
      graph: {
        title: 'Display type',
        network: {
          title: 'Neural network',
          description: 'It will provide you with information on which terms related to your search are most similar and relevant to users',
        },
        circlePacking: {
          title: 'Mitochondrial Graph',
          description: 'It will help you to know which terms related to your search have the highest volume of content similar to your search',
        },
        sankey: {
          title: 'Sankey Diagram',
          description: 'You will be able to see all the related searches that appear from your search and which are the ones that are repeated the most times',
        }
      },
      cta: 'See results',
      alsoasked: {
        title: 'We have information from Google Related Questions for this search, which will help you see the questions users are asking related to the term. You can see this information from this button:',
        cta: 'See information of related Questions',
      },
      errors: {
        keyword: 'You must choose a term',
        date: 'You must select a date',
        chartType: 'You must choose a display type',
      },
    },
  },
  generateKeyword: {
    maintenance: 'Splinter is undergoing maintenance',
    title: 'Do you want to request new information from a search to get new or more updated results? You can do it from this button:',
    form: {
      title: 'Fill in this form so we can start collecting the information you need',
      keyword: {
        title: 'Enter the term you want information on and press the + button. You will be able to enter more once you add it:',
        placeholder: 'Example: vodafone',
      },
      keywordsSelection: {
        title: 'Here you can select all those that you finally want to obtain information about. You can also bookmark them or request that we get information from Related Google Questions:',
        tooltip: {
          title: 'Select terms to search for information',
          description: {
            title: 'You can perform the following actions:',
            list: {
              select: 'Select the terms you want to obtain information about',
              favourite: 'Save the search as a favorite so it will update automatically when possible',
              alsoasked: 'Also ask for information from Google Related Questions',
              suggestions: {
                google: 'See Google suggestions for this term',
                amazon: 'View Amazon suggestions for this term',
                youtube: 'See Youtube suggestions for this term',
              },
            },
          }
        },
        suggestions: {
          from: 'Suggestions from',
          for: 'for',
          accordion: {
            open: 'Show',
            close: 'Hide',
          },
        },
      },
      priority: {
        title: 'Priority',
        tooltip: {
          title: 'Search priority:',
          description: 'Depending on the priority you select, they will take more or less to execute depending on the other searches that are queued in the tool',
        },
        values: ['Low', 'Medium', 'High'],
      },
      resume: 'Here is a list of the terms for which you are going to request information:',
      cta: 'Send terms',
      errors: {
        priority: 'You must select a priority',
      },
    },
  },
  users: {
  },
  contentAnalysis: {
    
  }
};

module.exports = { en };