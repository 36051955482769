// import axios from "axios";
import manageStorage from "hooks/manageStorage";

const refreshTokens = () => {
    const { get } = manageStorage();

    if (get() === null)
        return false

    return fetch(`${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/auth/refresh-tokens`,
    {
        method: "POST",
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            refreshToken: get().data.tokens.refresh.token,
        })
    })
    .then(res => res.json())
}

// eslint-disable-next-line import/prefer-default-export
export { refreshTokens };
