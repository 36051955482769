import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from "formik";
import { useMutation } from "react-query";
import * as Yup from "yup";

import NotificationContext from 'context/NotificationsContext';

import { updateUser } from "api/users";

import { DSModal, DSInput, DSPasswordGenerator, DSButton, DSSwitch } from 'components/DS';

import { LANG } from 'hooks/manageLanguage';

const schema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  password: Yup.string(),
});

const UserManagement = ({ user, isLoading, refetch, onClose }) => {
  const { setNotification } = useContext(NotificationContext);

  const mutation = useMutation((values) => updateUser(user?.id, values),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setNotification({
            icon: 'like',
            text: `${res.data.message}`,
            color: 'success'
          });
          refetch();
          onClose();
        } else {
          setNotification({
            icon: 'error',
            text: `${res.data.message}`,
            color: 'danger'
          });
        }
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      notifications: false,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => mutation.mutate(values),
  });

  const onChangePassword = (password) => {
    formik.setValues({
      ...formik.values,
      password,
    })
  };

  const changeNotificacionsSwitch = () => {
    formik.setValues({
      ...formik.values,
      notifications: !formik.values.notifications,
    })
  };

  useEffect(() => {
    if (user) formik.setValues({ name: user.name, email: user.email, notifications: user.notifications });
  }, [user]);

  return (
    <DSModal className='z-20' onClose={onClose}>
      { isLoading ? <p>{LANG.configuration.loading}</p>
      :
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <p className="text-xl mt-12 pb-10 bg-gray-50 w-full text-center">{LANG.configuration.title}</p>
          <fieldset>
            <div className="flex justify-center flex-col py-8 px-20">
              <input type='hidden' id='action' name='action' value={formik.values.action} />
              <div>
                <DSInput
                  label={LANG.configuration.name}
                  id="name"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && !!formik.errors.name}
                  errorHint={formik.errors.name}
                  autocomplete="off"
                />
              </div>
              <div className="mt-4">
                <DSInput
                  label={LANG.configuration.email}
                  id="email"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && !!formik.errors.email}
                  errorHint={formik.errors.email}
                  autocomplete="off"
                  disabled
                />
              </div>
              <div className="mt-4">
                <DSPasswordGenerator
                  label={LANG.configuration.password}
                  id="password"
                  name="password"
                  onChange={onChangePassword}
                  value={formik.values.password}
                  error={formik.touched.password && !!formik.errors.password}
                  errorHint={formik.errors.password}
                  autocomplete="off"
                  copyToClipboard
                  className='w-full'
                  generator={false}
                />
              </div>
              <div className="mt-4 text-left">
                <DSSwitch
                  label={formik.values.notifications ? LANG.configuration.communications.active : LANG.configuration.communications.inactive}
                  id='user-notifications'
                  name='user-notifications'
                  active={formik.values.notifications}
                  onChange={changeNotificacionsSwitch}
                />
              </div>
            </div>
          </fieldset>
          <div className="bg-gray-50 flex justify-center flex-col py-8 px-20">
            <DSButton
              type="submit"
              className="w-full"
              disabled={isLoading || mutation.isLoading}
            >
              {LANG.configuration.cta}
            </DSButton>
          </div>
        </form>
      }
    </DSModal>
  );
}

UserManagement.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    notifications: PropTypes.bool,
  }),
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
};

UserManagement.defaultProps = {
  user: undefined,
  isLoading: false,
  refetch: null,
};

export default UserManagement;