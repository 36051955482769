import React, { useContext } from "react";
import classnames from "tailwindcss-classnames";

import { UsersContextProvider } from "context/UsersContext";
import LoginContext from "context/LoginContext";
import Layout from "components/Layout/Layout";
import { DSCard } from "components/DS";
import { KeywordsContextProvider } from "./context/KeywordsContext";

import FormKeywords from "./FormKeywords/FormKeywords";
import GenerateKeyword from "./GenerateKeyword/GenerateKeyword";
import Users from "./Users/Users";

const cnBaseCard = classnames("bg-gray-50",
  "p-4",
  "rounded-xl",
  "text-center",
  "flex",
  "flex-col",
  "items-center",
  "justify-center"
);
const sectionClass = classnames(
  "bg-gradient-to-r",
  "from-primary-dark",
  "to-primary",
  "w-full",
  "flex",
  "items-center",
  "justify-center",
  "overflow-auto",
);
const DSCardClass = classnames(
  cnBaseCard,
  "min-h-dashboard-cards"
);
const keywordClass = classnames(
  cnBaseCard,
  "w-2/3"
);
const wrapperClass = classnames(
  "flex",
  "justify-center",
  "items-stretch",
  "gap-8",
  "w-11/12",
  "lg:w-3/4",
  "max-w-7xl",
);
const cnRightCol = classnames(
  "flex", "flex-col", "justify-between", "w-1/3"
);

const Dashboard = () => {
  const { userLogin } = useContext(LoginContext);
  const isAdmin = userLogin !== null && userLogin.data.user.role === "admin";

  return (
    <UsersContextProvider>
      <Layout>
        <section className={sectionClass}>
          <div className={wrapperClass}>
            <KeywordsContextProvider>
              <DSCard className={keywordClass}>
                <FormKeywords />
              </DSCard>
            </KeywordsContextProvider>
            <div className={cnRightCol}>
              <DSCard className={DSCardClass}>
                <GenerateKeyword />
              </DSCard>
              {isAdmin && (
                <DSCard className={DSCardClass}>
                  <Users />
                </DSCard>
              )}
            </div>
          </div>
        </section>
      </Layout>
    </UsersContextProvider>
  );
};

export default Dashboard;
