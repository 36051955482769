const manageStorage = () => {
  const set = (data = {}) => {
    localStorage.setItem(
      process.env.REACT_APP_SPLINTER_AUTH_COOKIE,
      JSON.stringify(data)
    );
  };

  const get = () =>
    JSON.parse(localStorage.getItem(process.env.REACT_APP_SPLINTER_AUTH_COOKIE));

  const remove = () => {
    localStorage.removeItem(process.env.REACT_APP_SPLINTER_AUTH_COOKIE);
  };

  const refresh = (data = {}) => {
    const a = get();
    const obj = { ...a, data: { user: a.data.user, tokens: data } };
    localStorage.setItem(
      process.env.REACT_APP_SPLINTER_AUTH_COOKIE,
      JSON.stringify(obj)
    );

    return obj;
  };

  return {
    set,
    get,
    remove,
    refresh,
  };
};

export default manageStorage;
