import React from "react";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";

const DSProgressBar = ({ progress, className }) => {
  let safeProgress = progress !== undefined ? progress : 0;

  if (safeProgress < 0) {
    safeProgress = 0;
  } else if (safeProgress > 100) {
    safeProgress = 100;
  }

  const baseClassName = classnames("rounded bg-gray-300", className);

  return (
    <div className={baseClassName}>
      <div
        className="bg-gray-700 rounded h-2 w-0 transition-all duration-700"
        style={{ width: `${progress !== null && progress <= 100 ? progress : safeProgress}%` }}
      >
        <span className="sr-only">{safeProgress}%</span>
      </div>
    </div>
  );
};

DSProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  className: PropTypes.string,
};

DSProgressBar.defaultProps = {
  className: "",
};

export default DSProgressBar;
