import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { classnames } from 'tailwindcss-classnames';
import { LANG } from 'hooks/manageLanguage';

import { getKeywords, getGraph, getGraphJson } from "api/keywords";

import Layout from 'components/Layout/Layout';
import GraphFilters from 'components/Common/GraphFilters/GraphFilters';
import NetworkColumn from 'routes/Network/components/NetworkColumn';
import NetworkGraph from 'routes/Network/components/NetworkGraph';
import background from "assets/images/background-red2.jpg";
import { DSIcon } from 'components/DS';
import GraphMaxRepetitions from 'components/Common/GraphMaxRepetitions/GraphMaxRepetitions';

const cnContainer = classnames("flex", "flex-col", "w-full");
const cnContent = classnames("flex", "flex-grow", "bg-gray-50", "h-1");
const cnColumn = classnames("flex-shrink-0", "w-96");
const cnGraph = classnames("bg-center", "bg-cover", "bg-no-repeat", "flex-grow", "ml-80", "mr-80");

const setTop10 = (data, origin) => data.nodes?.sort((a, b) => b.rels - a.rels).slice(0, 11).filter((node) => node !== origin).slice(0, 10);

const Network = () => {
	const [ topVisibility, setTopVisibility ] = useState(false);
	const { slug, id } = useParams();
	const { data: dataFilters, isLoading: isLoadingFilters } = useQuery(["filtersKeyword", slug], () => getKeywords({
		slug,
		sortBy: 'created_at:asc',
	}));
	const { data: dataGraph, isLoading: isLoadingGraph } = useQuery(["networkGraph", slug, id], () => getGraph({ graph: `network`, slug, keyword_id: id }));
	const { data: dataJson, isLoading: isLoadingJson } = useQuery(["networkGraphJson", slug, id], () => getGraphJson(dataGraph.data), {
		enabled: !isLoadingGraph,
	});
	const ref = useRef(null);
	const repetitionsRef = useRef(null);

	const [ mainKeywordInfo, setMainKeywordInfo ] = useState({});
	const [ graphKeywordInfo, setGraphKeywordInfo ] = useState({});

	const handleTopVisibility = () => setTopVisibility(!topVisibility);

	useEffect(() => {
		if (dataJson && dataFilters) {
			setGraphKeywordInfo({});
			const origin = dataJson.data.nodes ? dataJson.data.nodes[0] : dataFilters.data.results[0];
			NetworkGraph(ref.current, dataJson.data, setGraphKeywordInfo);
			setMainKeywordInfo({ origin: origin.keyword_id, top10: setTop10(dataJson.data, origin), suggestions: dataJson.data.suggestions, categories: dataJson.data.categories, pills: dataJson.data.pills });
			if (!dataJson.data.nodes) {
				setGraphKeywordInfo({
					selected: { id: origin.value },
				});
			}
			if (dataJson.data.topTen.length > 0) GraphMaxRepetitions(repetitionsRef.current, dataJson.data.topTen);
		}
	}, [dataJson, dataFilters]);

	return (
		<Layout>
			<div className={cnContainer}>
				<GraphFilters graph='network' data={dataFilters} isLoading={isLoadingFilters} />
				<div className={cnContent}>
					{ isLoadingGraph || isLoadingJson ? <p>Cargando datos...</p> :
						<>
							<NetworkColumn className={cnColumn} mainKeywordInfo={mainKeywordInfo} graphKeywordInfo={graphKeywordInfo} />
							<svg key={`${slug}${id}`} className={cnGraph} style={{ backgroundImage: `url(${background})` }} ref={ref}  />
							{ dataJson.data.topTen.length > 0 &&
								<div
									className={`fixed max-h-topRepetitions h-full top-full right-0 left-0 bg-white px-4 py-2 z-50 border-t border-gray-200 transition-transform duration-700 transform ${topVisibility ? '-translate-y-full' : '-translate-y-10'}`}
								>
									<button
										type='button'
										className='w-full items-center justify-between flex'
										onClick={handleTopVisibility}
									>
										<p>{LANG.graphs.mostRepeatedRelations}</p>
										<DSIcon
											id='chevron-down'
											className={`w-6 h-6 text-primary transition-transform duration-700 transform ${topVisibility ? 'rotate-0' : 'rotate-180'}`}
										/>
									</button>
									<svg className='w-full h-full' ref={repetitionsRef} />
								</div>
							}
						</>
					}
				</div>
			</div>
		</Layout>
	)
}

export default Network;