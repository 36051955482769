import React, { useState } from "react";
import { classnames } from "tailwindcss-classnames";

import { DSModal } from "components/DS";

import editUser from "assets/images/edituser.png";
import addUser from "assets/images/adduser.png";

import { LANG } from 'hooks/manageLanguage';

import UsersList from "./UsersList";
import UsersEditor from "./UsersEditor";

const cnTitle = classnames(
  "text-m",
  "font-bold",
  "mb-4",
);
const cnFlex = classnames("flex");
const cnImage = classnames(
  "w-16",
  "h-16",
);

const Users = () => {
  const [modal, setModal] = useState({
    status: false,
    type: undefined,
    user: undefined,
  });

  const closeModal = () => {
    setModal({ status: false, type: undefined, user: undefined });
  };

  const openEditor = () => {
    setModal({ status: true, type: "editor" });
  };

  const openUserEditor = (id) => {
    setModal({ status: true, type: "editor", user: id });
  };

  const openList = () => {
    setModal({ status: true, type: "list" });
  };

  return (
    <>
      <h2 className={cnTitle}>{LANG.users.title}</h2>
      <div className={cnFlex}>
        <button
          type="button"
          onClick={openEditor}
        >
          <img className={cnImage} src={addUser} alt="Abre ventana modal" />
        </button>
        <button
          type="button"
          onClick={openList}
        >
          <img className={cnImage} src={editUser} alt="Abre ventana modal" />
        </button>
      </div>
      {!modal.status || (
        <DSModal
          onClose={modal.user ? openList : closeModal}
          size={modal.type === 'list' ? 'min-w-modalList max-w-modalList' : 'min-w-modal'}
          transition={modal.user === undefined}
        >
          { modal.type === "list" ? (
            <UsersList editUser={openUserEditor} />
          ) : (
            <UsersEditor id={modal.user} openList={openList} />
          )}
        </DSModal>
      )}
    </>
  );
};

export default Users;
