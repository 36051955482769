import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";

import { LANG } from 'hooks/manageLanguage';

const cnColumn = classnames(
	"border-l",
	"bg-white",
	"border-gray-300",
	"overflow-y-scroll",
	"p-6",
	"fixed",
	"bottom-0",
	"top-24",
	"overflow-auto",
	"border-t",
	"max-w-xs",
	"w-80",
	"right-0",
);
const cnContent = classnames("");
const cnTitleContainer = classnames("border-l-4 border-primary pl-5");
const cnTitle = classnames("text-2xl font-bold mb-6 mt-4");
const cnElement = classnames("w-full pl-5 mb-2 border-l-2 border-gray-300 text-left hover:text-primary");
const cnParent = classnames("w-full border-gray-300 border-l-2 border-gray-300 pl-5 my-1 text-left hover:text-primary");
const cnTitleBase = classnames("border-l-4", "border-primary", "pl-4");
const cnTitleMargin = classnames(cnTitleBase, "mt-4");

const clickNode = ({ node }) => {
	const svg = document.querySelector(`#node--${node.data.id}`);
	svg.dispatchEvent(new Event('click'));
};

const findParents = ({ arr = [], node }) => {
	if (node && node.parent) {
		arr.unshift(node.parent);
		findParents({ arr, node: node.parent });
	}
	return arr;
};

const PeopleAlsoAskColumn = ({ className, data }) => {
	const baseClassName = classnames(cnColumn, className);
	const [ parents, setParents ] = useState(undefined);

	useEffect(() => {
		if (data) setParents(findParents({ node: data }));
	}, [data]);

	return (
		<>
			<div className={baseClassName}>
				{ data && data.categories && data.categories.length > 0 && (
					<>
						<div className={cnTitleMargin}><strong>{LANG.graphs.columns.categories.title}:</strong></div>
						<ul className='my-4 flex flex-col justify-between relative pl-5'>
							{ data.categories.map((cat) =>
								<li className='flex mt-1 items-center gap-1' key={cat}>
									{cat}
								</li>
							) }
						</ul>
					</>
				) }
				{ data && (
					<div className={cnContent}>
						{ parents && parents.length > 0 &&
							<>
								<p className='font-bold mb-2'>Padres:</p>
								<ul>
									{ parents.map((parent) =>
											<li key={parent.data.name}><button type='button' className={cnParent} onClick={() => clickNode({ node: parent })}>{ parent.data.name }</button></li>
									)}
								</ul>
							</>
						}
						<div className={cnTitleContainer}>
							<h2 className={cnTitle}>{ data.value }</h2>
						</div>
						{ data.childs && data.childs.length > 0 &&
							<>
								<p className='font-bold mb-2'>Preguntas relacionadas:</p>
								<ul>
									{
										data.childs && data.childs.map((child) => (
											<li key={child.data.name}><button type='button' className={cnElement} onClick={() => clickNode({ node: child })}>{ child.data.name }</button></li>
										))
									}
								</ul>
							</>
						}
					</div>
				)}
			</div>
		</>
  	)
};

PeopleAlsoAskColumn.propTypes = {
	className: PropTypes.string,
	data: PropTypes.shape({
		value: PropTypes.string,
		parent: PropTypes.shape({
			value: PropTypes.string,
			parent: PropTypes.shape({}) ,
			childs: PropTypes.arrayOf(),
		}) ,
		childs: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				parent: PropTypes.shape({}),
				childs: PropTypes.shape({}),
			})
		),
		categories: PropTypes.arrayOf(PropTypes.string),
	}),
};

PeopleAlsoAskColumn.defaultProps = {
  className: "",
  data: null,
};

export default PeopleAlsoAskColumn;
