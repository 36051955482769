/* eslint no-use-before-define: "off" */
import React from "react";
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { reactivateUser } from 'api/users';

import Layout from 'components/Layout/Layout';

import LogoVF from "../../assets/images/vf-logo.png";
import LogoSplinter from "../../assets/images/splinter.png";

const Reactivate = () => {
	const { token } = useParams();
	const { data, isLoading } = useQuery(["reactivateAccount"], () => reactivateUser(token));

  const printResult = () => (
    data.status === 404 ?
      <p className='text-xl'>La cuenta no existe o ya está activada</p>
    :
      <>
        <h1 className='text-2xl font-bold'>¡Tu cuenta ha sido reactivada correctamente!</h1>
        <p className='text-xl mt-4'>Por favor, accede con tus datos a Vodafone Splinter</p>
        <Link to='/login' className='mt-8 block rounded px-3 py-2 border border-primary bg-primary text-white transition-colors hover:bg-white hover:text-primary'>Acceder a Splinter</Link>
      </>
  );

  return (
    <Layout>
      <main className="bg-gradient-to-r from-primary-dark to-primary absolute inset-0 flex justify-center items-center">
        <div className='flex bg-white rounded-xl flex-col relative z-10 text-center p-12 min-w-modal min-h-activationCard'>
          <div className="flex flex-0 items-start justify-between pb-4 border-b-2 border-primary-600 mb-8">
            <img className="w-8 h-8 ml-4 mr-8" src={LogoVF} alt="" />
            <h1 className="text-2xl">Vodafone Splinter</h1>
            <img className="w-8 h-8 ml-8 mr-4" src={LogoSplinter} alt="" />
          </div>
          { isLoading ?
            <p className='align-self-center text-xl'>Estamos reactivando tu cuenta...</p>
            :
            printResult()
          }
        </div>
      </main>
    </Layout>
  );
}

export default Reactivate;
