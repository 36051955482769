import React, { useState, useEffect, useCallback, useContext } from "react";
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useParams, useHistory } from "react-router-dom";
import { classnames } from "tailwindcss-classnames";
import { useMutation } from 'react-query';
import { saveAs } from 'file-saver';

import NotificationContext from "context/NotificationsContext"

import { getXls } from 'api/keywords';

import { DSIcon, DSInputRange, DSTooltip, DSSelect } from "components/DS";

import { LANG } from 'hooks/manageLanguage';

const dateTimeFormatter = new Intl.DateTimeFormat(
  LANG.locale || process.env.REACT_APP_SPLINTER_LOCALE,
  {
    dateStyle: "medium",
  }
);

const cnContainer = classnames("bg-white", "border-b", "border-gray-300", "flex", "items-center", "justify-between", "px-4", "py-2", "z-10", "min-h-filters");
const cnLeftContent = classnames("flex", "items-center", "space-x-6");
const cnKeyword = classnames("text-lg", "font-bold", "inline-block", "align-middle");
const cnInputContainer = classnames("flex", "space-x-3", "items-center");
const cnXlsButtonBase = classnames("flex", "gap-0", "items-center", "justify-center", "hover:bg-white", "hover:text-primary", "transition-all", "w-10", "h-10", "border", "border-primary", "rounded-full");

const GraphFilters = ({ graph, data, isLoading, xls, selector }) => {
	const { slug, id } = useParams();
  const [ value, setValue ] = useState({ index: 0, keyword: '' });
  const [ date, setDate ] = useState();
  const [ view ] = useState(graph);
  const history = useHistory();
  const { setNotification } = useContext(NotificationContext);

  const historyChange = useCallback(
    debounce(info => history.push(`/${graph}/${info.slug}/${info.keyword_id}`), 800)
  , [])

  const handleChange = (e) => {
    const info = data.data.results[e.target.value];
    setDate(info.created_at.value);

    historyChange(info);
  }

  const handleViewChange = (e) => {
    history.push(`/${e.target.value}/${slug}/${id}`)
  }

  const mutateXls = useMutation((values) => getXls(values), {
    onSuccess(res) {
      if (res.status === 200) {
        const filename = `splinter-report-${slug}_${date.split('T')[0]}.xlsx`;
        saveAs(`${process.env.REACT_APP_SPLINTER_ENDPOINT}/${res.data.xls}`, filename);
      }
      else
        setNotification({
          icon: 'error',
          text: res.data.message,
          color: 'danger'
        });
    },
  });

  const clickGetXls = () => {
    if (!mutateXls.isLoading)
      mutateXls.mutate({ keyword_id: id, slug });
  }

  const cnXlsButton = classnames(cnXlsButtonBase, {
    'pointer-events-none': mutateXls.isLoading,
    'bg-primary': !mutateXls.isLoading,
    'bg-white': mutateXls.isLoading,
    'text-white': !mutateXls.isLoading,
    'text-primary': mutateXls.isLoading,
  });

  useEffect(() => {
    if (data) {
      const index = data.data.results.findIndex((result) => result.keyword_id === id);
      setValue({ index, keyword: data.data.results[index].value });
      setDate(data.data.results[index].created_at.value);
    }
  }, [data]);

  return (
    <div className={cnContainer}>
      <div className={cnLeftContent}>
        <p className={cnKeyword}>{value.keyword}</p>
        { isLoading ? <div>{LANG.graphs.filters.loading}</div> :
          <div className='flex items-center gap-4'>
            <div className={cnInputContainer}>
              { value && date && (
                <>
                  <DSInputRange
                    min={0}
                    max={data.data.results.length - 1}
                    step={1}
                    onChange={handleChange}
                    value={value.index}
                  />
                  <p>{date ? dateTimeFormatter.format(new Date(date)) : ''}</p>
                </>
              )}
            </div>
            { xls &&
              <>
                <span className='w-px h-6 bg-gray-300 block' />
                <div className='flex items-center'>
                  <button
                    onClick={clickGetXls}
                    type='button'
                    className={cnXlsButton}
                  >
                    <DSIcon id={mutateXls.isLoading ? 'loading' : 'documentXls'} className={mutateXls.isLoading ? 'w-10 h-10 transform translate-x-2 translate-y-px' : 'w-7 h-7'} />
                  </button>
                  <DSTooltip
                    icon='support'
                    description={LANG.graphs.filters.xls.tooltip.description}
                  />
                </div>
              </>
            }
          </div>
        }
      </div>
      { selector &&
        <div className='w-48'>
          <DSSelect
            id="graphSelector"
            name="graphSelector"
            value={view}
            onChange={handleViewChange}
            options={LANG.graphs.filters.viewsSelector.values}
            autocomplete="off"
          />
        </div>
      }
    </div>
  )
};

GraphFilters.propTypes = {
  graph: PropTypes.string.isRequired,
  data: PropTypes.shape({
    data: PropTypes.shape({
      results: PropTypes.arrayOf(
        PropTypes.shape({
          created_at: PropTypes.shape({
            value: PropTypes.string,
          }),
          keyword_id: PropTypes.string,
          value: PropTypes.string,
        })
      )
    })
  }),
  isLoading: PropTypes.bool,
  selector: PropTypes.bool,
  xls: PropTypes.bool,
}

GraphFilters.defaultProps = {
  data: null,
  isLoading: true,
  selector: true,
  xls: true,
}

export default GraphFilters;
