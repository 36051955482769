import React, { useState } from "react";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";

import DSIcon from "components/DS/Atoms/DSIcon/DSIcon";

const cnModalBase = classnames(
  "fixed",
  "inset-0",
  "z-20",
)
const cnCloseLayer = classnames(
  "bg-gray-600",
  "opacity-50",
  "h-full",
  "w-full",
  "fixed",
  "inset-0",
  "z-10",
)
const cnContainer = classnames(
  "absolute",
  "p-10",
  "inset-0",
  "overflow-auto",
  "text-center",
)
const cnVerticalLine = classnames(
  "inline-block",
  "align-middle",
  "min-h-full",
  "text-0",
)
const cnContentBase = classnames(
  "inline-block",
  "align-middle",
  "w-6/12",
  "bg-gray-50",
  "z-10",
  "relative",
  "min-h-1/4",
)
const cnCloseButton = classnames(
  "absolute",
  "h-10",
  "right-4",
  "top-4",
  "text-primary",
  "hover:text-gray-800",
)
const cnReadOnly = classnames(
  "sr-only",
)
const cnCloseIcon = classnames(
  "w-10",
  "h-10",
)

const DSModal = ({ onClose, children, size, transition, className }) => {
  const [ isClosing, setIsClosing ] = useState(false);

  const cnModal = classnames(
    cnModalBase,
    isClosing ? "animate-modalLayerDisappear" : "animate-modalLayerAppear opacity-0",
    className,
  );
  const cnContent = classnames(
    cnContentBase,
    size === 'modal' ? 'min-w-modal' : size
  );

  const handleClose = () => {
    if (transition) {
      setIsClosing(true);
      setTimeout(() => {
        onClose()
      }, 300);
    }
    else
      onClose()
  }

  return (
    <section role='dialog' className={cnModal}>
      <button type='button' className={cnCloseLayer} onClick={handleClose} tabIndex='-1'>
        <span className={cnReadOnly}>Cerrar modal</span>
      </button>
      <section className={cnContainer}>
        <span className={cnVerticalLine}>linea</span>
        <div className={cnContent}>
          <button
            type="button"
            className={cnCloseButton}
            onClick={handleClose}
          >
            <DSIcon id="close" className={cnCloseIcon} />
            <span className={cnReadOnly}>Cerrar modal</span>
          </button>
          {children}
        </div>
      </section>
    </section>
  )
}

DSModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  size: PropTypes.string,
  transition: PropTypes.bool,
  className: PropTypes.string,
};

DSModal.defaultProps = {
  size: 'modal',
  transition: true,
  className: '',
}

export default DSModal;
