import React, { useState } from "react";
import PropTypes from "prop-types";
import Generator from 'generate-password';
import { classnames } from 'tailwindcss-classnames';

import DSInput from "../DSInput/DSInput";
import DSIcon from "../DSIcon/DSIcon";

const iconForType = {
  password: "viewed",
  text: "no-viewed"
};

const DSPasswordGenerator = ({
  label,
  id,
  name,
  onChange,
  error,
  errorHint,
  copyToClipboard,
  onCopy,
  className,
  generator,
}) => {
  const [ inputValue, setInputValue ] = useState('');
  const [ inputType, setInputType ] = useState({ type: 'password', icon: 'viewed' });

  const updateValue = (value, e) => {
    onChange(value, e);
    setInputValue(value);
  }

  const generatePassword = () => {
    const password = Generator.generate({
      length: 16,
      numbers: true,
      lowercase: true,
      uppercase: true,
      strict: true
    });

    updateValue(password);

    if (copyToClipboard)
      navigator.clipboard.writeText(password);
      onCopy();
  }

  const toggleInput = () => {
    setInputType({
      type: inputType.type === 'password' ? 'text' : 'password',
      icon: inputType.type === 'password' ? iconForType.text : iconForType.password,
    });
  }

  const inputProps = {
    label,
    type: inputType.type,
    id,
    name,
    value: inputValue,
    onChange: (e) => updateValue(e.target.value, e),
    error,
    errorHint,
    autocomplete: "off"
  }

  const cnPasswordGenerator = classnames('flex', 'items-start', className);

  return (
    <div className={cnPasswordGenerator}>
      <DSInput {...inputProps} />
      <button type='button' className='bg-primary box-content border border-primary rounded h-6 p-2 mt-7 ml-0.5 text-white transition transition-colors hover:bg-white hover:text-primary' onClick={toggleInput}>
        <DSIcon id={inputType.icon} className="w-6 h-auto" />
      </button>
      { generator &&
        <button type='button' className='bg-primary box-content border border-primary rounded h-6 p-2 mt-7 ml-0.5 text-white transition transition-colors hover:bg-white hover:text-primary' onClick={generatePassword}>
          <DSIcon id="sync" className="w-6 h-auto" />
        </button>
      }
    </div>
  );
};

DSPasswordGenerator.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorHint:  PropTypes.string,
  copyToClipboard: PropTypes.bool,
  onCopy: PropTypes.func,
  className: PropTypes.string,
  generator: PropTypes.bool,
};

DSPasswordGenerator.defaultProps = {
  onChange: null,
  error: null,
  errorHint: null,
  copyToClipboard: false,
  onCopy: null,
  className: '',
  generator: true,
};

export default DSPasswordGenerator;
