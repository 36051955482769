import React, { useEffect, useContext, useState } from "react";
import { classnames } from "tailwindcss-classnames";

import NotificationsContext from "context/NotificationsContext";

import { DSIcon } from "components/DS/";

const colorClasses = {
  'success': { bg: 'bg-success', text: 'text-white' },
  'warning': { bg: 'bg-warning', text: 'text-gray-800' },
  'danger': { bg: 'bg-danger', text: 'text-white' }
}

const cnNotificationBase = classnames(
  "fixed",
  "inset-x-0",
  "top-0",
  "z-50",
)
const cnContent = classnames(
  "flex",
  "justify-center",
  "items-center",
  "p-4",
);
const cnIcon = classnames(
  "w-6",
  "h-auto",
  "mr-3",
);

const Notification = () => {
  const { notification, setNotification } = useContext(NotificationsContext);
  const [ showBar, setShowBar ] = useState(false)

  useEffect(() => {
    setShowBar(false);

    const timerInit = setTimeout(() => {
      setShowBar(true);
    }, 10)
    const timerEnd = setTimeout(() => {
      setNotification(undefined)
      setShowBar(false);
    }, 5000)

    return () => { clearTimeout(timerInit); clearTimeout(timerEnd) };
  }, [notification]);

  const cnNotification = classnames(
    cnNotificationBase,
    colorClasses[notification?.color]?.bg,
    colorClasses[notification?.color]?.text,
    notification ? 'animate-notification' : 'hidden',
  );

  return (
    <>
      { !showBar || (
        <div className={cnNotification}>
          <div className={cnContent}>
            {
              notification?.icon === undefined || (
                <DSIcon id={notification.icon} className={cnIcon} />
              )
            }
            <p>{notification?.text}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
