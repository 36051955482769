/* eslint no-use-before-define: "off" */
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { classnames } from 'tailwindcss-classnames';

import { login, register, forgotPassword } from "api/login";
import manageStorage from "hooks/manageStorage";
import { LANG } from 'hooks/manageLanguage';
import LoginContext from "context/LoginContext";
import NotificationContext from "context/NotificationsContext"

import Layout from "components/Layout/Layout";
import { DSIconSource } from "components/DS/Atoms/DSIcon/DSIcon";
import { DSButton, DSCard, DSPasswordGenerator } from "components/DS";
import DSInput from "../../components/DS/Atoms/DSInput/DSInput";
import LogoVF from "../../assets/images/vf-logo.png";
import LogoSplinter from "../../assets/images/splinter.png";

const cnActionButton = classnames('w-full', 'py-2', 'border-b-2');
const cnActionButtonActive = classnames(cnActionButton, 'bg-white', 'border-primary', 'pointer-events-none');
const cnActionButtonInactive = classnames(cnActionButton, 'bg-gray-100', 'border-gray-300');

const loginSchema = Yup.object().shape({
  email: Yup.string().email(LANG.login.login.form.errors.email.invalid).required(LANG.login.login.form.errors.email.required),
  password: Yup.string().required(LANG.login.login.form.errors.password),
});

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email(LANG.login.recovery.form.errors.email.invalid).required(LANG.login.recovery.form.errors.email.required),
});

const registerSchema = Yup.object().shape({
  name: Yup.string().required(LANG.login.register.form.errors.name),
  email: Yup.string().email(LANG.login.register.form.errors.email.invalid).required(LANG.login.register.form.errors.email.required)
    .test('vodafone-email', LANG.login.register.form.errors.email.corporative,
      (value) => {
        const domain = value && value !== '' ? value.split('@') : '';
        if (value && value !== '' && domain[1] === 'vodafone.com' || domain[1] === 'corp.vodafone.es') return true;
        return false;
      }
  ),
  password: Yup.string().required(LANG.login.register.form.errors.password.required)
    .test('password-chars', LANG.login.register.form.errors.password.pattern,
    (value) => {
      if (value && value !== '' && (!value.match(/\d/) || !value.match(/[a-zA-Z]/))) return false;
      if (value && value !== '' && value.length < 8) return false;
      return true;
    }
  ),
  passwordConfirm: Yup.string().required(LANG.login.register.form.errors.passwordConfirm.required).oneOf([Yup.ref('password'), null], LANG.login.register.form.errors.passwordConfirm.different),
});

const Login = () => {
  const { setUserLogin } = useContext(LoginContext);
  const { setNotification } = useContext(NotificationContext);
  const { set } = manageStorage();
  const history = useHistory();
  const [ action, setAction ] = useState('login');

  const logUser = (data) => {
    setUserLogin({ data });
    set({ data });
    history.push("/dashboard");
  };

  const loginQuery = useMutation((values) => login(values), {
    onSuccess(data) {
      if (data.status === 200) logUser(data.data);
      else
        setNotification({
          icon: 'error',
          text: data.data.message,
          color: 'danger'
        });
    },
  });

  const forgotPasswordQuery = useMutation((values) => forgotPassword(values), {
    onSuccess(data) {
      if (data.status === 404)
        setNotification({
          icon: 'error',
          text: data.data.message,
          color: 'danger'
        });
      else {
        setNotification({
          icon: 'like',
          text: LANG.login.recovery.form.results.ok,
          color: 'success'
        });
        forgotPasswordFormik.resetForm();
      }
    },
  });

  const registerQuery = useMutation((values) => register(values), {
    onSuccess(data) {
      if (data.status === 400)
        setNotification({
          icon: 'error',
          text: data.data.message,
          color: 'danger'
        });
      else {
        setNotification({
          icon: 'like',
          text: LANG.login.register.form.results.ok,
          color: 'success'
        });
        setAction('login');
        registerFormik.resetForm();
      }
    },
  });

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      loginQuery.mutate(values);
    },
  });

  const forgotPasswordFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      forgotPasswordQuery.mutate(values);
    },
  });

  const registerFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      const { name, email, password } = values;
      registerQuery.mutate({ name, email, password });
    },
  });

  const setLogin = () => {
    setAction('login');
  }

  const setRegister = () => {
    setAction('register');
  }

  const setforgotPassword = () => {
    setAction('forgotPassword');
  }

  const passwordGeneratorOnChange = (_, e) => {
    registerFormik.handleChange(e);
  }

  return (
    <Layout>
      <main className="bg-gradient-to-r from-primary-dark to-primary absolute inset-0 flex justify-center items-center">
        <div className="inline-flex bg-white rounded-xl flex-col relative z-10">
          <div className="flex flex-1 justify-between p-4 border-b-2 border-primary-600">
            <img className="w-8 h-8 ml-4 mr-8" src={LogoVF} alt="" />
            <h1 className="text-2xl">{LANG.login.title}</h1>
            <img className="w-8 h-8 ml-8 mr-4" src={LogoSplinter} alt="" />
          </div>
          { (action === 'login' || action === 'register') &&
            <ul className='w-full flex justify-around'>
              <li key='login' className='w-1/2'>
                <button
                  type='button'
                  className={action === 'login' ? cnActionButtonActive : cnActionButtonInactive}
                  onClick={setLogin}
                >
                  {LANG.login.login.title}
                </button>
              </li>
              <li key='register' className='w-1/2'>
                <button
                  type='button'
                  className={action === 'register' ? cnActionButtonActive : cnActionButtonInactive}
                  onClick={setRegister}
                >
                  {LANG.login.register.title}
                </button>
              </li>
            </ul>
          }
          <DSCard className="w-96 inline-flex bg-white rounded-xl flex-col">
            { action === 'login' &&
              (
                <form
                  className="flex flex-col space-y-4 items-start"
                  onSubmit={loginFormik.handleSubmit}
                >
                  <DSInput
                    label={LANG.login.login.form.email}
                    id="email"
                    type="email"
                    name="email"
                    onChange={loginFormik.handleChange}
                    value={loginFormik.values.email}
                    error={loginFormik.touched.email && !!loginFormik.errors.email}
                    errorHint={loginFormik.errors.email}
                  />
                  <DSInput
                    label={LANG.login.login.form.password}
                    id="password"
                    type="password"
                    name="password"
                    onChange={loginFormik.handleChange}
                    value={loginFormik.values.password}
                    error={loginFormik.touched.password && !!loginFormik.errors.password}
                    errorHint={loginFormik.errors.password}
                  />
                  <DSButton
                    type="submit"
                    className="w-full"
                    disabled={loginQuery.isLoading}
                  >
                    {LANG.login.login.form.cta}
                  </DSButton>
                  <button
                    type='button'
                    onClick={setforgotPassword}
                    className='mt-4 ml-auto mr-auto block hover:underline hover:text-primary'
                  >
                    {LANG.login.login.form.passwordRecovery}
                  </button>
                </form>
              )
            }
            { action === 'forgotPassword' &&
              (
                <form
                  className="flex flex-col space-y-4 items-start"
                  onSubmit={forgotPasswordFormik.handleSubmit}
                >
                  <DSInput
                    label={LANG.login.recovery.form.email}
                    id="email"
                    type="email"
                    name="email"
                    onChange={forgotPasswordFormik.handleChange}
                    value={forgotPasswordFormik.values.email}
                    error={forgotPasswordFormik.touched.email && !!forgotPasswordFormik.errors.email}
                    errorHint={forgotPasswordFormik.errors.email}
                  />
                  <DSButton
                    type='submit'
                    className='w-full'
                  >
                    {LANG.login.recovery.form.cta}
                  </DSButton>
                  <DSButton
                    type='button'
                    variant='secondary'
                    className='border border-primary w-full'
                    onClick={setLogin}
                  >
                    {LANG.login.recovery.form.back}
                  </DSButton>
                </form>
              )
            }
            { action === 'register' &&
              (
                <form
                  className="flex flex-col space-y-4 items-start"
                  onSubmit={registerFormik.handleSubmit}
                >
                  <DSInput
                    label={LANG.login.register.form.name}
                    id="name"
                    type="text"
                    name="name"
                    onChange={registerFormik.handleChange}
                    value={registerFormik.values.name}
                    error={registerFormik.touched.name && !!registerFormik.errors.name}
                    errorHint={registerFormik.errors.name}
                  />
                  <DSInput
                    label={LANG.login.register.form.email}
                    id="email"
                    type="email"
                    name="email"
                    onChange={registerFormik.handleChange}
                    value={registerFormik.values.email}
                    error={registerFormik.touched.email && !!registerFormik.errors.email}
                    errorHint={registerFormik.errors.email}
                  />
                  <DSPasswordGenerator
                    label={LANG.login.register.form.password}
                    id="password"
                    name="password"
                    onChange={passwordGeneratorOnChange}
                    value={registerFormik.values.password}
                    error={registerFormik.touched.password && !!registerFormik.errors.password}
                    errorHint={registerFormik.errors.password}
                    autocomplete="off"
                    copyToClipboard
                    className='w-full'
                    generator={false}
                  />
                  <DSPasswordGenerator
                    label={LANG.login.register.form.passwordConfirm}
                    id="passwordConfirm"
                    name="passwordConfirm"
                    onChange={passwordGeneratorOnChange}
                    value={registerFormik.values.passwordConfirm}
                    error={registerFormik.touched.passwordConfirm && !!registerFormik.errors.passwordConfirm}
                    errorHint={registerFormik.errors.passwordConfirm}
                    autocomplete="off"
                    copyToClipboard
                    className='w-full'
                    generator={false}
                  />
                  <DSButton
                    type="submit"
                    className="w-full"
                    disabled={registerQuery.isLoading}
                  >
                    {LANG.login.register.form.cta}
                  </DSButton>
                </form>
              )
            }
          </DSCard>
        </div>
      </main>
      <DSIconSource />
    </Layout>
  );
};

export default Login;
