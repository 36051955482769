const services = Object.freeze({
  GOOGLE: "google",
  YOUTUBE: "youtube",
  AMAZON: "amazon",
  GOOGLE_SUGGEST: "google_suggest",
});

export const principal = services.GOOGLE;
export const secondary = Object.values(services).filter(
  (service) => service !== principal
);

export default services;
