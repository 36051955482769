/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import PropTypes from "prop-types";

const Context = React.createContext({}); // eslint-disable-line no-unused-vars

export function KeywordsContextProvider({ children }) {
  const [keywordsData, setKeywordsData] = useState(null);

  return (
    <Context.Provider
      value={{
        keywordsData,
        setKeywordsData,
      }}
    >
      {children}
    </Context.Provider>
  );
}

KeywordsContextProvider.propTypes = {
  children: PropTypes.node,
};

KeywordsContextProvider.defaultProps = {
  children: null,
};

export default Context;
