import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useQuery, useMutation } from "react-query";
import * as Yup from "yup";

import NotificationContext from 'context/NotificationsContext';

import { registerUser, getUser, updateUser } from "api/users";

import { DSPasswordGenerator, DSInput, DSSelect, DSButton } from "components/DS";

import { LANG } from 'hooks/manageLanguage';

const loginSchema = Yup.object().shape({
  name: Yup.string().required(LANG.users.form.errors.name),
  email: Yup.string()
    .email(LANG.users.form.errors.email.invalid)
    .required(LANG.users.form.errors.email.required)
    .matches(
      /@vodafone.com|@corp.vodafone.es/,
      LANG.users.form.errors.email.corporative
    ),
  role: Yup.string().required(LANG.users.form.errors.role),
  password: Yup.string().when('action', {
    is: 'register',
    then: Yup.string().required(LANG.users.form.errors.password),
    otherwise: Yup.string()
  })
});

const roleOptions = LANG.users.form.role.values;

const UsersEditor = ({ id, openList }) => {
  const { setNotification } = useContext(NotificationContext);

  const mutation = useMutation(
    (values) => {
      if (id === undefined) return registerUser(values);
      return updateUser(id, values);
    },
    {
      onSuccess: (data) => {
        if (data.status === 201 || data.status === 200) {
          setNotification({
            icon: 'like',
            text: data.status === 201 ? LANG.users.create.responses.ok : LANG.users.edit.responses.ok,
            color: 'success'
          });

          openList();
        } else {
          setNotification({
            icon: 'error',
            text: `${data.data.message.charAt(0).toUpperCase() + data.data.message.slice(1)}`,
            color: 'danger'
          });
        }
      },
    }
  )

  const { data, isLoading } = useQuery(["userEditorGetUser"], () => getUser(id), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: id !== undefined
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      role: "admin",
      action: id === undefined ? 'register' : 'edition',
    },
    enableReinitialize: true,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const { action, ...rest } = values;
      mutation.mutate(rest);
    },
  });

  const onChangePassword = (password) => {
    formik.setValues({
      ...formik.values,
      password,
    })
  }

  const onCopyPassword = () => {
    setNotification({
      icon: 'warning',
      text: LANG.users.form.password.copy,
      color: 'warning'
    });
  }

  useEffect(() => {
    if (data !== undefined) {
      formik.setValues({
        name: data.data.name,
        email: data.data.email,
        password: data.data.password,
        role: data.data.role,
        action: id === undefined ? 'register' : 'edition'
      });
    }
  }, [data]);

  return id !== undefined && isLoading === true ? (
    <p>{LANG.users.loading}</p>
  ) : (
    <form autoComplete="off" className="bg-gray-200" onSubmit={formik.handleSubmit}>
      <p className="text-xl mt-12 bg-gray-50 w-full text-center">
        {id === undefined ? LANG.users.create.title : LANG.users.edit.title}
      </p>
      <fieldset>
        <legend className="bg-gray-50 px-12 py-8 w-full text-center">
          <span className="text-m block font-bold">
            {id === undefined ? LANG.users.create.description : LANG.users.edit.description}
          </span>
        </legend>
        <div className="flex justify-center flex-col py-8 px-20">
          <input type='hidden' id='action' name='action' value={formik.values.action} />
          <div>
            <DSInput
              label={LANG.users.form.name}
              id="name"
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && !!formik.errors.name}
              errorHint={formik.errors.name}
              autocomplete="off"
            />
          </div>
          <div className="mt-4">
            <DSInput
              label={LANG.users.form.email}
              id="email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && !!formik.errors.email}
              errorHint={formik.errors.email}
              autocomplete="off"
            />
          </div>
          <div className="mt-4">
            <DSSelect
              label={LANG.users.form.role.title}
              id="role"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
              error={formik.touched.role && !!formik.errors.role}
              errorHint={formik.errors.role}
              options={roleOptions}
              autocomplete="off"
            />
          </div>
          <div className="mt-4">
            <DSPasswordGenerator
              label={id === undefined ? LANG.users.create.password : LANG.users.edit.password}
              id="password"
              name="password"
              onChange={onChangePassword}
              error={formik.touched.password && !!formik.errors.password}
              errorHint={formik.errors.password}
              autocomplete="off"
              copyToClipboard
              onCopy={onCopyPassword}
            />
          </div>
        </div>
      </fieldset>
      <div className="bg-gray-50 flex justify-center flex-col py-8 px-20">
        <DSButton
          type="submit"
          className="w-full"
          disabled={mutation.isLoading}
        >
          {id === undefined ? LANG.users.create.cta : LANG.users.edit.cta}
        </DSButton>
      </div>
    </form>
  );
};

UsersEditor.propTypes = {
  id: PropTypes.string,
  openList: PropTypes.func,
};

UsersEditor.defaultProps = {
  id: undefined,
  openList: null,
};

export default UsersEditor;
