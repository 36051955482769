/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import PropTypes from "prop-types";

import manageStorage from "hooks/manageStorage";

const Context = React.createContext({}); // eslint-disable-line no-unused-vars

export function LoginContextProvider({ children }) {
  const { get } = manageStorage();

  const [userLogin, setUserLogin] = useState(() => get());

  return (
    <Context.Provider
      value={{
        userLogin,
        setUserLogin,
      }}
    >
      {children}
    </Context.Provider>
  );
}

LoginContextProvider.propTypes = {
  children: PropTypes.node,
};

LoginContextProvider.defaultProps = {
  children: null,
};

export default Context;
