/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import PropTypes from 'prop-types'

const Context = React.createContext({}); // eslint-disable-line no-unused-vars

export function NotificationsContextProvider({ children }) {
  const [notification, setNotification] = useState();

  return (
    <Context.Provider
      value={{
        notification,
        setNotification,
      }}
    >
      {children}
    </Context.Provider>
  );
}

NotificationsContextProvider.propTypes = {
  children: PropTypes.node,
};

NotificationsContextProvider.defaultProps = {
  children: null,
};

export default Context;
