/* eslint no-underscore-dangle: ["error", { "allow": ["_retry", "__isRetryRequest"] }] */
import axios from "axios";
import { refreshTokens } from "api/refreshTokens";
import manageStorage from "./manageStorage";

const refreshToken = () => {
  const { get, refresh, remove } = manageStorage();

  axios.interceptors.response.use(
    // Si la respuesta del servidor es correcta, seguimos con la petición
    (response) => response,
    (error) => {
      // Si la respuesta del servidor es un error, comprobamos que dicho error sea un 401 (no autorizado) y que tengamos un token guardado.
      if (!error.response || error.response.status !== 401)
        return error.response;

      return new Promise((resolve) => {
        const originalRequest = error.config;

        if (
          error.response &&
          error.response.status === 401 &&
          error.config &&
          !error.config.__isRetryRequest &&
          get()
        ) {
          originalRequest._retry = true;

          // Renovamos el token a través de refreshTokens() y repetimos la petición original con el nuevo token recibido.
          const resp = refreshTokens().then((res) => {
            refresh(res);
            return axios({
              ...originalRequest,
              headers: {
                  Authorization: `Bearer ${get().data.tokens.access.token}`,
              },
            });
          })
          .catch(() => {
            // Si el refreshTokens() falla porque se ha modificado y por tanto no podemos renovarlo, eliminamos el localstorage y recargamos la página para una correcta navegación desde cero.
            remove()
            window.location.reload()
          });

          resolve(resp)
        }

        return Promise.reject(error);
      });
    }
  );
};

export default refreshToken;
