import manageStorage from './manageStorage';
import * as lang from '../assets/lang';

const { get } = manageStorage();

const LANG = lang[get()?.data?.user.language || 'es'];
const languages = Object.entries(lang)
  .map(([key, value]) => ({
    value: key,
    label: value.language,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export { LANG, languages };
