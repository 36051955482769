/* eslint no-use-before-define: "off" */
import React, { useContext } from "react";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';

import { resetPassword } from 'api/users';

import NotificationContext from "context/NotificationsContext"

import Layout from 'components/Layout/Layout';
import { DSButton, DSPasswordGenerator } from 'components/DS';

import { LANG } from 'hooks/manageLanguage';

import LogoVF from "../../assets/images/vf-logo.png";
import LogoSplinter from "../../assets/images/splinter.png";

const schema = Yup.object().shape({
  password: Yup.string().required(LANG.resetPassword.form.errors.password.required)
    .test('password-chars', LANG.resetPassword.form.errors.password.pattern,
    (value) => {
      if (value && value !== '' && (!value.match(/\d/) || !value.match(/[a-zA-Z]/))) return false;
      if (value && value !== '' && value.length < 8) return false;
      return true;
    }
  ),
  passwordConfirm: Yup.string().required(LANG.resetPassword.form.errors.passwordConfirm.required).oneOf([Yup.ref('password'), null], LANG.resetPassword.form.errors.passwordConfirm.different),
});

const ResetPassword = () => {
	const { token } = useParams();
  const { setNotification } = useContext(NotificationContext);
  const history = useHistory();

  const resetPasswordMutation = useMutation((values) => resetPassword({ values, token }), {
    onSuccess(data) {
      if (data.status === 404)
        setNotification({
          icon: 'error',
          text: data.data.message,
          color: 'danger'
        });
      else {
        setNotification({
          icon: 'like',
          text: LANG.resetPassword.form.results.ok,
          color: 'success'
        });
        history.push('/login');
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      resetPasswordMutation.mutate({ password: values.password });
    },
  });

  const passwordGeneratorOnChange = (_, e) => {
    formik.handleChange(e);
  }

  return (
    <Layout>
      <main className="bg-gradient-to-r from-primary-dark to-primary absolute inset-0 flex justify-center items-center">
        <div className='flex bg-white rounded-xl flex-col relative z-10 text-center p-12 min-w-tooltip min-h-activationCard'>
          <div className="flex flex-0 items-start justify-between pb-4 border-b-2 border-primary-600 mb-8">
            <img className="w-8 h-8 ml-4 mr-8" src={LogoVF} alt="" />
            <h1 className="text-2xl">{LANG.resetPassword.title}</h1>
            <img className="w-8 h-8 ml-8 mr-4" src={LogoSplinter} alt="" />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <DSPasswordGenerator
              label={LANG.resetPassword.form.password}
              id="password"
              name="password"
              onChange={passwordGeneratorOnChange}
              value={formik.values.password}
              error={formik.touched.password && !!formik.errors.password}
              errorHint={formik.errors.password}
              autocomplete="off"
              copyToClipboard
              className='w-full'
              generator={false}
            />
            <DSPasswordGenerator
              label={LANG.resetPassword.form.passwordConfirm}
              id="passwordConfirm"
              name="passwordConfirm"
              onChange={passwordGeneratorOnChange}
              value={formik.values.passwordConfirm}
              error={formik.touched.passwordConfirm && !!formik.errors.passwordConfirm}
              errorHint={formik.errors.passwordConfirm}
              autocomplete="off"
              copyToClipboard
              className='w-full mt-4'
              generator={false}
            />
            <DSButton
              type='submit'
              className='w-full mt-4'
            >
              {LANG.resetPassword.form.cta}
            </DSButton>
          </form>
        </div>
      </main>
    </Layout>
  );
}

export default ResetPassword;
