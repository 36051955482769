import React from 'react';
import { UsersContextProvider } from 'context/UsersContext';

import Layout from "components/Layout/Layout";
import { DSAccordion } from 'components/DS';

import { LANG } from 'hooks/manageLanguage';

import images from './FaqsImages';

const faqImages = [
  [images.faqs01, images.faqs02, images.faqs03, images.faqs04, images.faqs05, images.faqs06],
  [images.faqs07, images.faqs08, images.faqs09, images.faqs10],
  [images.network, images.circlepacking, images.sankey],
  [images.faqs11, images.faqs12],
];

const Faqs = () => (
  <UsersContextProvider>
      <Layout>
        <div className='w-full h-full flex flex-col items-center overflow-auto'>
          <h2 className='w-full max-w-modalList py-20 text-center block text-4xl font-bold'>{LANG.faqs.title}</h2>
          <ul className='w-full max-w-modalList px-10'>
            <li className='border-b border-gray-300 py-4 mb-4'>
              <DSAccordion title={LANG.faqs.elements[0].title}>
                <ol className='grid grid-cols-3 gap-8 pl-4 pt-6'>
                  { LANG.faqs.elements[0].steps.map((step, idx) =>
                    <li className='flex flex-col gap-8'>
                      <div className='flex items-start gap-4'>
                        <span className='text-2xl font-bold'>{idx + 1}.</span>
                        <p className='font-normal'>{step}</p>
                      </div>
                      <img src={faqImages[0][idx]} alt='' />
                    </li>
                  )}
                </ol>
              </DSAccordion>
            </li>
            <li className='border-b border-gray-300 py-4 mb-4'>
              <DSAccordion title={LANG.faqs.elements[1].title}>
                <ol className='grid grid-cols-3 gap-8 pl-4 pt-6'>
                  { LANG.faqs.elements[1].steps.map((step, idx) =>
                    <li className='flex flex-col gap-8'>
                      <div className='flex items-start gap-4'>
                        <span className='text-2xl font-bold'>{idx + 1}.</span>
                        <p className='font-normal'>{step}</p>
                      </div>
                      <img src={faqImages[1][idx]} alt='' />
                    </li>
                  )}
                </ol>
              </DSAccordion>
            </li>
            <li className='border-b border-gray-300 py-4 mb-4'>
              <DSAccordion title={LANG.faqs.elements[2].title}>
                <p className='mb-6 pt-6'>{LANG.faqs.elements[2].description}</p>
                <ol className='grid grid-cols-3 gap-8 pl-4'>
                  { LANG.faqs.elements[2].steps.map((step, idx) =>
                    <li className='flex flex-col gap-8'>
                      <div className='flex items-start gap-4'>
                        <span className='text-2xl font-bold'>{idx + 1}.</span>
                        <p className='font-normal'>{step}</p>
                      </div>
                      <img src={faqImages[2][idx]} alt='' />
                    </li>
                  )}
                </ol>
              </DSAccordion>
            </li>
            <li className='border-b border-gray-300 py-4 mb-4'>
              <DSAccordion title={LANG.faqs.elements[3].title}>
                <p className='mb-6 pt-6'>{LANG.faqs.elements[3].description}</p>
                <ul className='flex flex-col gap-8 pl-4'>
                  { LANG.faqs.elements[3].steps.map((step, idx) =>
                    <li className='flex gap-8'>
                      <img src={faqImages[3][idx]} alt='' className='max-w-faqsImages' />
                      <div className='flex items-start gap-4'>
                        <p className='font-normal'>{step}</p>
                      </div>
                    </li>
                  )}
                </ul>
              </DSAccordion>
            </li>
            <li className='border-b border-gray-300 py-4 mb-4'>
              <DSAccordion title={LANG.faqs.elements[4].title}>
                { LANG.faqs.elements[4].elements.map((step, idx) =>
                  <p className={idx === 0 ? 'mb-6 pt-6' : 'mb-6'}>{step}</p>
                )}
              </DSAccordion>
            </li>
            <li className='border-b border-gray-300 py-4 mb-4'>
              <DSAccordion title={LANG.faqs.elements[5].title}>
                <p className='pt-6'>{LANG.faqs.elements[5].description}</p>
              </DSAccordion>
            </li>
          </ul>
        </div>
      </Layout>
  </UsersContextProvider>
);

export default Faqs;