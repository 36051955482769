import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";
import DSInput from "../DSInput/DSInput";
import DSIcon from "../DSIcon/DSIcon";

const cnBase = classnames(
  "relative"
);
const cnBaseList = classnames(
  "rounded",
  "bg-white",
  "border",
  "border-gray-300",
  "max-h-60",
  "overflow-auto",
  "absolute",
  "w-full",
  "z-10"
);
const cnBaseListElement = classnames(
  "border-gray-300",
  "w-full",
);
const cnBaseListTopElements = classnames(
	cnBaseListElement,
	"border-b"
)
const cnBaseListButton = classnames(
	"hover:bg-primary",
	"hover:text-white",
	"focus:bg-primary",
	"focus:text-white",
	"focus:underline",
  "p-2",
	"text-left",
  "w-full",
);
const cnLoading = classnames(
  "absolute",
  "right-0",
  "mt-0.5",
  "top-6",
  "text-gray-500",
  "w-12",
);
const cnLoadingHidden = classnames(
  cnLoading,
  "hidden",
);
const cnLoadingVisible = classnames(
  cnLoading,
  "visible",
);

const generateKey = (pre) =>`${ pre }_${ new Date().getTime() }_${Math.random()}`;

const DSAutoComplete = ({
  label,
  id,
  placeholder,
  error,
  errorHint,
  name,
  onChange,
  onClick,
  onKeyUp,
  disabled,
  options,
  loading,
}) => {
  const [input, setInput] = useState({ label: '', value: '' });

  const setFields = ({ label: lab, value: val }) => (
    options.filter((option) => option.label === lab || option.value === val)[0]
  );

  const changeValue = (e) => {
    setInput({ label: e.target.value, value: '' });
  }
  const selectValue = (e) => {
    setInput({ ...setFields({ value: e.target.value }), trigger: 'button' });
  }

  useEffect(() => {
    onChange(input);
  }, [input]);

  return (
    <div className={cnBase}>
			<DSInput
				label={label}
				id={id}
				value={input.label}
				placeholder={placeholder}
				error={error}
				errorHint={errorHint}
				type='text'
				name={name}
				onChange={changeValue}
				onClick={onClick}
				onKeyUp={onKeyUp}
				disabled={disabled}
				autocomplete='off'
        loading={false}
			/>
      <DSIcon id='loading' className={loading && input.trigger !== 'button' ? cnLoadingVisible : cnLoadingHidden} />
			{ options.length > 0 && input.label.length > 1 && options.filter((opt) => opt.value === input.value).length === 0 && !loading && (
				<ul className={cnBaseList}>
					{
						options.map((option, index) => (
              <li key={generateKey(option.value)} className={index + 1 === options.length ? cnBaseListElement : cnBaseListTopElements}>
                <button type='button' name={option.value} className={cnBaseListButton} onClick={selectValue} value={option.value}>{ option.label }</button>
              </li>
						))
					}
				</ul>
			)}
    </div>
  );
};

DSAutoComplete.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorHint: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
	options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
	),
  loading: PropTypes.bool,
};

DSAutoComplete.defaultProps = {
  placeholder: null,
  error: false,
  errorHint: null,
  name: null,
  onChange: null,
  onClick: null,
  onKeyUp: null,
  disabled: false,
	options: null,
  loading: false,
};

export default DSAutoComplete;
