import React from "react";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";

import "./DSInputRange.css";

const DSInputRange = ({ min, max, step, className, onChange, value }) => {
  const classNames = classnames(
    className,
    "rounded-lg",
    "appearance-none",
    "bg-gray-200",
    "h-3"
  );
  const handleChange = (e) => {
    onChange(e);
  }
  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      className={classNames}
      onChange={handleChange}
      defaultValue={value}
    />
  );
};

DSInputRange.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

DSInputRange.defaultProps = {
  min: null,
  max: null,
  step: null,
  className: null,
  onChange: null,
  value: 0,
};

export default DSInputRange;
