import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { classnames } from "tailwindcss-classnames";

import { getKeywords } from "api/keywords";

import { DSButton, DSAutoComplete, DSTooltip, DSInput } from "components/DS";
import GraphRadio from 'components/Common/GraphRadio/GraphRadio';
import DSDatepicker from "components/DS/Atoms/DSDatepicker/DSDatepicker";

import networkPic from "assets/images/network.png";
import circlePackingPic from "assets/images/circlepacking.png";
import sankeyPic from "assets/images/sankey.png";

import { LANG } from 'hooks/manageLanguage';

const cnTop4 = classnames("mt-4");
const cnDatepickerDisabled = classnames(cnTop4, "opacity-25")
const cnFullWidth = classnames("w-full");
const cnPeopleAlsoAskCta = classnames("w-full border border-primary mt-2");
const cnLegend = classnames("text-xl", "font-bold", "text-left", "mb-4");
const cnBottomFieldset = classnames("mt-4", "mb-4");
const cnBottomFieldsetDisabled = classnames(cnBottomFieldset, "opacity-25", "pointer-events-none");
const cnBottomLegend = classnames("block", "text-base", "text-gray-900", "pb-2", "text-left");
const cnBottomList = classnames(cnFullWidth, "flex", "justify-around", "gap-3");

const cnDatepickerLegend = classnames('w-full flex gap-4 justify-between items-center px-5 py-2 border border-l-0 border-r-0 border-gray-200');
const cnDatepickerLegendIcon = classnames('h-4 w-4 border border-primary block rounded-full relative');
const cnDatepickerLegendSubIcon = classnames('-top-1 -right-1 -bottom-1 -left-1 border border-primary radius border-dotted block rounded-full absolute');
const cnDatepickerLegendText = classnames('text-xs font-bold max-w-datepickerLegend text-left');
const cnDatepickerLegendHiddenText = classnames('sr-only');

const keywordSchema = Yup.object().shape({
  keyword: Yup.string().required(LANG.formKeywords.form.errors.keyword),
  date: Yup.string().required(LANG.formKeywords.form.errors.date),
  chartType: Yup.string().required(LANG.formKeywords.form.errors.chartType),
});

let autocompleteTimeout;

const datepickerLabel =
  <>
    {LANG.formKeywords.form.date.title}
    <DSTooltip
      icon='support'
      title={LANG.formKeywords.form.date.tooltip.title}
      description={LANG.formKeywords.form.date.tooltip.description}
    />
  </>

const DatepickerLegend = () => (
  <div className={cnDatepickerLegend}>
    <i className={cnDatepickerLegendIcon}>
      <span className={cnDatepickerLegendSubIcon}>&nbsp;</span>
      <span className={cnDatepickerLegendHiddenText}>Borde punteado</span>
    </i>
    <p className={cnDatepickerLegendText}>{LANG.formKeywords.form.date.legend}</p>
  </div>
);

const FormKeywords = () => {
  const [ keywordValue, setKeywordValue ] = useState({ keyword: '', slug: '', search: true });
  const [ options, setOptions ] = useState({ original: [], reduced: [] });
  const [ dates, setDates ] = useState(undefined);
  const [ selected, setSelected ] = useState(undefined);
  const [ peopleAlsoAsk, setPeopleAlsoAsk ] = useState(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      keyword: "",
      date: "",
      chartType: "",
      id: "",
    },
    validationSchema: keywordSchema,
    onSubmit: (values) => {
      history.push(`${values.chartType.toString()}/${values.keyword.toString()}/${values.id.toString()}`)
    },
  });


  const { isFetching } = useQuery(["dashboardKeyword", keywordValue], () => getKeywords({
      value: keywordValue.keyword,
      like: "value",
      groupBy: 'value, slug',
    }),
    {
      // only fetch search terms longer than 2 characters
      enabled: keywordValue.keyword?.length > 1 && keywordValue.search,
      onSuccess: (data) => {
        const reduced = data.data.results.filter((element, index, self) =>
          index === self.findIndex((e) => e.value === element.value)
        )
          .map((option) => ({ label: option.value, value: option.slug }))
          .sort((a, b) => a.value.localeCompare(b.value));
        setOptions({ original: data.data.results, reduced });
      }
    }
  );

  const changeDatepicker = (val) => {
    setSelected(
      options.original
        .filter((option) =>
          option.slug === keywordValue.slug &&
          new Date(option.created_at.value).setHours(0,0,0,0) === val.setHours(0,0,0,0)
      )[0]
    );
  }

  const generateDates = (slug) => {
    const datesFiltered = options.original
      .filter((option) => option.slug === slug)
      .map((val) => new Date(val.created_at.value))
      .sort((a, b) => new Date(b) - new Date(a));

    const featuredDates = options.original
      .filter((option) => option.slug === slug && option.alsoasked)
      .map((val) => new Date(val.created_at.value))
      .sort((a, b) => new Date(b) - new Date(a));

    setDates({ dates: datesFiltered, featured: featuredDates });

    // Si existen, marcamos la primera fecha por defecto
    if (datesFiltered.length > 0 && !selected) changeDatepicker(datesFiltered[0]);
    if (featuredDates.length > 0 && featuredDates[0] === datesFiltered[0]) setPeopleAlsoAsk(true);
  };

  const changeAutocomplete = (res) => {
    clearTimeout(autocompleteTimeout);
    setKeywordValue({ ...keywordValue, search: false });
    setSelected(undefined);
    autocompleteTimeout = setTimeout(() => {
      setKeywordValue({ keyword: res.label, slug: res.value, search: true });
      formik.setFieldValue('keyword', res.value);
    }, 800);
  }

  const submitPeopleAlsoAsk = () => history.push(`people-also-ask/${formik.values.keyword.toString()}/${formik.values.id.toString()}`);

  useEffect(() => {
    formik.setFieldValue('date', selected?.created_at.value);
    formik.setFieldValue('id', selected?.keyword_id);
    setPeopleAlsoAsk(selected?.alsoasked);
  }, [selected]);

  useEffect(() => {
    if (keywordValue.slug !== '') {
      generateDates(keywordValue.slug);
    } else {
      setDates(undefined);
      formik.setFieldValue('date', '');
      formik.setFieldValue('id', '');
    }
  }, [keywordValue, options]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <fieldset>
        <legend className={cnLegend}>
          {LANG.formKeywords.title}
        </legend>
        <DSAutoComplete
          label={LANG.formKeywords.form.keyword.title}
          id='keyword'
          name='keyword'
          placeholder={LANG.formKeywords.form.keyword.placeholder}
          error={formik.touched.keyword && !!formik.errors.keyword}
          errorHint={formik.errors.keyword}
          disabled={false}
          options={options.reduced}
          onChange={changeAutocomplete}
          loading={isFetching}
        />
        { dates !== undefined && dates?.dates.length > 0 ? (
          <DSDatepicker
            label={datepickerLabel}
            id='date'
            name='date'
            filter={dates.dates}
            featured={dates.featured}
            classes={cnTop4}
            error={formik.touched.date && !!formik.errors.date}
            errorHint={formik.errors.date}
            onChange={changeDatepicker}
            headerLegend={DatepickerLegend()}
          />
        ) : (
          <div className={cnDatepickerDisabled}>
            <DSInput
              label={datepickerLabel}
              disabled
            />
          </div>
        )}
      </fieldset>
      <fieldset className={dates !== undefined && dates?.dates.length > 0 ? cnBottomFieldset : cnBottomFieldsetDisabled}>
        <legend className={cnBottomLegend}>
          {LANG.formKeywords.form.graph.title}
        </legend>
        <ul className={cnBottomList}>
          <li className='w-full'>
            <GraphRadio
              title={LANG.formKeywords.form.graph.network.title}
              description={LANG.formKeywords.form.graph.network.description}
              image={networkPic}
              value='network'
              name='chartType'
              onChange={formik.handleChange}
              checked={formik.values.chartType === 'network'}
              className='h-full'
            />
          </li>
          <li className='w-full'>
            <GraphRadio
              title={LANG.formKeywords.form.graph.circlePacking.title}
              description={LANG.formKeywords.form.graph.circlePacking.description}
              image={circlePackingPic}
              value='circle-packing'
              name='chartType'
              onChange={formik.handleChange}
              checked={formik.values.chartType === 'circle-packing'}
              className='h-full'
            />
          </li>
          <li className='w-full'>
            <GraphRadio
              title={LANG.formKeywords.form.graph.sankey.title}
              description={LANG.formKeywords.form.graph.sankey.description}
              image={sankeyPic}
              value='sankey'
              name='chartType'
              onChange={formik.handleChange}
              checked={formik.values.chartType === 'sankey'}
              className='h-full'
            />
          </li>
        </ul>
        {
          formik.touched.chartType && !!formik.errors.chartType && (
            <p className="text-xs mt-2 text-danger text-left">{formik.errors.chartType}</p>
          )
        }
      </fieldset>
      <DSButton
        type="submit"
        className={cnFullWidth}
        disabled={
          formik.values.keyword === '' ||
          formik.values.chartType === '' ||
          formik.values.id === ''
        }>
        {LANG.formKeywords.form.cta}
      </DSButton>
      { peopleAlsoAsk && (
        <>
          <p className='mt-4 text-left'>
            {LANG.formKeywords.form.alsoasked.title}
          </p>
          <DSButton type="button" variant='secondary' className={cnPeopleAlsoAskCta} onClick={submitPeopleAlsoAsk}>
            {LANG.formKeywords.form.alsoasked.cta}
          </DSButton>
        </>
      ) }
    </form>
  );
};

export default FormKeywords;