import React, { useContext } from "react";
import { HashRouter as Switch, Route, Redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

import { refreshTokens } from "api/refreshTokens";

import LoginContext, { LoginContextProvider } from "context/LoginContext";
import { NotificationsContextProvider } from "context/NotificationsContext";

import manageStorage from "hooks/manageStorage";

import { ReactQueryDevtools } from "react-query/devtools";
import PeopleAlsoAsk from 'routes/PeopleAlsoAsk/PeopleAlsoAsk';
import CirclePacking from "./routes/CirclePacking/CirclePacking";
import Network from "./routes/Network/Network";
import Sankey from "./routes/Sankey/Sankey";
import Login from "./routes/Login/Login";
import Activate from "./routes/Activate/Activate";
import ResetPassword from "./routes/ResetPassword/ResetPassword";
import Reactivate from "./routes/Reactivate/Reactivate";
import Glossary from "./routes/Glossary/Glossary";
import Faqs from "./routes/Faqs/Faqs";
import Dashboard from "./routes/Dashboard/Dashboard";
import ContentAnalysis from "./routes/ContentAnalysis/ContentAnalysis";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const RouterComponent = () => {
    const { userLogin, setUserLogin } = useContext(LoginContext);
    const { get, refresh, remove } = manageStorage();

    const { isLoading } = useQuery(["token"], () => refreshTokens(), {
      onSuccess: (response) => {
        if (response.code !== 401) {
          refresh(response)
        } else {
          remove()
          setUserLogin(null)
        }
      },
      retry: false,
      enabled: get() !== null
    });

    return (
      <>
        { isLoading || (
          <Switch>
            <Route exact path="/">
              {userLogin !== null ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route path="/login" component={Login}>
              {userLogin !== null ? <Redirect to="/dashboard" /> : null}
            </Route>
            <Route path="/activate/:token" component={Activate}>
              {userLogin !== null ? <Redirect to="/dashboard" /> : null}
            </Route>
            <Route path="/reset-password/:token" component={ResetPassword}>
              {userLogin !== null ? <Redirect to="/dashboard" /> : null}
            </Route>
            <Route path="/reactivate/:token" component={Reactivate}>
              {userLogin !== null ? <Redirect to="/dashboard" /> : null}
            </Route>
            {userLogin !== null ? (
              <>
                <Route path="/circle-packing/:slug/:id" component={CirclePacking} />
                <Route path="/network/:slug/:id" component={Network} />
                <Route path="/sankey/:slug/:id" component={Sankey} />
                <Route path="/people-also-ask/:slug/:id" component={PeopleAlsoAsk} />
                <Route path="/glossary" component={Glossary} />
                <Route path="/faqs" component={Faqs} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/contents-analysis" component={ContentAnalysis} />
              </>
            ) : (
              <Route path="*" render={({ match }) => {
                if (
                  match.url.indexOf('/activate') === -1 &&
                  match.url.indexOf('/reset-password') === -1 &&
                  match.url.indexOf('/reactivate') === -1
                )
                  return <Redirect to="/login" />
                return true;
              }}
              />
            )
          }
          </Switch>
        )}
      </>
    );
  };

  return (
    <LoginContextProvider>
      <QueryClientProvider client={queryClient}>
        <NotificationsContextProvider>
          <RouterComponent />
          <ReactQueryDevtools initialIsOpen={false} />
        </NotificationsContextProvider>
      </QueryClientProvider>
    </LoginContextProvider>
  );
};

export default App;
