import React, { useState } from 'react';
import classnames from "tailwindcss-classnames";
import PropTypes from "prop-types";
import { format } from 'date-fns';

import DSIcon from '../DSIcon/DSIcon';

const cnKeywordBase = classnames('relative', 'flex', 'justify-between', 'gap-4', 'w-full', 'p-4', 'min-h-full');
const cnIconBase = classnames('w-8', 'h-8');
const cnIconFav = classnames(cnIconBase, 'text-primary');
const cnIconInfo = classnames(cnIconBase, 'text-gray-400');

const KeywordState = ({ keyword, state, disabled, onClick }) =>
  <button type='button' onClick={() => onClick({ keyword, state: !state })} disabled={disabled}>
    { !disabled &&
      (state ?
        <>
          <DSIcon id='favourite' className={cnIconFav} />
          <span className='sr-only'>Quitar {keyword.value} como favorita</span>
        </>
      :
        <>
          <DSIcon id='favouriteInactive' className={cnIconFav} />
          <span className='sr-only'>Marcar {keyword.value} como favorita</span>
        </>
      )
    }
    { disabled && <DSIcon id='loading' className={cnIconBase} />}
  </button>

KeywordState.propTypes = {
  keyword: PropTypes.shape({
    keyword_id: PropTypes.string,
    value: PropTypes.string,
    created_at: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        last_login: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        id: PropTypes.string,
      })
    ),
  }).isRequired,
  state: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

KeywordState.defaultProps = {
  disabled: false,
  onClick: null,
};

const KeywordUsers = ({ keyword }) => {
  const [ usersList, setUsersList ] = useState(false);

  const showKeywordUsers = () => {
    setUsersList(true);
  };

  const hideKeywordUsers = () => {
    setUsersList(false);
  };

  const cnUsersListBase = classnames('absolute', 'p-6', 'bg-white', 'right-1/2', 'top-full', 'border', 'border-gray-300', 'transform translate-x-1/2', 'transition-all', 'duration-300');
  const cnUsersList = classnames(
    cnUsersListBase,
    usersList ? ['pointer-events-auto', 'opacity-100', 'translate-y-0'] : ['pointer-events-none', 'opacity-0', 'translate-y-4'],
  );

  return (
    <button type='button' className='relative bg-white z-10' onMouseOver={showKeywordUsers} onMouseOut={hideKeywordUsers} onFocus={showKeywordUsers} onBlur={hideKeywordUsers}>
      <DSIcon id='family' className={cnIconInfo} />
      <span className='sr-only'>Ver usuarios de {keyword.value}</span>
      <ul className={cnUsersList}>
        { keyword.users.map((user) =>
          <li key={user.id}>
            <strong className='whitespace-nowrap'>{user.name}</strong>
          </li>
        ) }
      </ul>
    </button>
  );
};

KeywordUsers.propTypes = {
  keyword: PropTypes.shape({
    keyword_id: PropTypes.string,
    value: PropTypes.string,
    created_at: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        last_login: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        id: PropTypes.string,
      })
    ),
  }).isRequired,
};

const DSGlossaryKeyword = ({ keyword, state, disabled, featured, onClickUpdate, showUsers }) => {
  const cnKeyword = classnames(
    cnKeywordBase,
    {
      'border-gray-300': !featured,
      'border-primary': featured,
      'border': !featured,
      'border-2': featured,
    }
  );

  return (
    <div className={cnKeyword} data-prueba={featured}>
      <div>
        <p className='font-bold'>{keyword.value}</p>
        <span>{format(new Date(keyword.created_at), 'dd/MM/yyyy')}</span>
      </div>
      <div className='flex gap-2 items-center justify-end'>
        { showUsers && featured && <KeywordUsers keyword={keyword} /> }
        <KeywordState keyword={keyword} state={state} disabled={disabled} onClick={onClickUpdate} />
      </div>
    </div>
  );
};

DSGlossaryKeyword.propTypes = {
  keyword: PropTypes.shape({
    keyword_id: PropTypes.string,
    value: PropTypes.string,
    created_at: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        last_login: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        id: PropTypes.string,
      })
    ),
  }).isRequired,
  state: PropTypes.bool,
  disabled: PropTypes.bool,
  featured: PropTypes.bool,
  onClickUpdate: PropTypes.func,
  showUsers: PropTypes.bool,
};

DSGlossaryKeyword.defaultProps = {
  state: false,
  disabled: false,
  featured: false,
  onClickUpdate: null,
  showUsers: false,
};

export default DSGlossaryKeyword;