import axios from "axios";
import refreshToken from "hooks/refreshToken";

const login = (values) =>
  axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/auth/login`,
      values,
      {
        validateStatus: (status) => status < 500,
      }
    )
    .then((res) => res);

const logout = (values) => {
  refreshToken();

  return axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/auth/logout`,
      values
    )
    .then((res) => res);
};

const register = (values) =>
  axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/auth/register`,
      values,
      {
        validateStatus: (status) => status < 500,
      }
    )
    .then((res) => res);

const forgotPassword = (values) =>
  axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/auth/forgot-password`,
      values,
      {
        validateStatus: (status) => status < 500,
      }
    )
    .then((res) => res);

export { login, logout, register, forgotPassword };
