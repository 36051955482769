import faqs01 from 'assets/images/faqs/faqs-01.png';
import faqs02 from 'assets/images/faqs/faqs-02.png';
import faqs03 from 'assets/images/faqs/faqs-03.png';
import faqs04 from 'assets/images/faqs/faqs-04.png';
import faqs05 from 'assets/images/faqs/faqs-05.png';
import faqs06 from 'assets/images/faqs/faqs-06.png';
import faqs07 from 'assets/images/faqs/faqs-07.png';
import faqs08 from 'assets/images/faqs/faqs-08.png';
import faqs09 from 'assets/images/faqs/faqs-09.png';
import faqs10 from 'assets/images/faqs/faqs-10.png';
import faqs11 from 'assets/images/faqs/faqs-11.png';
import network from 'assets/images/network.png';
import circlepacking from 'assets/images/circlepacking.png';
import sankey from 'assets/images/sankey.png';

const images = {
  faqs01,
  faqs02,
  faqs03,
  faqs04,
  faqs05,
  faqs06,
  faqs07,
  faqs08,
  faqs09,
  faqs10,
  faqs11,
  network,
  circlepacking,
  sankey,
}

export default images;