import React from 'react';
import PropTypes from 'prop-types';
import { classnames } from 'tailwindcss-classnames';

const cnLabel = classnames("flex", "items-center", "justify-start", "gap-3", "cursor-pointer");
const cnSwitchBase = classnames("text-0", "w-12", "h-7", "border", "border-gray-300", "rounded-full", "flex", "items-center", "transition-colors", "duration-500", "relative");
const cnSwitchCircleBase = classnames("w-6", "h-6", "bg-white", "border", "border-gray-300", "block", "rounded-full", "absolute", "transform", "transition-all", "duration-500");

const DSSwitch = ({ label, id, name, active, onChange }) => {
  const cnSwitch = classnames(cnSwitchBase,
    { "bg-primary": active },
    { "bg-gray-200": !active },
  );
  const cnSwitchCircle = classnames(cnSwitchCircleBase,
    { "right-0  translate-x-0": active },
    { "right-full translate-x-full": !active },
  );

  return (
    <>
      <input type='checkbox' id={id} name={name} checked={active} onChange={onChange} aria-label={label} className='sr-only' />
      <label htmlFor={id} className={cnLabel}>
        <span className={cnSwitch}>
          <span className={cnSwitchCircle} />
        </span>
        {label}
      </label>
    </>
  );
};

DSSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

DSSwitch.defaultProps = {
  active: false,
  id: '',
  name: '',
  onChange: null,
}

export default DSSwitch;