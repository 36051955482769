import React, { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "react-query";
import PropTypes from "prop-types";

import { logout } from "api/login";
import { updateUser } from 'api/users';
import manageStorage from "hooks/manageStorage";
import LoginContext from "context/LoginContext";

import { DSIcon } from "../DS";

import { LANG, languages } from '../../hooks/manageLanguage';

const languageSelector = false;

const Header = ({ user, onClickUserManagement }) => {
  const { setUserLogin } = useContext(LoginContext);
  const { get, set, remove } = manageStorage();
  const [ userMenu, setUserMenu ] = useState(false);
  const [ languageMenu, setLanguageMenu ] = useState(false);
  const history = useHistory();

  const mutation = useMutation((values) => logout(values), {
    onSuccess() {
      remove();
      setUserLogin(null);
      history.push("/login");
    },
  });

  const updateLanguageMutation = useMutation((values) => updateUser(get().data.user.id, values), {
    onSuccess(data) {
      const storage = { ...get().data, user: data.data };
      set({ data: storage });
      window.location.reload();
    },
    onError(err) {
      console.error(err);
    }
  });

  const handleLogout = () => {
    mutation.mutate({
      refreshToken: get().data?.tokens?.refresh?.token,
    });
  };

  const showUserMenu = () => setUserMenu(true);
  const hideUserMenu = () => setUserMenu(false);

  const showLanguageMenu = () => setLanguageMenu(true);
  const hideLanguageMenu = () => setLanguageMenu(false);

  const changeLanguage = ({ value }) => {
    setLanguageMenu(false);
    updateLanguageMutation.mutate({ language: value });
  };

  return (
    <div className="flex justify-between bg-white border-b border-gray-300 px-4 py-2 relative z-20">
      <Link to='/' className="flex space-x-2 items-center text-gray-800 hover:text-primary">
        <DSIcon id="splinter" className="w-7 h-auto" />
        <span className="text-xl">Splinter</span>
      </Link>

      <ul className='flex gap-6 items-center'>
        { languageSelector &&
          <li key='language' className='relative' onMouseLeave={hideLanguageMenu} onBlur={hideLanguageMenu}>
            <span className='flex items-center space-x-2 cursor-auto' onMouseOver={showLanguageMenu} onFocus={showLanguageMenu}>
              <DSIcon id='countryOrInternational' className='w-6 h-6' />
              <span className="font-bold text-sm">{LANG.language}</span>
            </span>
            { languageMenu &&
              <ul className='absolute flex flex-col justify-items-center -left-6 top-full bg-white pt-3 whitespace-nowrap'>
                { languages.map(({ value, label }) =>
                  <li key={label} className='w-full bg-white py-4 px-8 border border-gray-300 border-t-0 min-w-userMenu'>
                    <button
                      type="button"
                      className={ value === get()?.data?.user.language ?
                        "text-sm font-bold flex items-center space-x-2 hover:text-primary pointer-events-none"
                        :
                        "text-sm font-bold flex items-center space-x-2 hover:text-primary "
                      }
                      onClick={() => changeLanguage({ value })}
                    >
                      <DSIcon id='tickOrSolvedEsp' className={ value === get()?.data?.user.language ? 'w-6 h-6 text-primary' : 'w-6 h-6 text-gray-200' } />
                      <span className='font-bold text-sm'>{label}</span>
                    </button>
                  </li>
                )}
              </ul>
            }
          </li>
        }
        <li key='contents-analysis'>
          <Link to='/contents-analysis' className='flex space-x-2 items-center text-gray-800 hover:text-primary'>
            <DSIcon id='rocket' className='w-6 h-6' />
            <span className="font-bold text-sm">{LANG.menu.contentsAnalysis}</span>
          </Link>
        </li>
        <li key='glossary'>
          <Link to='/glossary' className='flex space-x-2 items-center text-gray-800 hover:text-primary'>
            <DSIcon id='listView' className='w-6 h-6' />
            <span className="font-bold text-sm">{LANG.menu.glossary}</span>
          </Link>
        </li>
        <li key='faqs'>
          <Link to='/faqs' className='flex space-x-2 items-center text-gray-800 hover:text-primary'>
            <DSIcon id='support' className='w-6 h-6' />
            <span className="font-bold text-sm">{LANG.menu.faqs}</span>
          </Link>
        </li>
        <li key='user-management' className='relative' onMouseLeave={hideUserMenu} onBlur={hideUserMenu}>
          <span className='flex items-center space-x-2 cursor-auto' onMouseOver={showUserMenu} onFocus={showUserMenu}>
            <DSIcon id='admin' className='w-6 h-6' />
            <span className="font-bold text-sm">{user?.name}</span>
          </span>
          { userMenu &&
            <ul className='absolute flex flex-col justify-items-center -right-4 top-full bg-white pt-3 whitespace-nowrap'>
              <li key='user-management-edit' className='w-full bg-white py-4 px-8 border border-gray-300 border-t-0 min-w-userMenu'>
                <button
                  type="button"
                  className="text-sm font-bold flex items-center space-x-2 hover:text-primary"
                  onClick={onClickUserManagement}
                >
                  <DSIcon id='settings' className='w-6 h-6' />
                  <span className="font-bold text-sm">{LANG.menu.user.configure}</span>
                </button>
              </li>
              <li key='user-management-logout' className='w-full bg-white py-4 px-8 border border-gray-300 border-t-0 min-w-userMenu'>
                <button
                  type="button"
                  className="text-sm font-bold flex items-center space-x-2 hover:text-primary"
                  onClick={handleLogout}
                >
                  <DSIcon id='logout' className='w-6 h-6' />
                  <span className="font-bold text-sm">{LANG.menu.user.logout}</span>
                </button>
              </li>
            </ul>
          }
        </li>
      </ul>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  onClickUserManagement: PropTypes.func.isRequired,
};

Header.defaultProps = {
  user: undefined,
};

export default Header;
