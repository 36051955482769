import React, { useState, useContext }  from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";

import manageStorage from "hooks/manageStorage";

import LoginContext from "context/LoginContext";

import { getUser } from "api/users";

import Notification from "components/Common/Notification/Notification";
import { DSIconSource } from "components/DS/Atoms/DSIcon/DSIcon";
import Header from "./Header";
import UserManagement from "./UserManagement";

const Layout = ({ children }) => {
  const { get } = manageStorage();
  const [ userManagement, setUserManagement ] = useState(false);
  const { userLogin } = useContext(LoginContext);

  const openUserManagement = () =>  setUserManagement(true);
  const closeUserManagement = () => setUserManagement(false);

  const { data, isLoading, refetch } = useQuery(["userInfo"], () => getUser(get()?.data.user.id));

  return (
    <>
      <div className="h-screen flex flex-col">
        { data && userLogin && <Header onClickUserManagement={openUserManagement} user={data?.data} /> }
        <main className="flex-grow flex overflow-hidden">{children}</main>
      </div>
      <DSIconSource />
      <Notification />
      { userManagement && <UserManagement user={data?.data} loading={isLoading} refetch={refetch} onClose={closeUserManagement} /> }
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
