import axios from "axios";
import manageStorage from "hooks/manageStorage";
import refreshToken from "hooks/refreshToken";

const { get } = manageStorage();

const getKeywordRelations = async (params) => {
  const urlSearchParams = new URLSearchParams(params);

  refreshToken();

  const response = await axios.get(
    `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/keywordRelations?${urlSearchParams}`,
    {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${get().data.tokens.access.token}`,
        "content-type": "application/json",
      },
    }
  );

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response;
};

// eslint-disable-next-line import/prefer-default-export
export { getKeywordRelations };
