import React, { useState, useRef, useContext } from 'react';
import Layout from 'components/Layout/Layout';
import MultiRangeSlider from "multi-range-slider-react";
import { classnames } from 'tailwindcss-classnames';
import { useMutation, useQuery } from 'react-query';
import { getKeywords } from 'api/keywords';
import { DSAccordion, DSAutoComplete, DSButton, DSIcon, DSInput, DSSelect } from 'components/DS';
import "./multi-range-slider-styles.css";
import { getRelationPercentage } from 'api/dataAnalysis';
import NotificationContext from "context/NotificationsContext"
import { LANG } from 'hooks/manageLanguage';

const cnSection = classnames(
  "w-screen",
  "overflow-auto",
  "p-12",
);
const cnSectionContent = classnames(
  "flex",
  "gap-20",
  "items-start",
  "justify-center",
);
const cnTitle = classnames(
  "text-2xl",
  "font-bold",
  "text-left",
  "mb-10"
);
const cnColumn = classnames("w-full grow-1 relative");

const RECOMMENDATION_TYPES = {
  'titles_word_recommendation': 'Para títulos',
  'descriptions_word_recommendation': 'Para descripciones',
  'titles_descriptions_word_recommendation': 'Para títulos y descripciones',
};;

const filterKeywords = ({
  setOptions,
  keywords,
  filter = '',
}) => {
  const result = keywords
    .filter(({ value }) => {
      if (filter !== '') return value.startsWith(filter);
      return value;
    })
    .reduce((acc, { value }) => {
      if (!acc.includes(value.trim())) acc.push(value.trim());
      return acc;
    }, [])
    .sort((a, b) => a.localeCompare(b))
    .map((value) => ({ label: value, value }));

  setOptions(result);
};

const distributedCopy = ({ items, n }) => {
  const elements = [items[0]];
  const totalItems = items.length - 2;
  const interval = Math.floor(totalItems/(n - 2));
  for (let i = 1; i < n - 1; i += 1) {
    elements.push(items[i * interval]);
  }
  elements.push(items[items.length - 1]);
  return elements;
};

const printDate = ({ date }) => date.split('T')[0].split('-').reverse().join('-');

const roundTwoDecimals = ({ value }) => Math.round(Number(value) * 100) / 100

const ContentAnalysis = () => {
  const [ keywords, setKeywords ] = useState([]);
  const [ options, setOptions ] = useState([]);
  const [ selected, setSelected ] = useState([]);
  const [ dateInit, setDateInit ] = useState(0);
  const [ dateEnd, setDateEnd ] = useState(1);
  const [ textForm, setTextsForm ] = useState({
    text: '',
    type: 'titles',
  });
  const [ analysis, setAnalysis ] = useState([]);
  const [ responses, setResponses ] = useState({});
  const [ loadingAnalysis, setLoadingAnalysis ] = useState(false);

  const formRef = useRef();

  const { setNotification } = useContext(NotificationContext);

  const { isLoading } = useQuery(["content-analysis-keywords"], () => getKeywords, {
    onSuccess: async (data) => {
      const result = await data;
      filterKeywords({ setOptions, keywords: result.data.results });

      setKeywords(result.data.results);
    }
  });

  const handleChange = (e) => {
    if (e.trigger === 'button') {
      const selectedKeyword = keywords
        .filter(({ value }) => value === e.value)
        .sort((b, a) => new Date(b.created_at.value) - new Date(a.created_at.value));

      setSelected(selectedKeyword);
    }
  };
  const handleKeyUp = (e) => filterKeywords({ setOptions, keywords, filter: e.target.value });

  const addToAnalysis = () => {
    const obj = {}
    obj[textForm.text] = [textForm.type];

    setAnalysis([...analysis, [textForm.text, textForm.type]]);
    formRef.current.reset();
    setTextsForm({
      text: '',
      type: 'titles',
    })
  };

  const removeFromAnalysis = ({ index }) => {
    setAnalysis(analysis.filter((_, idx) => idx !== index));
  }

  const relationPercentageQuery = useMutation((values) => getRelationPercentage(values), {
    onSuccess(data) {
      setLoadingAnalysis(false);

      if (data.status === 200) setResponses(data.data.result);
      else
        setNotification({
          icon: 'error',
          text: data.data.message,
          color: 'danger'
        });
    },
  });

  const handleRelationSubmit = () => {
    setLoadingAnalysis(true);

    const [initDate] = selected[dateInit].created_at.value.split('T');
    const [endDate] = selected[dateEnd].created_at.value.split('T');

    const parsedAnalysis = analysis.reduce((acc, [key, value]) => {
      if (!acc[key]) acc[key] = [value];
      else acc[key].push(value)
      return acc;
    }, {});

    const values =  {
      keyword: selected[0].value,
      initKeywordId: selected[dateInit].keyword_id,
      endKeywordId: selected[dateEnd].keyword_id,
      initDate,
      endDate,
      userAnalysis: JSON.stringify(parsedAnalysis),
    };

    relationPercentageQuery.mutate(values)
  };

  return (
    <Layout>
      <section className={cnSection}>
        <h1 className={cnTitle}>{LANG.contentAnalysis.title}</h1>
        <div className={cnSectionContent}>
          <div className={cnColumn}>
            { selected.length === 0 &&
              ( (!isLoading && keywords.length > 0) ?
                  <DSAutoComplete
                    label='Keyword'
                    id='keyword'
                    placeholder={LANG.contentAnalysis.keywordInput}
                    error={false}
                    onChange={handleChange}
                    onClick={undefined}
                    onKeyUp={handleKeyUp}
                    options={options}
                    loading={isLoading}
                  />
                :
                <p>{LANG.contentAnalysis.loading}</p>
              )
            }
            { selected.length > 0 &&
              <>
                <p className="text-left mb-4">{LANG.contentAnalysis.keywordSelected}: <span className="font-bold py-2 px-3 rounded-full bg-gray-100 ml-2">{selected[0].value}</span></p>
                { selected.length > 1 ?
                  <>
                    <p className="mb-2">{LANG.contentAnalysis.dateSelector.title}:</p>
                    <p className='mb-4'>{LANG.contentAnalysis.dateSelector.from} <span className='font-bold'>{printDate({ date: selected[dateInit].created_at?.value })}</span> - {LANG.contentAnalysis.dateSelector.to} <span className='font-bold'>{printDate({ date: selected[dateEnd].created_at?.value })}</span> ({dateEnd - dateInit + 1} {LANG.contentAnalysis.dateSelector.total})</p>
                    <MultiRangeSlider
                      min={0}
                      max={selected.length - 1}
                      minValue={selected.length - 2}
                      maxValue={selected.length - 1}
                      step={1}
                      label={false}
                      ruler={false}
                      className="multi-range-splinter"
                      barLeftColor="#fff"
                      barInnerColor="#e60000"
                      barRightColor="#fff"
                      thumbLeftColor="#c30000"
                      thumbRightColor="#c30000"
                      onInput={(e) => {
                        setDateInit(e.minValue);
                        setDateEnd(e.maxValue);
                      }}
                    />
                    <ul className="flex w-full justify-between">
                      { selected.length > 10 ?
                        distributedCopy({ items: selected, n: 10 }).map(({ keyword_id: keywordId, created_at: createdAt }) =>
                          <li key={keywordId}>
                            <span style={{ writingMode: 'vertical-rl' }} className="block relative text-sm whitespace-nowrap">{printDate({ date: createdAt.value })}</span>
                          </li>
                        )
                        :
                        selected.map(({ keyword_id: keywordId, created_at: createdAt }) =>
                          <li key={keywordId}>
                            <span style={{ writingMode: 'vertical-rl' }} className="block relative text-sm whitespace-nowrap">{printDate({ date: createdAt.value })}</span>
                          </li>
                        )
                      }
                    </ul>
                  </>
                :
                  <p>{LANG.contentAnalysis.dateSelector.uniqueDate} {printDate({ date: selected[dateInit].created_at?.value })}</p>
                }
              </>
            }
            { selected.length > 0 &&
              <>
                <h2 className='mt-8 mb-2'>{LANG.contentAnalysis.textsAnalysis.description}:</h2>
                <form ref={formRef} className="flex gap-4 mb-4">
                  <DSInput
                    label={LANG.contentAnalysis.textsAnalysis.input}
                    id='text'
                    name='text'
                    onChange={(e) => setTextsForm({ ...textForm, text: e.target.value })}
                  />
                  <DSSelect
                    label={LANG.contentAnalysis.textsAnalysis.select.label}
                    id='type'
                    name='type'
                    options={LANG.contentAnalysis.textsAnalysis.select.options}
                    onChange={(e) => setTextsForm({ ...textForm, type: e.target.value })}
                  />
                </form>
                <DSButton
                  type='button'
                  variant='primary'
                  disabled={textForm.text === ''}
                  onClick={addToAnalysis}
                >
                  {LANG.contentAnalysis.textsAnalysis.button}
                </DSButton>
                {
                  analysis.length > 0 &&
                  <div className='mt-8'>
                    <ul className='flex flex-col gap-2 mb-4'>
                      { analysis.map(([key, value], index) =>
                        <li key={`${key}-${value}`} className="flex gap-4">
                          <DSInput readOnly disabled value={key} />
                          <div className='w-40'>
                            <DSInput readOnly disabled value={value === 'titles' ? 'Título' : 'Descripción'} />
                          </div>
                          <button type='button' onClick={() => removeFromAnalysis({ index })}>
                            <DSIcon id='close' className='w-6 h-6 text-gray-400 hover:text-gray-800 transition-colors' />
                          </button>
                        </li>
                      ) }
                    </ul>
                  </div>
                }
                <DSButton
                  type='button'
                  className='mt-4 w-full'
                  onClick={handleRelationSubmit}
                  disabled={loadingAnalysis}
                  >
                    { loadingAnalysis ? <DSIcon id='loading' className='w-8 h-8' /> : LANG.contentAnalysis.mainButton }
                </DSButton>
              </>
            }
          </div>
          <div className={cnColumn}>
            { Object.keys(responses).length > 0 &&
              <div className='flex flex-col gap-8'>
                { Object
                    .entries(responses)
                    .filter(([ key ]) => !Object.keys(RECOMMENDATION_TYPES).includes(key))
                    .length > 0 &&
                  <div>
                    <h2 className='text-xl font-bold mb-2'>{LANG.contentAnalysis.results.textsAnalysis.title}</h2>
                    <p className="text-sm">{LANG.contentAnalysis.results.textsAnalysis.description}</p>
                    <ul>
                      { Object.entries(responses)
                          .filter(([ key ]) => !Object.keys(RECOMMENDATION_TYPES).includes(key))
                          .map(([ key, value ]) =>
                            <li key={key} className="border-b border-gray-300 p-4">
                              <DSAccordion title={key} classes={{
                                container: 'text-xl font-bold',
                                button: {
                                  text: 'text-base font-bold text-left',
                                  icon: 'w-6 h-6 text-primary',
                                },
                                content: 'py-4 text-base',
                              }}>
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                  <thead className='text-md text-gray-900 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                                    <tr>
                                      <th scope="col" className="px-4 py-2 w-1/4">{LANG.contentAnalysis.results.textsAnalysis.analysis}</th>
                                      <th scope="col" className="px-4 py-2">{LANG.contentAnalysis.results.textsAnalysis.value}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="px-4 py-1">{LANG.contentAnalysis.results.textsAnalysis.relevancePercentage}</td>
                                      <td className="px-4 py-1 flex items-center gap-4">{roundTwoDecimals({ value: value.percentage })}%</td>
                                    </tr>
                                    <tr key={key}>
                                      <td className="px-4 py-1 align-top">{LANG.contentAnalysis.results.textsAnalysis.textWeight}</td>
                                      <td className="px-4 py-1 flex items-center gap-4">
                                        <table>
                                          <tbody>
                                            { Object.entries(value.word_weights).map(([word, weight]) =>
                                              <tr key={word}>
                                                <td>{word}:</td>
                                                <td className='pl-2'>{roundTwoDecimals({ value: weight })}</td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <img src={`data:image/png;base64, ${value.graphic}`} alt='Gráfica' className='mt-4' />
                                <div className='flex flex-col gap-2 mt-4'>
                                  {LANG.contentAnalysis.results.textsAnalysis.graphicDescription.map(({ bold, normal }) =>
                                    <p key={bold} className='font-bold text-sm'>
                                      { bold }: { normal && <span className='font-normal'>{normal}</span> }
                                    </p>
                                  )}
                                </div>
                              </DSAccordion>
                            </li>
                          )
                      }
                    </ul>
                  </div>
                }
                <DSAccordion title={LANG.contentAnalysis.results.keywordsRecommendations.title}>
                  <ul className="flex flex-col gap-4">
                    {
                      Object.entries(RECOMMENDATION_TYPES).map(([key, value]) =>
                        <li key={key}>
                          <h3 className='font-bold mb-2'>{value}</h3>
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                              <tr>
                                <th scope="col" className="px-6 py-3 w-1/4">{LANG.contentAnalysis.results.keywordsRecommendations.word}</th>
                                <th scope="col" className="px-6 py-3">{LANG.contentAnalysis.results.keywordsRecommendations.weight}</th>
                              </tr>
                            </thead>
                            <tbody>
                              { responses[key].map(([ keyword, weight ]) =>
                                <tr key={keyword}>
                                  <td className="px-6 py-3">{keyword}</td>
                                  <td className="px-6 py-3 flex items-center gap-4">
                                    {weight}
                                    <span className='w-full h-2 rounded-xl bg-gray-200 relative'>
                                      <span className='absolute left-0 rounded-xl bottom-0 top-0 bg-primary' style={{ width: `${weight * 100}%` }} />
                                    </span>
                                  </td>
                                </tr>
                              ) }
                            </tbody>
                          </table>
                        </li>
                      )
                    }
                  </ul>
                </DSAccordion>
              </div>
            }
            { loadingAnalysis &&
              <div className='flex items-center justify-center bg-white bg-opacity-90 absolute bottom-0 left-0 right-0 top-0'>
                <DSIcon id='loading' className='w-14 h-14 text-primary' />
              </div>
            }
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContentAnalysis;