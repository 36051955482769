import React, { useContext } from "react";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";
import SankeyContext from "routes/Sankey/SankeyContext";
import SankeyColumn from "routes/Sankey/components/SankeyColumn";

const SankeyColumns = ({ className }) => {
  const { state } = useContext(SankeyContext);
  const baseClassName = classnames("flex overflow-x-scroll", className);

  return (
    <div className={baseClassName}>
      {state.columns.length > 0 &&
        state.columns.map((key, index) => (
          <SankeyColumn
            keywordKey={key}
            index={index}
            className="w-60"
            key={key}
          />
        ))}
    </div>
  );
};

SankeyColumns.propTypes = {
  className: PropTypes.string,
};

SankeyColumns.defaultProps = {
  className: "",
};

export default SankeyColumns;
