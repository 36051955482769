const es = {
  language: 'Español',
  locale: 'es-ES',
  menu: {
    contentsAnalysis: 'Análisis de contenidos',
    glossary: 'Listado de búsquedas',
    faqs: 'Preguntas frecuentes',
    user: {
      configure: 'Configuración',
      logout: 'Cerrar sesión',
    },
  },
  configuration: {
    loading: 'Cargando información del usuario...',
    title: 'Configuración usuario',
    name: 'Nombre',
    email: 'Email',
    password: 'Contraseña',
    communications: {
      active: 'Quiero recibir comunicaciones por email',
      inactive: 'No deseo recibir comunicaciones por email',
    },
    cta: 'Actualizar configuración',
  },
  components: {
    datepicker: {
      locale: 'es',
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
  },
  login: {
    title: 'Vodafone Splinter',
    login: {
      title: 'Acceder',
      form: {
        email: 'Email',
        password: 'Contraseña',
        cta: 'Acceder',
        passwordRecovery: 'Olvidé mi contraseña',
        errors: {
          email: {
            invalid: 'El email es inválido',
            required: 'El email es obligatorio',
          },
          password: 'Introduce tu contraseña',
        },
      },
    },
    recovery: {
      form: {
        email: 'Introduce tu email',
        cta: 'Recuperar contraseña',
        back: 'Volver',
        errors: {
          email: {
            invalid: 'El email es inválido',
            required: 'El email es obligatorio',
          },
        },
        results: {
          ok: 'Te hemos enviado un email con las instrucciones para resetear tu contraseña',
        },
      },
    },
    register: {
      title: 'Registrarse',
      form: {
        name: 'Nombre',
        email: 'Email',
        password: 'Contraseña',
        passwordConfirm: 'Confirmar contraseña',
        cta: 'Registrarse',
        errors: {
          name: 'El nombre es obligatorio',
          email: {
            invalid: 'El email es inválido',
            required: 'El email es obligatorio',
            corporative: 'El email debe pertenecer a Vodafone',
          },
          password: {
            required: 'Debes introducir una contraseña',
            pattern: 'La contraseña ha de tener al menos una letra, un número y 8 caracteres',
          },
          passwordConfirm: {
            required: 'Debes confirmar tu contraseña',
            different: 'Las contraseñas no coinciden',
          },
        },
        results: {
          ok: 'Por favor, activa tu cuenta desde el email que te hemos enviado',
        },
      },
    },
  },
  activate: {
    title: 'Vodafone Splinter',
    card: {
      title: '¡Tu cuenta ha sido activada correctamente!',
      description: 'Por favor, accede con tus datos a Vodafone Splinter',
      cta: 'Acceder a Splinter',
    },
    loading: 'Estamos activando tu cuenta...',
    error: 'La cuenta no existe o ya está activada',
  },
  resetPassword: {
    title: 'Vodafone Splinter',
    form: {
      password: 'Introduce una nueva contraseña',
      passwordConfirm: 'Confirma tu nueva contraseña',
      cta: 'Actualizar contraseña',
      errors: {
        password: {
          required: 'Debes introducir una contraseña',
          pattern: 'La contraseña ha de tener al menos una letra, un número y 8 caracteres',
        },
        passwordConfirm: {
          required: 'Debes confirmar tu contraseña',
          different: 'Las contraseñas no coinciden',
        },
      },
      results: {
        ok: 'Tu contraseña ha sido actualizada correctamente. Prueba a acceder con ella',
      },
    },
  },
  formKeywords: {
    title: 'Aquí podrás buscar los términos de los que tenemos información. Introduce tu búsqueda y te mostraremos la información que tenemos guardada:',
    form: {
      keyword: {
        title: '¿Qué estás buscando?',
        placeholder: 'Ejemplo: vodafone',
      },
      date: {
        title: 'Selecciona una fecha de las disponibles',
        tooltip: {
          title: 'Selector de fechas',
          description: 'Algunas de las búsquedas se renuevan automáticamente. En este calendario podrás ver si existen resultados de diferentes fechas. Si quieres que alguna búsqueda tenga este refresco automático, puedes hacerlo desde el “Listado de búsquedas“, marcando como favoritos aquellos términos que desees.',
        },
        legend: 'Los días marcados con doble círculo tienen también información de las preguntas relacionadas de Google',
      },
      graph: {
        title: 'Tipo de visualización',
        network: {
          title: 'Red neuronal',
          description: 'Te aportará información de qué términos relacionados con tu búsqueda tienen mayor parecido y relevancia de cara a los usuarios',
        },
        circlePacking: {
          title: 'Gráfico mitocondrial',
          description: 'Te servirá para saber qué términos relacionados con tu búsqueda tienen mayor volumen de contenidos similares a tu búsqueda',
        },
        sankey: {
          title: 'Diagrama de Sankey',
          description: 'Podrás ver las todas búsquedas relacionadas que surgen de tu búsqueda y cuáles son las que más veces se repiten',
        }
      },
      cta: 'Ver resultados',
      alsoasked: {
        title: 'Tenemos información de las Preguntas relacionadas de Google para esta búsqueda, lo cual te servirá para ver las preguntas que se plantean los usuarios relacionadas con el término. Puedes ver esta información desde este botón:',
        cta: 'Ver información de las Preguntas relacionadas',
      },
      errors: {
        keyword: 'Debes eligir un término',
        date: 'Debes seleccionar una fecha',
        chartType: 'Debes elegir un tipo de visualización',
      },
    },
  },
  generateKeyword: {
    maintenance: 'Splinter está en mantenimiento',
    title: '¿Quieres pedir nueva información de una búsqueda para obtener resultados nuevos o más actualizados? Puedes hacerlo desde este botón:',
    form: {
      title: 'Rellena este formulario para que podamos empezar a recoger la información que necesitas',
      keyword: {
        title: 'Introduce el término del que quieres obtener información y pulsa el botón +. Podrás introducir más una vez lo añadas:',
        placeholder: 'Ejemplo: vodafone',
      },
      keywordsSelection: {
        title: 'Aquí podrás seleccionar todas aquellas de las que finalmente querrás obtener información. También podrás marcarlas como favoritas o solicitar que obtengamos información de las Preguntas relacionadas de Google:',
        tooltip: {
          title: 'Seleccionar términos para buscar información',
          description: {
            title: 'Puedes realizar las siguientes acciones:',
            list: {
              select: 'Seleccionar los términos de los que quieres obtener información',
              favourite: 'Guardar la búsqueda como favorita para que se actualice automáticamente cuando sea posible',
              alsoasked: 'Solicitar también la información de las Preguntas relacionadas de Google',
              suggestions: {
                google: 'Ver sugerencias de Google para este término',
                amazon: 'Ver sugerencias de Amazon para este término',
                youtube: 'Ver sugerencias de Youtube para este término',
              },
            },
          }
        },
        suggestions: {
          from: 'Sugerencias de',
          for: 'para',
          accordion: {
            open: 'Mostrar',
            close: 'Ocultar',
          },
        },
      },
      priority: {
        title: 'Prioridad',
        tooltip: {
          title: 'Prioridad de la búsqueda:',
          description: 'Según la prioridad que selecciones, tardarán más o menos en ejecutarse en función de las otras búsquedas que haya en cola en la herramienta',
        },
        values: [{
          value: 1,
          label: 'Baja'
        }, {
          value: 2,
          label: 'Media',
         }, {
          value: 3,
          label: 'Alta'
        }],
      },
      resume: 'Aquí tienes un listado de los términos de los que vas a solicitar información:',
      cta: 'Lanzar búsqueda',
      errors: {
        priority: 'Debes seleccionar una prioridad',
      },
    },
  },
  users: {
    loading: 'Cargando datos del usuario...',
    title: 'Desde aquí podrás gestionar los usuarios que hay en Splinter',
    create: {
      title: 'Crear un nuevo usuario',
      description: 'Recuerda, solo puedes añadir usuarios con cuenta corporativa de Vodafone: @vodafone.com, @corp.vodafone.es',
      password: 'Contraseña',
      cta: 'Crear usuario',
      responses: {
        ok: 'Usuario registrado correctamente',
      },
    },
    edit: {
      title: 'Editar usuario',
      description: 'Recuerda, el email solo puede tener asociada su cuenta corporativa de Vodafone: @vodafone, @corp.vodafone.es',
      password: 'Nueva contraseña',
      cta: 'Actualizar usuario',
      responses: {
        ok: 'Usuario actualizado correctamente',
      },
    },
    form: {
      name: 'Nombre',
      email: 'Email',
      role: {
        title: 'Rol',
        values: [{
          value: 'admin',
          label: 'Administrador',
        }, {
          value: 'user',
          label: 'Usuario',
        }],
      },
      password: {
        copy: 'Contraseña copiada al portapapeles',
      },
      errors: {
        name: 'El nombre es obligatorio',
        email: {
          invalid: 'El email es inválido',
          required: 'El email es obligatorio',
          corporative: 'El email debe pertenecer a Vodafone',
        },
        role: 'Debes seleccionar un rol',
        password: 'La contraseña es obligatoria',
      },
    },
  },
  usersList: {
    title: 'Listado de usuarios de Splinter',
    loading: 'Cargando usuarios...',
    form: {
      searchBy: {
        title:'Buscar por',
        values: [{
          label: 'Nombre',
          value: 'name',
        }, {
          label: 'Email',
          value: 'email',
        }],
      },
      text: 'Texto búsqueda',
      cta: 'Buscar',
      remove: 'Limpar campos',
      errors: {
        searchBy: 'Campo obligatorio',
        searchValue: 'Campo obligatorio',
      },
    },
    list: {
      status: 'Estado',
      name: 'Nombre',
      email: 'Email',
      actions: 'Acciones',
      icons: {
        inactive: 'Inactivo',
        expired: 'Expirado',
        active: 'Activo',
      },
      buttons: {
        edit: 'Editar',
        delete: 'Eliminar',
      },
      results: {
        delete: {
          ok: 'Usuario eliminado correctamente',
          ko: 'Se ha producido un error',
        },
      },
    },
  },
  glossary: {
    loading: 'Cargando listado de términos...',
    form: {
      sort: {
        title: 'Ordenar',
        values: [{
          value: 'value:asc',
          label: 'Alfabético ↓'
        }, {
          value: 'value:desc',
          label: 'Alfabético ↑'
        }, {
          value: 'created_at:desc',
          label: 'Actualizadas más recientemente'
        }, {
          value: 'created_at:asc',
          label: 'Actualizadas menos recientemente'
        }],
      },
      keyword: 'Término de búsqueda',
      favourites: 'Mis favoritas',
      noResults: 'Sin resultados para tu búsqueda',
    },
  },
  graphs: {
    filters: {
      loading: 'Cargando datos...',
      xls: {
        tooltip: {
          description: 'Se generará un reporte en formato .xls con la información de la keyword',
        },
      },
      viewsSelector: {
        values: [{
          value: 'network',
          label: 'Árbol de nodos'
        },{
          value: 'circle-packing',
          label: 'Grafo circular'
        },{
          value: 'sankey',
          label: 'Sankey'
        }],
      },
    },
    columns: {
      parents: 'Padres',
      selected: 'Seleccionado',
      children: {
        title: 'Hijos',
        weight: 'Peso',
      },
      categories: {
        title: 'Categorías',
        values: [{
          stored: 'Noticias',
          label: 'Noticias',
          icon: 'googleNews',
        }, {
          stored: 'Maps',
          label: 'Maps',
          icon: 'googleMaps',
        }, {
          stored: 'Imágenes',
          label: 'Imágenes',
          icon: 'googleImages',
        }, {
          stored: 'Shopping',
          label: 'Shopping',
          icon: 'googleShopping',
        }, {
          stored: 'Vídeos',
          label: 'Vídeos',
          icon: 'googleVideos',
        }, {
          stored: 'Libros',
          label: 'Libros',
          icon: 'googleLibros',
        }, {
          stored: 'Vuelos',
          label: 'Vuelos',
          icon: 'googleVuelos',
        }, {
          stored: 'Finance',
          label: 'Finance',
          icon: 'googleFinance',
        }],
      },
      pills: {
        title: 'Pills',
      },
      suggestions: {
        title: 'Sugerencias',
        empty: 'No hay sugerencias',
        values: [{
          value: 'Also-Asked',
          label: '¿Qué pregunta la gente?',
          icon: 'peopleAlsoAsk',
        }, {
          value: 'google-suggest',
          label: 'La gente también busca',
          icon: 'info',
        }, {
          value: 'google_suggest',
          label: 'La gente también busca',
          icon: 'info',
        }, {
          value: 'amazon',
          label: 'Amazon recomienda',
          icon: 'shopping-cart',
        }, {
          value: 'youtube',
          label: 'Youtube recomienda',
          icon: 'school',
        }]
      },
    },
    mostRepeatedRelations: 'Ver información de las relaciones más repetidas'
  },
  network: {
    loading: 'Cargando datos...',
    noData: 'No hay información disponible',
    topTen: {
      title: 'Top 10',
      list: {
        keyword: 'Término',
        value: 'Veces que ha aparecido',
      },
    },
    legend: {
      title: '* ¿Qué es el peso?',
      description: 'En este gráfico el peso sirve para comparar la importancia de un término respecto de sus hermanos. Cuanto mayor peso, mayor será la asociación que hacen los usuarios al término principal. Te ayudará a entender qué términos tienen mayor similitud en el pensamiento de los usuarios.',
    },
  },
  circlePacking: {
    loading: 'Cargando datos...',
    noData: 'No hay información disponible',
    legend: {
      title: '* ¿Qué es el peso?',
      description: 'En este gráfico el peso sirve para comparar el volumen de términos relacionados. Cuanto mayor peso, mayor será la cantidad de búsquedas relacionadas con el término principal. Te ayudará a descubrir qué términos tienen mayor cantidad de búsquedas asociadas por parte de los usuarios.',
    },
  },
  sankey: {
    loading: 'Cargando datos...',
    noData: 'No hay información disponible',
    legend: {
      title: '* ¿Qué indican las barras?',
      description: 'La barra indica la importancia de cada término. Cuantas más veces se repita el mismo en los resultados, más rellena estará la barra. Una barra llena, indica que es el término más repetido (puede haber varias diferentes si coincide el número de repeticiones). Una barra vacía indica que sólo ha aparecido una vez. Te ayudará a entender cuáles son los términos asociados a tu búsqueda más relevantes.',
    },
  },
  peopleAlsoAsk: {
    loading: 'Cargando datos...',
    noData: 'No hay información disponible',
    legend: {
      title: '* ¿Qué indican las barras?',
      description: 'La barra indica la importancia de cada término. Cuantas más veces se repita el mismo en los resultados, más rellena estará la barra. Una barra llena, indica que es el término más repetido (puede haber varias diferentes si coincide el número de repeticiones). Una barra vacía indica que sólo ha aparecido una vez. Te ayudará a entender cuáles son los términos asociados a tu búsqueda más relevantes.',
    },
  },
  faqs: {
    title: 'Preguntas frecuentes sobre Splinter',
    elements: [{
      title: 'Solicitar nueva información de un término',
      steps: [
        'Dentro de la Página Principal de Splinter, haz clic en el siguiente botón',
        'Escribimos el término de la que queremos obtener información y pulsaremos sobre el botón con el icono +',
        'Además, podemos solicitar sugerencias de Google, Amazon y Youtube a dicho término. Basta con hacer click en el botón correspondiente, y nos devolverá un listado de las mismas. Para pedir recoger también la información de dichas sugerencias, basta con seleccionarlas (quedarán marcadas en azul):',
        'En este punto, también podemos marcarlas como favoritas. Esto hará que los términos se actualice periódicamente a medida que vayan quedando huecos en la herramienta; solicitar también la información de las Preguntas relacionadas de Google a cada término.',
        'Antes de confirmar el envío de todos los términos elegidos, tendremos un resumen sobre el botón para poder comprobar de forma sencilla aquellos que vamos a solicitar.',
        'Tu petición pasará a una cola de procesamiento y dependiendo de la carga de los sistemas podrá ser resuelta en mayor o menor tiempo. En condiciones normales, la herramienta tendrá resultados en aproximadamente 4 - 6 horas, pero te recomendamos que planifiques las altas con cierto margen de tiempo. Recibirás un email cuando estén disponibles en la herramienta para que accedas a la información recopilada.',
      ],
    }, {
      title: 'Consultar información de un término',
      steps: [
        'Dentro de la Página Principal de Splinter, vamos al formulario con de búsqueda de términos, y escribimos en el campo ¿Qué estás buscando? aquel que deseamos buscar (se trata de un campo predictivo, nos mostrará todas aquellos términos que contengan el texto que hemos introducido. Por ejemplo si introducimos met nos devolverá, meta, metaverso, metaverso vodafone,...)',
        'A continuación debemos seleccionar la fecha de cuándo queremos obtener la información. El calendario sólo permite seleccionar fechas en las que se haya información disponible.',
        'Por último, debemos seleccionar el tipo de vista que queremos visualizar',
        'En aquellos casos que, para la búsqueda y fecha seleccionados, se haya solicitado la información del las Preguntas Relacionadas de Google, aparecerá un botón que nos permitirá acceder al gráfico con la información correspondiente.',
      ],
    }, {
      title: 'Tipos de vistas',
      description: 'Existen tres tipos de vistas para las keywords crawleadas:',
      steps: [
        'Red neuronal: Podrás entender qué otras búsquedas relacionadas de Google tienen mayor similitud con el término que has buscado de cara a los usuarios. Puedes utilizarlo para encontrar nuevos términos con relevancia en búsquedas relacionadas con el término principal.',
        'Gráfico mitocondrial: Esta vista te ayudará a descubrir cuáles son los términos con mayor volumen de búsquedas relacionadas con el término principal. Te servirá para entender qué otros términos aportan mayor cantidad de información relacionada a la búsqueda principal.',
        'Diagrama de Sankey: Esta vista nos muestra, mediante interacción de columnas, los resultados que aporta Google de un modo simple y efectivo. Además, te aportará la información sobre qué términos son aquellos que más veces se repiten dentro de las búsquedas relacionadas con el término principal.',
      ],
    }, {
      title: 'Herramientas de las vistas',
      description: 'En el menú superior de las vistas, tendremos siempre estos elementos:',
      steps: [
        'Se trata de un selector de fechas, en el que podremos, pinchando y arrastrando, mover la bolita roja y ver las fechas con información disponible del término del cual estamos viendo información.',
        'Este selector nos permite cambiar la vista de gráfico sin tener que pasar por el proceso de búsqueda en e la Página Principal de Splinter.',
      ],
    }, {
      title: 'Listado de búsquedas',
      elements: [
        'En el Listado de búsquedas, podemos ver todos los términos de las que se tienen información guardada en algún momento en Splinter.',
        'En la parte superior, hay un sencillo buscador para poder filtrar u organizar los resultados.',
        'Cada término mostrará la fecha en la que fue actualizado por última vez',
        'Dentro de cada término, podremos marcarlo o desmarcarlo como favorito mediante el botón de la estrella (si lo marcas cuando solicitas nueva información de un término, aquí aparecerá como favorito también). Cuando alguno de los usuarios ha seleccionado dicho término como favorito, esta se actualizará automáticamente a medida que vayan quedando huecos libres en la cola de trabajo de la herramienta. Si en algún momento necesitas que se actualice sin esperar a que lo haga automáticamente, puedes volver a solicitar su información siguiendo el proceso normalmente y poniendo la prioridad según tu urgencia.',
        'Cuando la cajita de un término aparece con el borde remarcado en rojo, significa que algún usuario lo tiene marcado como favorito, por lo que se actualizará por si mismo siguiendo el método anteriormente explicado.',
      ],
    }, {
      title: 'Modificar tus datos',
      description: 'En la parte superior derecha de la página, podrás ver tu nombre de usuario. Si mueves el ratón sobre el mismo, podrás acceder al menú “Configuración“, donde podrás modificar tu nombre, contraseña y elegir si deseas o no recibir las comunicaciones de Splinter por email. El email no es posible modificarlo.',
    }],
  },
  contentAnalysis: {
    title: 'Análisis y creación de contenidos',
    loading: 'Cargando datos...',
    keywordInput: 'Introduce una keyword',
    keywordSelected: 'Keyword seleccionada',
    dateSelector: {
      title: 'Elige un rango de fechas sobre el que realizar el análisis',
      from: 'Desde',
      to: 'Hasta',
      total: 'días de registros',
      uniqueDate: 'Sólo podemos hacer el análisis de esta keyword con la información del',
    },
    textsAnalysis: {
      description: 'Si lo deseas, puedes añadir todos los títulos y/o descripciones de los que quieras comprobar la relación con la keyword selecionada. Si solo deseas obtener información de la keyword, no es necesario que añadas ninguno',
      input: 'Texto',
      select: {
        label: 'Tipo',
        options: [{
          value: 'titles',
          label: 'Título',
        }, {
          value: 'snippets',
          label: 'Descripción',
        }],
      },
      button: 'Añadir',
    },
    mainButton: 'Realizar análisis',
    results: {
      textsAnalysis: {
        title: 'Análisis textos',
        description: 'Este análisis te aporta información sobre los textos que has probado para comprar con la información almacenada para la keyword y rango de fechas seleccionados. Te dará un porcentaje para que veas cuánta relación tienen respecto, y te diferenciará cuáles son aquellas palabras más o menos relevantes al respecto.',
        analysis: 'Análisis',
        value: 'Valor',
        relevancePercentage: 'Porcentage relevancia',
        textWeight: 'Pesos por palabra',
        graphicDescription: [{
          bold: '*¿Cómo analizar el gráfico superior?',
          normal: '',
        }, {
          bold: 'Comparación con el Corpus',
          normal: 'Comparar los picos en el gráfico del contenido del usuario con el gráfico del corpus. Si los picos en el gráfico del usuario son mucho más altos que los del corpus, significa que esas palabras son particularmente relevantes en el contexto de ese contenido específico.',
        }, {
          bold: 'Diversidad de Palabras Clave',
          normal: 'Observar si hay varios picos en diferentes puntos del gráfico. Esto podría indicar que hay varias palabras clave o términos relevantes.',
        }, {
          bold: 'Contexto del Contenido',
          normal: 'Examinar el contexto en el que aparecen las palabras clave en los picos. ¿Se relacionan con el contenido? ¿Tienen sentido en el contexto?',
        }, {
          bold: 'Efecto en el SEO',
          normal: 'Las palabras clave con picos altos en el gráfico podrían ser buenos candidatos para optimizar el contenido. Se pueden ajustar los títulos, snippets...',
        }],
      },
      keywordsRecommendations: {
        title: 'Palabras más relevantes',
        word: 'Palabra',
        weight: 'Peso',
      }
    }
  },
};

module.exports = { es };