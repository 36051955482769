import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";

import DSIcon from "../DSIcon/DSIcon";

const cnBase = classnames('relative', 'w-auto', 'inline-block', 'ml-1', 'align-top');
const cnIcon = classnames('w-5', 'h-5', 'text-primary');
const cnContentBase = classnames(
  'bg-white',
  'absolute',
  'left-full',
  'bottom-1/2',
  'transform',
  'translate-y-1/2',
  'transition-all',
  'duration-500',
  'w-auto',
  'text-left',
  'pointer-events-none',
  'p-4',
  'border',
  'border-gray-300',
  'min-w-tooltip',
  'z-10',
);
const cnArrow = classnames(
  'w-4',
  'h-4',
  'bg-white',
  'transform',
  'rotate-45',
  'translate-y-1/2',
  'translate-x-1/2',
  'right-full',
  'bottom-1/2',
  'absolute',
  'border',
  'border-gray-300',
  'border-r-0',
  'border-t-0'
);
const cnTitle = classnames('text-m', 'font-bold', 'mb-2');
const cnDescription = classnames('text-sm');

const DSTooltip = ({ icon, title, description, className }) => {
  const [ status, setStatus ] = useState(false);

  const showTooltip = () => setStatus(true);
  const hideTooltip = () => setStatus(false);

  const cnTooltip = classnames(cnBase, className);
  const cnContent = classnames(cnContentBase,
    { 'opacity-0': !status },
    { 'opacity-100': status },
    { 'translate-x-4': status },
    { 'translate-x-6': !status },
  );

  return (
    <div className={cnTooltip}>
      <button type='button' className={cnBase} onMouseOver={showTooltip} onFocus={showTooltip} onMouseOut={hideTooltip} onBlur={hideTooltip}>
        <DSIcon id={icon} className={cnIcon} />
      </button>
      <span className={cnContent}>
        <span className={cnArrow} />
        { title && <h2 className={cnTitle}>{title}</h2> }
        { description && <p className={cnDescription}>{description}</p> }
      </span>
    </div>
  );
}

DSTooltip.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

DSTooltip.defaultProps = {
  title: undefined,
  description: undefined,
  className: '',
};

export default DSTooltip;
