/* eslint no-param-reassign: "off" */
/* eslint no-use-before-define: "off" */
/* eslint react/destructuring-assignment: "off" */
import * as d3 from 'd3';
import { Library } from '@observablehq/stdlib/';

import './NetworkGraph.css';

let links;
let zoom;

const rewriteId = (id) => `id-${id.replace(/"/g, '').replace(/[^\w ]+/g, '').replace(/ /g, '-')}`;

const getNodeColor = (node) => node.level === 0 ? '#E60000' : '#ffffff'

const getMarker = (link, data) => {
	let url;
	data.nodes.forEach(node => {
		if (link.target === node.id) {
			switch (node.r) {
				case 10:
				default:
					url = "url(#0-arrowhead-1)";
					break;
				case 15:
					url = "url(#0-arrowhead-2)";
					break;
				case 20:
					url = "url(#0-arrowhead-3)";
					break;
				case 30:
					url = "url(#0-arrowhead-4)";
					break;
				case 40:
					url = "url(#0-arrowhead-5)";
					break;
				case 60:
					url = "url(#0-arrowhead-6)";
					break;
			}
		}
	});
	return url;
}

function getLink(source) { return links.find(link => source === link.target); }

function getParents(parents, link) {
	if(link.parentDepth > 0) {
		getParents(parents, getLink(link.source));
	}
	parents.push(link.source.id);
	return parents;
}

function getInfoNodes(node, data) {
	return data.links.reduce((nodes, link) => {
		if (link.target.id === node.id) {
			nodes.neighbors.push(link.source.id);
			getParents(nodes.parents, link);

		} else if (link.source.id === node.id) {
			nodes.neighbors.push(link.target.id);
			nodes.children.push({
				"name": link.target.id,
				"rels": link.target.rels,
				"percentage": 0
			});
		}
		return nodes;
	},
		{
			neighbors: [node.id],
			parents: [],
			children: []
		}
	);
}

function isParentLink(node, link) {
	return link.target.id === node.id;
}

function isChildrenLink(node, link) {
	return link.source.id === node.id;
}

function isNeighbourNode(node, nodes) {
	return Array.isArray(nodes) && nodes.indexOf(node.id) > -1;
}

function getSelectedNodeColors(node, parents, children, selectedNode) {
	if (node === selectedNode) { return '#BD0000'; }
	if (isNeighbourNode(node, parents) && isNeighbourNode(node, children)) { return '#9C2AA0'; }
	if (isNeighbourNode(node, parents)) { return '#00B0CA'; }
	if (isNeighbourNode(node, children)) { return '#E60000'; }
	return node.level === 0 ? '#E60000' : '#ffffff';
}

function getSelectedLinkColors(node, link) {
	if (isParentLink(node, link)) { return '#00B0CA'; }
	if (isChildrenLink(node, link)) { return '#FFFFFF'; }
	return 'rgb(50, 50, 50)';
}

function getTextDisplay(node, neighbors) {
    return Array.isArray(neighbors) && neighbors.indexOf(node.id) > -1 ? 'block' : 'none';
}

function calculateWeight(data, node, children) {
	const childrenNames = children.map((c) => c.name);
	const nodeChilds = data.links.filter((link) => link.source.id === node.name);
	const levelChilds = data.links.filter((link) => childrenNames.indexOf(link.source.id) !== -1);
	if (nodeChilds.length === 0) return undefined;
	return (nodeChilds.length / levelChilds.length * 100).toFixed(2);
}

function selectNode (selectedNode, data, update, ...elements) {
	const [nodeElements, textElements, linkElements] = elements;
	const nodesInfo = getInfoNodes(selectedNode, data);
	const { neighbors, parents, children } = nodesInfo;

	const childs = children.sort((a, b) => a.rels < b.rels && 1 || -1).map(child => (
		{
			...child,
			rels: calculateWeight(data, child, children),
		}
	));

	update({
		selected: selectedNode,
		parents,
		children: childs,
	});

	nodeElements.attr('fill', node => getSelectedNodeColors(node, parents, children, selectedNode));
	nodeElements.attr('stroke', node => node === selectedNode ? '#ffffff' : 'transparent' );
	textElements.style('display', node => getTextDisplay(node, neighbors));
	linkElements.attr('stroke', link => getSelectedLinkColors(selectedNode, link));
}

function releaseNode(d) {
	d.fx = null;
	d.fy = null;
}

function centerNode(svg, xx, yy) {
	svg.transition().duration(800).call(
		zoom.transform,
		d3.zoomIdentity.scale(3).translate(-xx, -yy),
	);
}

const NetworkGraph = (element, data, updateKeywordInfo) => {
	const library = new Library();
	const height = element.scrollHeight;
	const width = element.scrollWidth;

	const { links: dataLinks, nodes: dataNodes } = data;

	const svg = d3.select(element)
		.attr("viewBox", `-${width} -${height} ${width * 2} ${height * 2}`)
		.attr("width", width)
		.attr("height", height);

	svg.selectAll("g").remove();
	svg.selectAll("defs").remove();

	if (dataLinks === undefined || dataNodes === undefined) {
		svg.append('text')
			.text('NO DATA AVAILABLE')
			.attr("dominant-baseline", "middle")
			.attr("text-anchor", "middle")
			.style("font-size", "44px")
			.style("fill", "#ffffff");
		return svg.node();
	}

	const container = svg.append('g').attr('id', library.DOM.uid(`container`).id);
	const defs = svg.append('defs');

	zoom = d3.zoom();

	svg.call(
		zoom
		.scaleExtent([.1, 6])
		.on("zoom", (e) => {
			container.attr("transform", e.transform);
		})
	);

	d3.select("svg").on("dblclick.zoom", null);

	for (let i = 0; i < 6; i += 1) {
		defs.append('marker')
			.attr('id', library.DOM.uid(`arrowhead`).id)
			.attr('viewBox', '-0 -5 10 10')
			.attr('refX', i + 1)
			.attr('refY', 0)
			.attr('orient', 'auto')
			.attr('markerWidth', 5)
			.attr('markerHeight', 5)
			.attr('xoverflow', 'visible')
			.attr('class', 'arrow-head')
			.append('path')
			.attr('d', 'M 0,-5 L 10 ,0 L 0,5')
			.attr('fill', 'rgb(50, 50, 50)')
			.style('stroke', 'none')
	}

	links = dataLinks;

	const linkForce = d3.forceLink().id(link => link.id).strength(link => link.strength);
	const simulation = d3.forceSimulation().velocityDecay(0.1).force('link', linkForce).force('charge', d3.forceManyBody().strength(-120)).force('collide', d3.forceCollide()).force('center', d3.forceCenter(0, 0));
	const linkElements = container.append("g").attr("class", "spl-links").selectAll("line").data(data.links).enter().append("line").attr("stroke-width", 1).attr("stroke", "rgb(50, 50, 50)").attr('marker-end', (link) => getMarker(link, data));
	const nodeElements = container.append("g").attr("class", "spl-nodes").selectAll("circle").data(data.nodes).enter().append("circle").attr("r", node => node.r).attr("fill", getNodeColor).attr("id", (node) => rewriteId(node.id))
		.call(
			d3.drag()
			.on('start', node => {
				node.fx = node.x;
				node.fy = node.y;
			}).on('drag', (e, node) => {
				simulation.alphaTarget(0.7).restart();
				node.fx = e.x;
				node.fy = e.y;
			}).on('end', (e, node) => {
				if (!e.active) {
					simulation.alphaTarget(0);
				}
				node.fx = node.x;
				node.fy = node.y;
			})
		)
		.on('click', (e, selectedNode) => {
			centerNode(svg, e.target.getAttribute('cx'), e.target.getAttribute('cy'));
			selectNode(selectedNode, data, updateKeywordInfo, nodeElements, textElements, linkElements)
		})
		.on('initClick', (e, selectedNode) => {
			selectNode(selectedNode, data, updateKeywordInfo, nodeElements, textElements, linkElements)
		})
		.on('dblclick', releaseNode);

	const textElements = container.append("g").attr("class", "spl-texts").selectAll("text").data(data.nodes).enter().append("text").text(node => `\xa0${node.id}`).attr("font-size", 15).attr("text-anchor", "middle").attr("dy", node => -node.r - 10);

	nodeElements.append("title").text(node => `${node.id}\xa0\xa0[ ${node.rels} ]`);

	simulation.nodes(data.nodes).on('tick', () => {
		nodeElements.attr('cx', node => node.x).attr('cy', node => node.y);
		textElements.attr('x', node => node.x).attr('y', node => node.y);
		linkElements.attr('x1', link => link.source.x).attr('y1', link => link.source.y).attr('x2', link => link.target.x).attr('y2', link => link.target.y);
	});

	simulation.force("link").links(data.links);
	simulation.force("collide").radius(d => d.r + 1);
	element.querySelector(`#${rewriteId(data.nodes[0].id)}`).dispatchEvent(new Event('initClick'));

	return svg.node();
}

export default NetworkGraph;
