import React from "react";
import PropTypes from "prop-types";
import { classnames } from 'tailwindcss-classnames';


const DSFormCheckbox = ({ label, id, name, onChange, classes, disabled }) => {
  const cnLabel = classnames('inline-flex', 'items-center', 'space-x-3', classes);
  const cnBaseInput = classnames('rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-6 h-6')
  const cnInput = classnames(cnBaseInput, {
    "bg-white": !disabled,
    "bg-gray-200": disabled,
  });

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={cnLabel}>
      <input
        id={id}
        type="checkbox"
        className={cnInput}
        name={name}
        onChange={onChange}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
  };

DSFormCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
};

DSFormCheckbox.defaultProps = {
  id: null,
  onChange: null,
  classes: null,
  disabled: false,
};

export default DSFormCheckbox;
