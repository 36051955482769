import React from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";
import { DSAccordion } from 'components/DS';
import { LANG } from 'hooks/manageLanguage';

const cnBase = classnames(
	"border-l",
	"bg-white",
	"border-gray-300",
	"overflow-y-scroll",
	"p-6",
	"fixed",
	"bottom-0",
	"top-24",
	"overflow-auto",
	"border-t",
	"max-w-xs",
	"w-80",
);
const cnLeftColumn = classnames(cnBase, "left-0");
const cnRightColumn = classnames(cnBase, "right-0");
const cnTitleBase = classnames("border-l-4", "border-primary", "pl-4");
const cnTitleMargin = classnames(cnTitleBase, "mt-4");
const cnValueBase = classnames("flex", "justify-between", "pl-3", "mt-1", "border-l-4", "border-gray-300", "ml-5");
const cnButton = classnames("flex", "w-full", "justify-between", "hover:text-primary");

const rewriteId = (kw) =>
	`id-${kw.replace(/"/g, '').replace(/[^\w ]+/g, '').replace(/ /g, '-')}`;

const bindClick = (kw) => {
	const element = document.querySelector(`#${rewriteId(kw)}`);
	if (element !== null && element?.getAttribute('pointer-events') !== 'none') {
		element.dispatchEvent(new Event('click'));
	}
}

const KeywordSelected = ({ keyword }) =>
	<>
		<div className={cnTitleMargin}><strong>{LANG.graphs.columns.selected}:</strong></div>
		<div className={cnValueBase}>{keyword}</div>
	</>

KeywordSelected.propTypes = {
	keyword: PropTypes.string,
}
KeywordSelected.defaultProps = {
	keyword: undefined,
}

const KeywordParents = ({ parents }) =>
	<>
		<div className={cnTitleBase}><strong>{LANG.graphs.columns.parents} [{parents.length}]:</strong></div>
		<ul>
			{
				parents.map((parent) =>
					<li key={parent} className={cnValueBase}>
						<button type='button' className={cnButton} onClick={() => bindClick(parent)}>{parent}</button>
					</li>
				)
			}
		</ul>
	</>

KeywordParents.propTypes = {
	parents: PropTypes.arrayOf(
		PropTypes.string
	),
}
KeywordParents.defaultProps = {
	parents: null,
}

const KeywordChildren = ({ childs, maxDepth }) => {
	const cnChildrenValue = classnames(cnValueBase, "pl-5");
	const cnLeftText = classnames("text-left");

	return (
		<>
			<div className={cnTitleMargin}>
				<span><strong>{LANG.graphs.columns.children.title} [{childs.length}]</strong></span>
				{ childs.filter((child) => child.rels).length > 0 && <span><strong>{LANG.graphs.columns.children.weight}*</strong></span> }
			</div>
			<ul>
				{
					childs.map((child) =>
						<li key={child.id} className={cnChildrenValue}>
							{
								(child.depth < (maxDepth - 1)) ?
									<button type='button' className={cnButton} onClick={() => bindClick(child.name)}>
										<span className={cnLeftText}>{child.name}</span>
										{ child.perc !== 'NaN' && <span>{ child.perc }%</span> }
									</button>
								:
								<>
									<span className={cnLeftText}>{child.name}</span>
									{ child.perc !== 'NaN' && <span>{ child.perc }%</span> }
								</>
							}
						</li>
					)
				}
			</ul>
			<p className='font-bold mt-8'>{LANG.circlePacking.legend.title}</p>
			<p>{LANG.circlePacking.legend.description}</p>
		</>
	)
}

KeywordChildren.propTypes = {
	childs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			depth: PropTypes.number,
			name: PropTypes.string,
			perc: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]),
		})
	),
	maxDepth: PropTypes.number,
}
KeywordChildren.defaultProps = {
	childs: null,
	maxDepth: 1,
}

const Suggestion = ({ service, suggestions }) => {
	const cnTitle = classnames("pl-5", "text-sm", "font-bold");
	const cnValue = classnames("pl-8", "mt-1");

	return (
		<DSAccordion title={`${service} [${suggestions.length}]`} classes={{ container: 'mt-2', button: { text: cnTitle, icon: 'w-6 h-6 text-primary space-y-0' } }}>
			<ul>
				{
					suggestions.sort((a, b) => a.localeCompare(b)).map((suggestion) =>
						<li key={suggestion} className={cnValue}>{suggestion}</li>
					)
				}
			</ul>
		</DSAccordion>
	)
}

Suggestion.propTypes = {
	service: PropTypes.string,
	suggestions: PropTypes.arrayOf(
		PropTypes.string
	)
}
Suggestion.defaultProps = {
	service: null,
	suggestions: null,
}

const RightColumn = ({ selected, suggestions, categories, pills }) => {
	const suggestionsList = suggestions.reduce((acc, elem) => {
		if (elem.key === selected) {
			if (acc[elem.service] === undefined) {
				acc[elem.service] = [];
			}
			acc[elem.service].push(...elem.value);
		}
		return acc;
	}, {});

	const categoriesList = categories?.filter((cat) => cat.key === selected)[0].categories;

	const pillsList = pills?.filter((cat) => cat.key === selected)[0].pills;

	const getService = ({ service }) =>
		LANG.graphs.columns.suggestions.values.filter((val) => val.value === service)[0]?.label;

	return (
		<div className={cnRightColumn}>
			{ categoriesList && categoriesList.length > 0 && (
				<>
					<div className={cnTitleMargin}><strong>{LANG.graphs.columns.categories.title}:</strong></div>
					<ul className='mt-2 pl-5 justify-between relative gap-3'>
						{ categoriesList.map((cat) =>
							<li className='flex mt-1 items-center gap-1' key={cat}>
								{cat}
							</li>
						) }
					</ul>
				</>
			) }
			{ pillsList && pillsList.length > 0 && (
				<>
					<div className={cnTitleMargin}><strong>{LANG.graphs.columns.pills.title}:</strong></div>
					<ul className='mt-2 pl-5 justify-between relative gap-3'>
						{ pillsList.map((pill) =>
							<li className='flex mt-1 items-center gap-1' key={pill}>
								{pill}
							</li>
						) }
					</ul>
				</>
			) }
			{ (Object.keys(suggestionsList).length === 0 || (Object.keys(suggestionsList).length === 1 && suggestionsList['Also-Asked'].length === 0)) ?
				<div key='suggestions' className={cnTitleMargin}><strong>{LANG.graphs.columns.suggestions.empty}:</strong></div>
			:
				<>
					<div key='suggestions' className={cnTitleMargin}><strong>{LANG.graphs.columns.suggestions.title}</strong></div>
					{
						Object.entries(suggestionsList)
							.sort(([a], [b]) => a.localeCompare(b))
							.map(([key, value]) =>
							value.length > 0 	&&
								<Suggestion key={key} service={getService({ service: key })} suggestions={value} />
						)
					}
				</>
			}
		</div>
	)
}

RightColumn.propTypes = {
	selected: PropTypes.string,
	suggestions: PropTypes.arrayOf(
		PropTypes.shape({})
	),
	categories: PropTypes.arrayOf(
		PropTypes.shape({})
	),
	pills: PropTypes.arrayOf(
		PropTypes.shape({})
	),
}
RightColumn.defaultProps = {
	selected: null,
	suggestions: null,
	categories: null,
	pills: null,
}

const CirclePackingColumn = ({ className, keyword, suggestions, categories, pills }) => {
	const baseClassName = classnames(cnLeftColumn, className);

	return (
		<>
			<div className={baseClassName}>
				{ (keyword.children === undefined || keyword.children.length === 0) &&
					<p className='mb-4'>{LANG.circlePacking.noData}</p>
				}
				{
					keyword.parents !== undefined && keyword.parents.length > 0 && (
						<KeywordParents parents={keyword.parents} />
					)
				}
				{
					keyword.selected !== undefined && keyword.origin !== keyword.selected && (
						<KeywordSelected keyword={keyword.selected.name} />
					)
				}
				{
					keyword.children !== undefined && (
						<KeywordChildren childs={keyword.children} maxDepth={keyword.maxDepth} />
					)
				}
			</div>
			{
				keyword.selected !== undefined && suggestions !== undefined && (
					<RightColumn selected={keyword.selected.name} suggestions={suggestions} categories={categories} pills={pills} />
				)
			}
		</>
  	)
};

CirclePackingColumn.propTypes = {
  	className: PropTypes.string,
	keyword: PropTypes.shape({
		origin: PropTypes.string,
		maxDepth: PropTypes.number,
		selected:  PropTypes.shape({
			name: PropTypes.string,
		}),
		parents: PropTypes.arrayOf(
			PropTypes.string
		),
		children: PropTypes.arrayOf(
			PropTypes.shape({})
		),
	}).isRequired,
	suggestions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			key: PropTypes.string,
			service: PropTypes.string,
			value: PropTypes.arrayOf(
				PropTypes.string,
			),
		})
	),
	categories: PropTypes.arrayOf(
		PropTypes.shape({})
	),
	pills: PropTypes.arrayOf(
		PropTypes.shape({})
	),
};

CirclePackingColumn.defaultProps = {
  className: "",
  suggestions: null,
  categories: null,
  pills: null,
};

export default CirclePackingColumn;
