import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'tailwindcss-classnames';

import DSIcon from 'components/DS/Atoms/DSIcon/DSIcon';

const cnContentParentBase = classnames('w-full', 'overflow-hidden', 'transition-all', 'duration-700');
const cnButtonBase = classnames('w-full', 'flex', 'items-center', 'justify-between', 'transition-all', 'duration-700', 'hover:text-primary');
const cnButtonIconBase = classnames('transform', 'transition-transform', 'duration-700');

const DSAccordion = ({ title, children, classes }) => {
  const [ visible, setVisible ] = useState(false);
  const [ height, setHeight ] = useState(0);
  const content = useRef();

  const toggleVisibility = () => {
    if (!visible) setHeight(content.current.scrollHeight);
    else setHeight(0);
    setVisible(!visible)
  };

  const cnAccordion = classnames('w-full', classes.container);
  const cnButton = classnames(cnButtonBase, classes.button.text);

  const cnContentParent = classnames(cnContentParentBase,
    { 'opacity-0': !visible },
    { 'opacity-1': visible },
  );

  const cnButtonIcon = classnames(cnButtonIconBase, classes.button.icon,
    { 'rotate-0': !visible },
    { 'rotate-180': visible },
  );

  const cnContent = classnames(classes.content);

  return (
    <div className={cnAccordion}>
      <button type='button' onClick={toggleVisibility} className={cnButton}>
        <span>{title}</span>
        <DSIcon id='chevron-down' className={cnButtonIcon} />
      </button>
      <div className={cnContentParent} style={{ maxHeight: `${height}px` }}>
        <div ref={content} className={cnContent}>{children}</div>
      </div>
    </div>
  );
};

DSAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    button: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
    }),
    content: PropTypes.string,
  }),
};

DSAccordion.defaultProps = {
  classes: {
    container: 'text-xl font-bold',
    button: {
      text: 'text-xl font-bold',
      icon: 'w-8 h-8 text-primary',
    },
    content: 'py-4 text-base',
  },
}

export default DSAccordion;