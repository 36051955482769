/* eslint no-use-before-define: "off" */
/* eslint no-param-reassign: "off" */
/* eslint no-return-assign: "off" */
/* eslint func-names: "off" */
import * as d3 from 'd3';
import { Library } from '@observablehq/stdlib/';

function rewriteId(kw) {
    return `id-${kw.replace(/"/g, '').replace(/[^\w ]+/g, '').replace(/ /g, '-')}`;
}

function getAllChildren(total, node) {
	let result = total + node.children ? node.children.length : 0;

	if (node.children && node.children.length > 0) node.children.forEach((n) => result += getAllChildren(result, n));

	return result;
}

function updateChildrenInfo(focus, update, origin, maxDepth) {
	let { parent } = focus;
	const parents = [];
	while (parent !== null) {
		parents.push(parent.data.name);
		parent = parent.parent;
	}

	const totalChildren = getAllChildren(0, focus) - focus.children.length;

	const childs = focus.children.map(child => ({
			id: `${child.x}${child.y}`,
			name: child.data.name,
			perc: (getAllChildren(0, child) / totalChildren * 100).toFixed(2),
			depth: child.depth,
		})
	);

	update({
		origin,
		maxDepth,
		selected: focus.data,
		parents: parents.reverse(),
		children: childs.sort((a, b) => Number(b.perc) - Number(a.perc)),
	});
}

const CirclePackingGraph = (element, data, updateColumn, maxDepth) => {
	const height = element.scrollHeight;
	const width = element.scrollWidth;

	if (data.children.length === 0) {
		const svg = d3.select(element)
			.attr("viewBox", `-${width} -${height} ${width * 2} ${height * 2}`)
			.attr("width", width)
			.attr("height", height)
			.style("text-align", "center");

		svg.selectAll("g").remove();
		svg.selectAll("defs").remove();

		svg.append('text')
			.text('NO DATA AVAILABLE')
			.attr("dominant-baseline", "middle")
			.attr("text-anchor", "middle")
			.style("font-size", "44px")
			.style("fill", "#ffffff");

		updateColumn({
			origin: data.name,
			selected: data,
		});
		return svg.node();
	}

	const color = d3.scaleLinear().domain([1, 3]).range(["hsl(0,0%,100%)", "hsl(0,100%,45%)"]).interpolate(d3.interpolateHcl);
	const format = d3.format(",d");
	const pack = (json) => d3.pack()
			.size([width - 2, height - 2])
			.padding(3)
		(d3.hierarchy(json)
			.sum(d => d.value)
			.sort((a, b) => b.value - a.value));

	const library = new Library();
	const root = pack(data);
	const focus = root;
	let view;

	const svg = d3.select(element)
		.attr("id", rewriteId(data.name))
		.attr("viewBox", `-${width} -${height} ${width * 2} ${height * 2}`)
		.style("cursor", "pointer")
		.style("text-shadow", "0 2px 0px #fff, 2px 0 0px #fff, 0px -2px 0px #fff, -2px 0px 0px #fff,0 3px 1px #fff, 3px 0 1px #fff, 0px -3px 1px #fff, -3px 0px 1px #fff")
		.on("click", () => zoom(root, leaf, node));

	svg.selectAll("g").remove();
	svg.selectAll("filter").remove();

	const shadow = library.DOM.uid("shadow");

	svg.append("filter")
		.attr("id", shadow.id)
		.append("feDropShadow")
		.attr("flood-opacity", 0.3)
		.attr("dx", 0)
		.attr("dy", 1);

	const node = svg.append("g")
		.selectAll("circle")
		.data(root.descendants().slice(1))
		.join("circle")
		.attr("fill", d => d.children ? color(d.depth) : "#fff")
		.attr("stroke", d => d.children ? null : "#000")
		.attr("pointer-events", d => !d.children ? "none" : null)
		.attr("id", d => rewriteId(d.data.name))
		.on("mouseover", function() {
			d3.select(this).attr("stroke", "#000");
		}).on("mouseout", function () {
			d3.select(this).attr("stroke", null);
		}).on("click", (e, d) => focus !== d && (zoom(d, leaf, node), e.stopPropagation()));

	const leaf = svg.append("g")
		.style("font-size", "32px")
		.style("font-family", "VodafoneRegularBold")
		.attr("pointer-events", "none")
		.attr("text-anchor", "middle")
		.selectAll("text")
		.data(root.descendants())
		.join("text")
		.style("fill-opacity", d => d.parent === root ? 1 : 0)
		.style("fill", "#333")
		.style("display", d => d.parent === root ? "inline" : "none")
		.text(d => d.data.name);

	const zoom = (foc, ...elements) => {
		const [lab, nod] = elements;

		const transition = svg.transition()
			.duration(750)
			.tween("zoom", () => {
				const i = d3.interpolateZoom(view, [foc.x, foc.y, foc.r * 2]);
				return t => zoomTo(i(t), lab, nod);
			});

		lab
			.filter(function(d) { return d.parent === foc || this.style.display === "inline"; })
			.transition(transition)
				.style("fill-opacity", d => d.parent === foc ? 1 : 0)
				.on("start", function(d) { if (d.parent === foc) this.style.display = "inline"; })
				.on("end", function(d) { if (d.parent !== foc) this.style.display = "none"; });

		updateChildrenInfo(foc, updateColumn, data.name, maxDepth);
	}

	const zoomTo = (v, ...elements) => {
		const [lab, nod] = elements;
		const k = width / v[2];
		view = v;

		lab.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
		nod.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
		nod.attr("r", d => d.r * k);
	}

	zoomTo([root.x, root.y, root.r * 2], leaf, node);
	svg.on('click')();

	node.append("title")
		.text(d => `${d.ancestors().map(g => g.data.name).reverse().join("/")}\n${format(d.value)}`);

	return svg.node()
}

export default CirclePackingGraph;
