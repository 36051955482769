import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { classnames } from 'tailwindcss-classnames';
import { LANG } from 'hooks/manageLanguage';

import { getKeywords, getGraph, getGraphJson } from "api/keywords";

import Layout from 'components/Layout/Layout';
import GraphFilters from 'components/Common/GraphFilters/GraphFilters';
import CirclePackingColumn from 'routes/CirclePacking/components/CirclePackingColumn';
import CirclePackingGraph from 'routes/CirclePacking/components/CirclePackingGraph';
import background from "assets/images/background-red2.jpg";
import { DSIcon } from 'components/DS';
import GraphMaxRepetitions from 'components/Common/GraphMaxRepetitions/GraphMaxRepetitions';

const cnContainer = classnames("flex", "flex-col", "w-full");
const cnContent = classnames("flex", "flex-grow", "bg-gray-50", "h-1");
const cnColumn = classnames("flex-shrink-0", "w-96");
const cnGraph = classnames("bg-center", "bg-cover", "bg-no-repeat", "flex-grow", "ml-80", "mr-80");

const CirclePacking = () => {
	const [ topVisibility, setTopVisibility ] = useState(false);
	const { slug, id } = useParams();
	const { data: dataFilters, isLoading: isLoadingFilters } = useQuery(["filtersKeyword", slug], () => getKeywords({
		slug,
		sortBy: 'created_at:asc',
	}));
	const { data: dataGraph, isLoading: isLoadingGraph } = useQuery(["circlesGraph", slug, id], () => getGraph({ graph: `circles`, slug, keyword_id: id }));
	const { data: dataJson, isLoading: isLoadingJson } = useQuery(["circlesGraphJson", slug, id], () => getGraphJson(dataGraph.data), {
		enabled: !isLoadingGraph,
	});
	const ref = useRef(null);
	const repetitionsRef = useRef(null);

	const [ mainKeywordInfo, setMainKeywordInfo ] = useState({});
	const [ suggestions, setSuggestions ] = useState(undefined);
	const [ categories, setCategories ] = useState(undefined);
	const [ pills, setPills ] = useState(undefined);

	const handleTopVisibility = () => setTopVisibility(!topVisibility);

	useEffect(() => {
		if (dataJson && dataFilters) {
			const maxDepth = dataFilters.data.results.filter((result) => result.keyword_id === id)[0].recursion;

			setMainKeywordInfo({ origin: dataJson.data.name, maxDepth });
			setSuggestions(dataJson.data.suggestions);
			setCategories(dataJson.data.categories);
			setPills(dataJson.data.pills);
			CirclePackingGraph(ref.current, dataJson.data, setMainKeywordInfo, maxDepth);
			if (dataJson.data.topTen.length > 0) GraphMaxRepetitions(repetitionsRef.current, dataJson.data.topTen);
		}
	}, [dataJson, dataFilters]);

	return (
		<Layout>
			{
				isLoadingGraph || isLoadingJson ? <p>Cargando datos...</p>
				:
				<div className={cnContainer}>
					<GraphFilters graph='circle-packing' origin={dataJson?.data?.name} data={dataFilters} isLoading={isLoadingFilters} />
					<div className={cnContent}>
						<CirclePackingColumn className={cnColumn} keyword={mainKeywordInfo} suggestions={suggestions} categories={categories} pills={pills} />
						<svg key={`${slug}${id}`} className={cnGraph} style={{ backgroundImage: `url(${background})` }} ref={ref}  />
						{ dataJson.data.topTen.length > 0 &&
							<div
								className={`fixed max-h-topRepetitions h-full top-full right-0 left-0 bg-white px-4 py-2 z-50 border-t border-gray-200 transition-transform duration-700 transform ${topVisibility ? '-translate-y-full' : '-translate-y-10'}`}
							>
								<button
									type='button'
									className='w-full items-center justify-between flex'
									onClick={handleTopVisibility}
								>
									<p>{LANG.graphs.mostRepeatedRelations}</p>
									<DSIcon
										id='chevron-down'
										className={`w-6 h-6 text-primary transition-transform duration-700 transform ${topVisibility ? 'rotate-0' : 'rotate-180'}`}
									/>
								</button>
								<svg className='w-full h-full' ref={repetitionsRef} />
							</div>
					}
					</div>
				</div>
			}
		</Layout>
	)
}

export default CirclePacking;