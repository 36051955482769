import React, { useContext } from "react";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";
import { useQuery } from "react-query";
import { getKeywordRelations } from "api/keywordRelations";
import { updateColumnsAction } from "routes/Sankey/SankeyReducer";
import SankeyContext from "routes/Sankey/SankeyContext";
import { DSAccordion } from 'components/DS';
import { LANG } from 'hooks/manageLanguage';
import SankeyKeywordRelation from "./SankeyKeywordRelation";

const SankeyColumn = ({ keywordKey, index, className }) => {
  const { state, dispatch } = useContext(SankeyContext);

  const odd = index % 2 !== 0;
  const baseClassName = classnames("flex flex-col flex-shrink-0", className, {
    "bg-gray-100": odd,
    "bg-white": !odd,
  });

  const { data, isLoading, isError, error } = useQuery(
    ["sankeyKeywordRelations", state.id, keywordKey],
    () =>
      getKeywordRelations({
        keyword_id: state.id,
        keyword: keywordKey,
      })
  );

  const headerClickHandler = (key) => () => {
    dispatch(updateColumnsAction({ value: key, index }));
  };

  const keywordClickHandler = (key) => () => {
    dispatch(updateColumnsAction({ value: key, index: index + 1 }));
  };

  const isKeywordSelected = (value) => state.columns[index + 1] === value;

  return (
    <div className={baseClassName}>
      <div className="bg-gray-500 py-2.5 px-3 text-white text font-bold border-r border-gray-600 overflow-x-scroll">
      <button type='button' onClick={headerClickHandler(keywordKey)} className="whitespace-nowrap text-white font-bold hover:underline">{keywordKey}</button>
      </div>

      {isLoading && <p className="p-3">{LANG.sankey.loading}</p>}
      {isError && <>{error?.message}</>}
      {!isLoading && !isError && (
        <div className="flex-grow overflow-y-scroll h-40">
          {(!data || data.data.totalResults === 0 || data.data.results[0].keyword.length === 0) && (
            <p className="h-full flex p-3">
              <span>{LANG.sankey.noData}</span>
            </p>
          )}

          { index === 0 &&
            <DSAccordion
              title={LANG.sankey.legend.title}
              classes={{
                container: 'text-left py-4 px-4 border-b',
                button: { text: 'text-sm font-bold', icon: 'w-6 h-6 text-primary' },
                content: 'text-sm py-2'
              }}
            >
              <p>{LANG.sankey.legend.description}</p>
            </DSAccordion>
          }

          {data &&
            data.data.totalResults > 0 &&
            data.data.results[0].relations.map((akw) => {
              const iconList = [];
              const isLastColumn = index === state.columns.length - 1;
              if (
                isLastColumn &&
                state.columns.find((columnkw) => columnkw === akw)
              ) {
                iconList.push("refresh");
              }

              return (
                <div className="border-r border-gray-300 border-b" key={akw}>
                  <SankeyKeywordRelation
                    keyword={akw}
                    onClick={keywordClickHandler(akw)}
                    highlight={isKeywordSelected(akw)}
                    iconList={iconList}
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

SankeyColumn.propTypes = {
  keywordKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
};

SankeyColumn.defaultProps = {
  className: "",
};

export default React.memo(SankeyColumn);
