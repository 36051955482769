import React from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";
import { SVG } from "./svg";

const DSIcon = ({ id, className }) => {
  const classes = classnames(className, "inline-block");
  return (
    <>
      <SVG id={id} className={classes} />
    </>
  );
};

DSIcon.propTypes = {
  id: PropTypes.oneOf([
    "calendar-today",
    "school",
    "shopping-cart",
    "build",
    "splinter",
    "info",
    "subdirectory-arrow-right",
    "chevron-down",
    "chevron-left",
    "chevron-right",
    "edit",
    "delete",
    "close",
    "sync",
    "viewed",
    "no-viewed",
    "warning",
    "error",
    "like",
    "spinner",
    "refresh",
    "loading",
    "support",
    "search",
    "googleNews",
    "googleMaps",
    "googleImages",
    "googleShopping",
    "googleVideos",
    "googleLibros",
    "googleVuelos",
    "googleFinance",
    "favourite",
    "favouriteInactive",
    "family",
    "addOrPlus",
    "google",
    "amazon",
    "youtube",
    "listView",
    "logout",
    "admin",
    "settings",
    "peopleAlsoAsk",
    "documentXls",
    "countryOrInternational",
    "tickOrSolvedEsp",
    "rocket",
  ]).isRequired,
  className: PropTypes.string,
};

DSIcon.defaultProps = {
  className: "",
};

export { SVGSource as DSIconSource } from "./svg";
export default DSIcon;
