import React from "react";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";
import DSIcon from "../../Atoms/DSIcon/DSIcon";
import DSProgressBar from "../../Atoms/DSProgressBar/DSProgressBar";

const DSKeyword = ({
  keyword,
  iconList,
  weight,
  onClick,
  highlight,
  loadingInfo,
}) => {
  const cn = classnames(
    "relative",
    "group",
    "p-3",
    "border-r-4",
    "hover:border-primary",
    "space-y-3",
    {
      "border-primary": highlight,
    },
    {
      "border-transparent": !highlight,
    }
  );

  return (
    <div
      className={cn}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <p>{keyword}</p>
      <div className="flex space-x-4 items-center h-5">
        {loadingInfo && <DSIcon id="spinner" className="w-5 h-5 text-gray-500 animate-spin" />}
        {!loadingInfo && (
          <>
            <div className="w-full">
              <DSProgressBar className='w-full inline-block align-middle' progress={weight} />
            </div>
            <div className="flex space-x-1 w-1/5 justify-end">
              {iconList.length > 0 &&
                iconList.map((icon) => (
                  <DSIcon
                    id={icon}
                    key={icon}
                    className="w-5 h-5 text-gray-500"
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

DSKeyword.propTypes = {
  keyword: PropTypes.string.isRequired,
  iconList: PropTypes.arrayOf(PropTypes.string),
  weight: PropTypes.number,
  onClick: PropTypes.func,
  highlight: PropTypes.bool,
  loadingInfo: PropTypes.bool,
};

DSKeyword.defaultProps = {
  iconList: [],
  onClick: null,
  highlight: false,
  weight: null,
  loadingInfo: false,
};

export default DSKeyword;
