/* eslint no-nested-ternary: "off" */
import React, { useState, forwardRef, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import PropTypes from "prop-types";
import { subDays } from "date-fns";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";
import { classnames } from "tailwindcss-classnames";

import "react-datepicker/dist/react-datepicker.css";
import "./DSDatepicker.css";

import { LANG } from 'hooks/manageLanguage';

import DSIcon from "../DSIcon/DSIcon";
import DSInput from "../DSInput/DSInput";

registerLocale("es", es);
registerLocale("en", en);

const monthsStrings = LANG.components.datepicker.months;

const cnBase = classnames("flex", "justify-between", "items-center", "px-2");
const cnArrowBase = classnames("w-11", "py-3");
const cnArrowLeft = classnames(cnArrowBase, "custom-datepicker__prev-button");
const cnArrowRight = classnames(cnArrowBase, "custom-datepicker__next-button");
const cnArrowLeftInvisible = classnames(cnArrowLeft, "invisible");
const cnArrowRightInvisible = classnames(cnArrowRight, "invisible");
const cnArrowIcon = classnames("w-7", "h-auto");
const cnHeaderParagraph = classnames("custom-datepicker__header-paragraph");

const DSDatepicker = ({ label, id, name, start, classes, filter, featured, onChange, error, errorHint, headerLegend }) => {
  const [dates, updateDates] = useState(filter);
  const [filtered] = useState(filter !== null);
  const [startDate, setStartDate] = useState(start !== null ? start : filtered ? filter[0] : new Date());
  // Por defecto, si hay fechas filtradas el estado de la flecha derecha es false porque cargamos la última fecha
  const [arrows, setArrows] = useState({ left: true, right: !filter });

  const cnDatepickerBase = classnames("custom-datepicker");
  const cnDatepicker = classnames(cnDatepickerBase, classes);
  const cnDatepickerFiltered = classnames(cnDatepicker, "custom-datepicker--filtered");

  const zeroDateHours = (date) => new Date(date).setHours(0, 0, 0, 0);
  const monthYearDate = (date) => {
    const newDate = new Date(date);
    return `${newDate.getMonth()}/${newDate.getFullYear()}`;
  }

  const changeDate = (date) => {
    setStartDate(date);
    onChange(date);
  }

  const infoDate = (date) => {
    // Filtramos las fechas que tenemos parseadas
    const dateInterdites = dates.map((arrVal) => zeroDateHours(arrVal));

    return dateInterdites.includes(zeroDateHours(date));
  }

  const generateNewDate = (date, direction) => {
    // Inc/Dec year en función del siguiente mes
    let month = date.getMonth() + direction;
    let year = date.getFullYear();

    if (month < 0) {
      month = 11;
      year -= 1;
    } else if (month > 11) {
      month = 0;
      year += 1;
    }

    return new Date(`${year}/${month + 1}/01`);
  }

  const updateArrows = (date) => {
    // Marcamos ambas flechas como visibles
    let arr = { left: true, right: true };

    // Si es el primer mes con datos ocultamos la flecha de la izquierda
    if (monthYearDate(filter[0]) === monthYearDate(date))
      arr = { ...arr, right: false };

    // Si es el último mes con datos ocultamos la flecha de la derecha
    if (monthYearDate(filter[filter.length - 1]) === monthYearDate(date))
      arr = { ...arr, left: false };

    // Seteamos las flechas con los valores
    setArrows(arr);
  }

  const checkDate = (date, direction) => {
    const getDate = new Date(date);
    let compareDate = generateNewDate(getDate, direction);

    if (filtered) {
      // Si la fecha no existe, seguimos inc/dec hasta encontrar una que tenga información
      while (!dates.map((dat) => monthYearDate(dat)).includes(monthYearDate(compareDate))) {
        if ((dates[0] < compareDate) || (dates[dates.length - 1] > compareDate)) {
          break;
        }
        compareDate = generateNewDate(
          new Date(`${compareDate.getFullYear()}/${compareDate.getMonth() + 1}/01`),
          direction
        );
      }

      // Actualizamos las flechas
      updateArrows(compareDate);
    }

    return compareDate;
  }

  const clickArrow = ({ date, changeMonth, changeYear, dir }) => {
    const update = checkDate(date, dir);
    const newDate = new Date(update);
    changeMonth(newDate.getMonth());
    changeYear(newDate.getFullYear());
  }

  const headerDate = (date) => {
    const newDate = new Date(date);
    return `${monthsStrings[newDate.getMonth()]} / ${newDate.getFullYear()}`;
  }



  const hightlightDates = [{
    "react-datepicker__day--highlighted--featured": featured.map((arrVal) => zeroDateHours(arrVal)).map((date) => subDays(date, 0)),
  }];

  useEffect(() => {
    if (filtered) {
      // Ordenamos las fechas de más recientes a más antiguas
      filter.sort((a, b) => new Date(b) - new Date(a));

      updateDates(filter);

      setArrows({ left: true, right: false });
    }
  }, [filter]);

  const CustomHeader = ({ ...props }) => (
    <>
      <div className={cnBase}>
        <button
          type="button"
          className={arrows.left ? cnArrowLeft : cnArrowLeftInvisible}
          onClick={() => clickArrow({ date: props.date, changeMonth: props.changeMonth, changeYear: props.changeYear, dir: -1 })}
          disabled={props.prevMonthButtonDisabled}
        >
        <DSIcon id="chevron-left" className={cnArrowIcon} />
        </button>
        <p className={cnHeaderParagraph}>
          {headerDate(props.date)}
        </p>
        <button
          type="button"
          className={arrows.right ? cnArrowRight : cnArrowRightInvisible}
          onClick={() => clickArrow({ date: props.date, changeMonth: props.changeMonth, changeYear: props.changeYear, dir: 1 })}
          disabled={props.nextMonthButtonDisabled}
        >
          <DSIcon id="chevron-right" className={cnArrowIcon} />
        </button>
      </div>
      { headerLegend }
    </>
  );

  CustomHeader.propTypes = {
    date: PropTypes.string,
    changeMonth: PropTypes.func,
    changeYear: PropTypes.func,
    prevMonthButtonDisabled: PropTypes.bool,
    nextMonthButtonDisabled: PropTypes.bool,
  };

  CustomHeader.defaultProps = {
    date: null,
    changeMonth: null,
    changeYear: null,
    prevMonthButtonDisabled: false,
    nextMonthButtonDisabled: false,
  };

  const CustomInput = forwardRef(({ ...props }, ref) => (
    <div ref={ref}>
      <DSInput
        label={label}
        id={id}
        type="text"
        name={name}
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        error={error}
        errorHint={errorHint}
        readOnly
      />
    </div>
  ));

  CustomInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
  };

  CustomInput.defaultProps = {
    value: null,
    onClick: null,
    onChange: null,
  };

  return (
    <div className={filtered ? cnDatepickerFiltered : cnDatepicker}>
      <DatePicker
        locale={LANG.components.datepicker.locale}
        calendarStartDay={LANG.components.datepicker.locale === 'es' ? 1 : 0}
        className={cnDatepickerBase}
        onChange={changeDate}
        selected={startDate}
        startDate={startDate}
        filterDate={filtered ? infoDate : null}
        highlightDates={hightlightDates}
        minDate={filtered ? new Date(filter[filter.length - 1]) : null}
        maxDate={filtered ? new Date() : null}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select date"
        disabledKeyboardNavigation
        onCalendarOpen={() => {
          // Si el campo no está vacío
          if (startDate !== null && filtered)
            updateArrows(startDate);
        }}
        customInput={<CustomInput />}
        renderCustomHeader={({
          date,
          changeMonth,
          changeYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CustomHeader
            date={date.toString()}
            changeMonth={changeMonth}
            changeYear={changeYear}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
      />
    </div>
  );
};

DSDatepicker.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  start: PropTypes.instanceOf(Date),
  classes: PropTypes.string,
  filter: PropTypes.arrayOf(
    PropTypes.instanceOf(Date)
  ),
  featured: PropTypes.arrayOf(
    PropTypes.instanceOf(Date)
  ),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorHint: PropTypes.string,
  headerLegend: PropTypes.node,
};

DSDatepicker.defaultProps = {
  label: null,
  id: null,
  name: null,
  start: null,
  classes: "",
  filter: null,
  featured: null,
  onChange: null,
  error: false,
  errorHint: null,
  headerLegend: null,
};

export default DSDatepicker;
