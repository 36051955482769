/* eslint no-use-before-define: "off" */
/* eslint prefer-destructuring: "off" */
import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import manageStorage from "hooks/manageStorage";

import NotificationContext from 'context/NotificationsContext';

import generateKeyword from "api/scrapinghubJob";
import { getSuggestions } from "api/keywords";

import { DSModal, DSInput, DSButton, DSSelect, DSTooltip, DSKeywordGenerator, DSIcon } from "components/DS/";

import shubPlus from "assets/images/shub-plus.png";
import selectedKw from "assets/images/selected-kw.png";

import { LANG } from 'hooks/manageLanguage';

const { get } = manageStorage();

const loginSchema = Yup.object().shape({
  priority: Yup.number().required(LANG.generateKeyword.form.errors.priority),
});

const priorityOptions = LANG.generateKeyword.form.priority.values;

const suggestionServices = [
  { name: 'google' },
  { name: 'amazon' },
  { name: 'youtube' }
];

const generateKey = (pre) =>`${ pre }_${ new Date().getTime() }_${Math.random()}`;

const priorityLabel =
  <>
    {LANG.generateKeyword.form.priority.title}
    <DSTooltip
      icon='support'
      title={LANG.generateKeyword.form.priority.tooltip.title}
      description={LANG.generateKeyword.form.priority.tooltip.description}
    />
  </>;

const keywordsLabel =
  <>
    {LANG.generateKeyword.form.keywordsSelection.tooltip.description.title}
      <ul>
        <li key={generateKey('tooltip-sublist')} className='mt-2 flex'>- <img src={selectedKw} alt='' className='w-15 h-6 mr-2' />{LANG.generateKeyword.form.keywordsSelection.tooltip.description.list.select}</li>
        <li key={generateKey('tooltip-sublist')} className='mt-2'>- <DSIcon id='favouriteInactive' className='h-6 w-6 mr-2' />{LANG.generateKeyword.form.keywordsSelection.tooltip.description.list.favourite}</li>
        <li key={generateKey('tooltip-sublist')} className='mt-2'>- <DSIcon id='family' className='h-6 w-6 mr-2' />{LANG.generateKeyword.form.keywordsSelection.tooltip.description.list.alsoasked}</li>
        <li key={generateKey('tooltip-sublist')} className='mt-2'>- <DSIcon id='google' className='h-4 w-4 mr-2' />{LANG.generateKeyword.form.keywordsSelection.tooltip.description.list.suggestions.google}</li>
        <li key={generateKey('tooltip-sublist')} className='mt-2'>- <DSIcon id='amazon' className='h-4 w-4 mr-2' />{LANG.generateKeyword.form.keywordsSelection.tooltip.description.list.suggestions.amazon}</li>
        <li key={generateKey('tooltip-sublist')} className='mt-2'>- <DSIcon id='youtube' className='h-5 w-5 mr-2' />{LANG.generateKeyword.form.keywordsSelection.tooltip.description.list.suggestions.youtube}</li>
      </ul>
  </>

const selectSuggestParse = ({ data, service }) => {
  let result;
  switch (service) {
    case 'google':
    default:
      result = data[1];
      break;
    case 'amazon':
      result = data.suggestions.map(el => el.value);
      break;
    case 'youtube':
      result = data.split("[[")[1].replace(/.$/, '').split('],[')
        .map(el => el.split('",')[0].replace(/"/, ''));
      break;
  }
  return result;
};

const GenerateKeyword = () => {
  const [ input, setInput ] = useState('');
  const [ keywords, setKeywords ] = useState([]);
  const [ showModal, setShowModal ] = useState(false);
  const { setNotification } = useContext(NotificationContext);

  const maintenance = false;

  const mutation = useMutation((values) => generateKeyword(values), {
    onSuccess(res) {
      const jobsStatus = [...res.data.filter((job) => job.status !== 'ok')];

      if (res.status === 201 && jobsStatus.length === 0) {
        setNotification({
          icon: 'like',
          text: 'Keyword(s) enviada(s) a ScrapingHub correctamente',
          color: 'success'
        });
        setKeywords([]);
        formik.resetForm();
      } else
        setNotification({
          icon: 'error',
          text: res.data.job.message,
          color: 'danger'
        });
    },
  });

  const formik = useFormik({
    initialValues: {
      recursion: 4,
      priority: 2,
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const crawlKeywords = keywords
        .filter((kw, index, self) => index === self.findIndex((t) => (
          t.value === kw.value && t.selected
        )))
        .map(kw => ({
          value: kw.value,
          favourite: kw.favourite,
          alsoasked: kw.peoplealsoask,
        }));
      mutation.mutate({
        ...values,
        keywords: crawlKeywords,
        email: get().data.user.email,
      });
    },
  });

  const handleViewModal = () => {
    setShowModal(!showModal);
  };

  const suggestMutation = useMutation(({ keyword, service }) => getSuggestions({ keyword, service }), {
    onSuccess({ res, parent, service }) {
      const suggestions = selectSuggestParse({ data: res.data.result, service });
      const newKeywords = suggestions
        .map(el => ({
          value: el,
          selected: keywords.reduce((acc, kw) => {
              if (kw.selected) acc.push(kw.value);
              return acc;
            }, [])
            .indexOf(el) !== -1,
          favourite: keywords.filter(kw => kw.value === el)[0]?.favourite || false,
          peoplealsoask: keywords.filter(kw => kw.value === el)[0]?.peoplealsoask || false,
          type: 'suggestion',
          parent,
          service,
          visible: true,
        }));

      setKeywords([...keywords, ...newKeywords]);
    }
  });

  const onChangeInput = (e) => {
    setInput(e.target.value);
  };

  const onClickKeyword = (parent) => {
    setKeywords(keywords.map((keyword) => {
      if (keyword.value === parent) {
        return {
          ...keyword,
          selected: !keyword.selected,
          favourite: keyword.selected ? false : keyword.favourite,
          peoplealsoask: keyword.selected ? false : keyword.peoplealsoask,
        };
      }
      return keyword;
    }));
  };

  const onClickFavourite = (parent) => {
    setKeywords(keywords.map((keyword) => {
      if (keyword.value === parent) {
        return { ...keyword, selected: true, favourite: !keyword.favourite };
      }
      return keyword;
    }));
  };

  const onClickPeopleAlsoAsk = (parent) => {
    setKeywords(keywords.map((keyword) => {
      if (keyword.value === parent) {
        return { ...keyword, selected: true, peoplealsoask: !keyword.peoplealsoask };
      }
      return keyword;
    }));
  };

  const addKeyword = () => {
    if (input.length >= 2) {
      if (keywords.map(kw => kw.value).indexOf(input) === -1) {
        setKeywords([...keywords, {
          value: input,
          selected: true,
          peoplealsoask: false,
          type: 'keyword',
          services: suggestionServices.map(serv => ({ ...serv, visible: true, loading: false })),
        }]);
        setInput('');
      } else {
        setNotification({
          icon: 'error',
          text: 'La keyword ya está añadida',
          color: 'warning'
        });
      }
    }
  };

  const searchSuggestions = ({ service, value }) => {
    setKeywords(
      keywords.map(kw => {
        if (kw.value === value && kw.type === 'keyword') {
          return {
            ...kw,
            services: kw.services.map(serv => {
              if (serv.name === service.name) return { ...serv, loading: true };
              return serv;
            }),
          }
        }
        return kw;
      })
    );
    suggestMutation.mutate({ keyword: value, service: service.name });
  };

  const SuggestionsButton = ({ service, keyword }) => {
    const loading = keyword.services.filter(kw => kw.name === service.name)[0]?.loading;
    return (
      <button type="button" aria-label={`Recomendaciones de ${service.name}`}  className='border bg-white rounded-full w-10 h-10 transition-colors border-gray-400 hover:border-gray-800' onClick={() => searchSuggestions({ service, value: keyword.value })}>
        <DSIcon id={loading ? 'loading' : service.name} className={`${loading ? 'ml-2.5 w-8 h-8' : 'w-5 h-5 '}`} />
      </button>
    )
  };

  SuggestionsButton.propTypes = {
    service: PropTypes.shape({
      status:  PropTypes.string,
      name:  PropTypes.string,
    }).isRequired,
    keyword: PropTypes.shape({
      value: PropTypes.string,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      )
    }).isRequired,
  };

  const toggleSuggestions = ({ keyword, service }) => {
    setKeywords(
      keywords.map(kw => {
        if (kw.value === keyword && kw.type === 'keyword') {
          return {
            ...kw,
            services: kw.services.map(serv => {
              if (service === serv.name) return { ...serv, visible: !serv.visible };
              return serv;
            }),
          };
        }
        return kw;
      })
    );
  };

  useEffect(() => {
    if (showModal) {
      setInput('');
      setKeywords([]);
    }
  }, [showModal]);

  return maintenance ?
    <p>{LANG.generateKeyword.maintenance}</p>
  :
    <>
      <h2 className="text-m font-bold mb-4">{LANG.generateKeyword.title}</h2>
      <button type="button" onClick={handleViewModal}>
        <img className="w-16 h-16" src={shubPlus} alt="Abre ventana modal" />
      </button>
      {showModal && (
        <DSModal onClose={handleViewModal} size='min-w-modalList max-w-modalList'>
          <form className="bg-gray-200" onSubmit={formik.handleSubmit}>
            <fieldset>
              <legend className="text-m font-bold bg-gray-50 p-12 w-full text-center">
                <picture className="flex justify-center">
                  <img
                    className="w-12 h-12 mb-4"
                    src={shubPlus}
                    alt="Abre ventana modal"
                  />
                </picture>
                {LANG.generateKeyword.form.title}
              </legend>
              <div className="flex justify-center flex-col py-16 px-32">
                <div className='flex justify-center items-end'>
                  <DSInput
                    label={LANG.generateKeyword.form.keyword.title}
                    id="keyword"
                    type="text"
                    name="keyword"
                    placeholder={LANG.generateKeyword.form.keyword.placeholder}
                    onChange={onChangeInput}
                    value={input}
                  />
                  <button type='button' className={`w-11 border-3 rounded-sm text-white py-1 -ml-2 transition-all hover:bg-cyan-800 hover:border-cyan-800 ${input.length < 2 ? 'bg-gray-300 border-gray-300 pointer-events-none' : 'bg-cyan-700 border-cyan-700'}`} onClick={addKeyword}>
                    <DSIcon id='addOrPlus' className='w-7 h-7' />
                  </button>
                </div>
                { keywords.filter(kw => kw.type === 'keyword').length > 0 &&
                  <>
                    <p className='text-left mt-6'>
                      {LANG.generateKeyword.form.keywordsSelection.title}
                      <DSTooltip
                        icon='support'
                        title={LANG.generateKeyword.form.keywordsSelection.tooltip.title}
                        description={keywordsLabel}
                      />
                    </p>
                    <ul className='mt-2'>
                      { keywords.filter(keyword => keyword.type === 'keyword').map((keyword, index) =>
                          <li key={generateKey(keyword.value)} className={`bg-white rounded-3xl p-4 ${index === 0 ? '' : 'mt-6'}`}>
                            <ul className='flex gap-4 justify-between items-center'>
                              <li key={generateKey('sublist')}>
                                <DSKeywordGenerator
                                  keyword={keyword.value}
                                  selected={keyword.selected}
                                  favourite={keyword.favourite}
                                  peoplealsoask={keyword.peoplealsoask}
                                  onClickKeyword={() => onClickKeyword(keyword.value)}
                                  onClickFavourite={() => onClickFavourite(keyword.value)}
                                  onClickPeopleAlsoAsk={() => onClickPeopleAlsoAsk(keyword.value)}
                                />
                              </li>
                              <li key={generateKey('services')} className='flex gap-2 items-center'>
                                { suggestionServices.map(service =>
                                  keywords.filter(kw => (kw.parent === keyword.value && kw.service === service.name)).length === 0 && <SuggestionsButton service={service} keyword={keyword} />
                                ) }
                              </li>
                            </ul>
                            { suggestionServices.map(service =>
                                keywords.filter(kw => (keyword.value === kw.parent && kw.type === 'suggestion' && kw.service === service.name)).length > 0 &&
                                <>
                                  <p className='mt-4 text-left text-sm flex justify-between items-center gap-4'>
                                    <span>- {LANG.generateKeyword.form.keywordsSelection.suggestions.from} <strong>{service.name}</strong> {LANG.generateKeyword.form.keywordsSelection.suggestions.for} <strong>&quot;{keyword.value}&quot;</strong></span>
                                    <button
                                      type='button'
                                      className='text-primary'
                                      onClick={() => toggleSuggestions({ keyword: keyword.value, service: service.name })}
                                    >
                                      {keyword.type === 'keyword' && keyword.services.filter(serv => serv.name === service.name)[0]?.visible ? LANG.generateKeyword.form.keywordsSelection.suggestions.accordion.close : LANG.generateKeyword.form.keywordsSelection.suggestions.accordion.open}
                                    </button>
                                  </p>
                                  { keyword.type === 'keyword' && keyword.services.filter(serv => serv.name === service.name)[0]?.visible &&
                                    <ul className='mt-2 mb-6 flex gap-2 flex-wrap'>
                                      { keywords.filter(kw => (keyword.value === kw.parent && kw.type === 'suggestion' && kw.service === service.name)).map(kw =>
                                        <li key={generateKey('keyword-generator')}>
                                          <DSKeywordGenerator
                                            keyword={kw.value}
                                            selected={kw.selected}
                                            favourite={kw.favourite}
                                            peoplealsoask={kw.peoplealsoask}
                                            onClickKeyword={() => onClickKeyword(kw.value)}
                                            onClickFavourite={() => onClickFavourite(kw.value)}
                                            onClickPeopleAlsoAsk={() => onClickPeopleAlsoAsk(kw.value)}
                                          />
                                        </li>
                                      ) }
                                    </ul>
                                  }
                                </>
                              )
                            }
                          </li>
                        ) }
                    </ul>
                  </>
                }
                <div className="mt-4">
                  <DSSelect
                    label={priorityLabel}
                    id="priority"
                    name="priority"
                    value={formik.values.priority}
                    onChange={formik.handleChange}
                    error={formik.touched.priority && !!formik.errors.priority}
                    errorHint={formik.errors.priority}
                    options={priorityOptions}
                    autocomplete="off"
                  />
                </div>
              </div>
            </fieldset>
            <div className="bg-gray-50 flex justify-center flex-col py-16 px-32">
              {
                keywords.filter(kw => kw.selected).length > 0 &&
                <>
                  <p className='text-sm font-bold text-left'>{LANG.generateKeyword.form.resume}</p>
                  <ul className='text-sm flex flex-wrap gap-3 gap-y-1 list-disc mt-2 mb-5'>
                    { keywords.filter((kw, index, self) => index === self.findIndex((t) => (
                        t.value === kw.value && t.selected
                      ))).map((kw, index) =>
                      <li className={index !== 0 ? 'ml-3' : 'list-none'} key={generateKey('kw-resume')}>{kw.value}</li>
                    )}
                  </ul>
                </>
              }
              <DSButton
                type="submit"
                className="w-full"
                disabled={keywords.filter(kw => kw.selected).length === 0 || mutation.isLoading}
              >
                {LANG.generateKeyword.form.cta}
              </DSButton>
            </div>
          </form>
        </DSModal>
      )}
    </>
};
export default GenerateKeyword;
