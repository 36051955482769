import React, { useReducer, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getKeywords, getTopRelations } from "api/keywords";
import Layout from "components/Layout/Layout";
import GraphFilters from 'components/Common/GraphFilters/GraphFilters';
import SankeyColumns from "routes/Sankey/components/SankeyColumns";
import SankeyKwInfo from "routes/Sankey/components/SankeyKwInfo";
import SankeyReducer, {
  initialState,
  setKeywordAction,
} from "routes/Sankey/SankeyReducer";
import SankeyContext from "routes/Sankey/SankeyContext";
import { LANG } from 'hooks/manageLanguage';
import GraphMaxRepetitions from 'components/Common/GraphMaxRepetitions/GraphMaxRepetitions';
import { DSIcon } from 'components/DS';

const Sankey = () => {
	const [ topVisibility, setTopVisibility ] = useState(false);
  const { id, slug } = useParams();
  const [state, dispatch] = useReducer(SankeyReducer, initialState);

  const repetitionsRef = useRef(null);

  const { isLoading, data } = useQuery(["filtersKeyword", slug], () =>
    getKeywords({
      slug,
      sortBy: "created_at:asc",
    })
  );

  const { isLoading: relationsIsLoading, data: relationsData } = useQuery(["relationsData", id], () =>
    getTopRelations({ id })
  );

	const handleTopVisibility = () => setTopVisibility(!topVisibility);

  useEffect(() => {
    if (data?.data?.totalResults > 0) {
      const keyword = data.data.results.filter((kw) => kw.keyword_id === id)[0];
      dispatch(setKeywordAction({ keyword }));
    }
  }, [data]);

  useEffect(() => {
    if (relationsData && relationsData.data.topTen.length > 0) GraphMaxRepetitions(repetitionsRef.current, relationsData.data.topTen);
  }, [relationsData]);

  return (
    <Layout>
      {isLoading && <>Loading...</>}
      {!isLoading && state.id && (
        <SankeyContext.Provider value={{ data: data.data, state, dispatch }}>
          <div className="flex flex-col w-full">
				    <GraphFilters graph='sankey' data={data} isLoading={isLoading} />
            <div className="flex flex-grow bg-gray-50 h-full max-h-sankeyContent">
              <SankeyColumns className="flex-grow" />
              <SankeyKwInfo className="flex-shrink-0 w-96" />
            </div>
          </div>
        </SankeyContext.Provider>
      )}
      { !relationsIsLoading && relationsData.data && relationsData.data.topTen.length > 0 &&
        <div
          className={`fixed max-h-topRepetitions h-full top-full right-0 left-0 bg-white px-4 py-2 z-50 border-t border-gray-200 transition-transform duration-700 transform ${topVisibility ? '-translate-y-full' : '-translate-y-10'}`}
        >
          <button
            type='button'
            className='w-full items-center justify-between flex'
            onClick={handleTopVisibility}
          >
            <p>{LANG.graphs.mostRepeatedRelations}</p>
            <DSIcon
              id='chevron-down'
              className={`w-6 h-6 text-primary transition-transform duration-700 transform ${topVisibility ? 'rotate-0' : 'rotate-180'}`}
            />
          </button>
          <svg className='w-full h-full' ref={repetitionsRef} />
        </div>
      }
    </Layout>
  );
};

export default Sankey;
