/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import PropTypes from "prop-types";

const Context = React.createContext({}); // eslint-disable-line no-unused-vars

export function UsersContextProvider({ children }) {
  const [usersList, setUsersList] = useState(null);

  return (
    <Context.Provider
      value={{
        usersList,
        setUsersList,
      }}
    >
      {children}
    </Context.Provider>
  );
}

UsersContextProvider.propTypes = {
  children: PropTypes.node,
};

UsersContextProvider.defaultProps = {
  children: null,
};

export default Context;
