import React from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";

import DSIcon from "../DSIcon/DSIcon";

const cnBase = classnames('flex');
const cnButtonBase = classnames('bg-white border-2 border-cyan-600 px-3 py-1 rounded-l-full')
const cnButtonFavouriteBase = classnames('bg-white border-2 border-cyan-600 border-l-0 px-3 py-1')
const cnButtonAlsoAskedBase = classnames('bg-white border-2 border-solid border-l-0 border-cyan-600 px-3 py-1 rounded-r-full')
const cnIcon = classnames('w-6', 'h-6');

const DSKeywordGenerator = ({ keyword, selected, favourite, peoplealsoask, onClickKeyword, onClickFavourite, onClickPeopleAlsoAsk }) => {
  const cnButton = classnames(cnButtonBase, {
    'bg-cyan-600 text-white': selected,
    '': !selected,
  });

  const cnButtonFavourite = classnames(cnButtonFavouriteBase, {
    'bg-cyan-600 text-white': favourite,
    '': !favourite,
  });

  const cnButtonAlsoAsked = classnames(cnButtonAlsoAskedBase, {
    'bg-cyan-600 text-white': peoplealsoask,
    '': !peoplealsoask,
  });

  return (
    <div className={cnBase}>
      <button type='button' className={cnButton} onClick={onClickKeyword} data-value={keyword}>
        <span>{keyword}</span>
      </button>
      <button type='button' className={cnButtonFavourite} onClick={onClickFavourite} data-value={keyword}>
        <DSIcon id={favourite ? 'favourite' : 'favouriteInactive'} className={cnIcon} />
      </button>
      <button type='button' className={cnButtonAlsoAsked} onClick={onClickPeopleAlsoAsk} data-value={keyword}>
        <DSIcon id='peopleAlsoAsk' className={cnIcon} />
      </button>
    </div>
  );
}

DSKeywordGenerator.propTypes = {
  keyword: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  favourite: PropTypes.bool,
  peoplealsoask: PropTypes.bool,
  onClickKeyword: PropTypes.func,
  onClickFavourite: PropTypes.func,
  onClickPeopleAlsoAsk: PropTypes.func,
};

DSKeywordGenerator.defaultProps = {
  selected: false,
  favourite: false,
  peoplealsoask: false,
  onClickKeyword: null,
  onClickFavourite: null,
  onClickPeopleAlsoAsk: null,
};

export default DSKeywordGenerator;
