import React from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";
import { DSAccordion } from 'components/DS';
import { LANG } from 'hooks/manageLanguage';

const cnBase = classnames(
	"border-l",
	"bg-white",
	"border-gray-300",
	"overflow-y-scroll",
	"p-6",
	"fixed",
	"bottom-0",
	"top-24",
	"overflow-auto",
	"border-t",
	"max-w-xs",
	"w-80",
);
const cnLeftColumn = classnames(cnBase, "left-0");
const cnRightColumn = classnames(cnBase, "right-0");
const cnTitleBase = classnames("border-l-4", "border-primary", "pl-4");
const cnTitleMargin = classnames(cnTitleBase, "mt-4");
const cnChildrenTitle = classnames(cnTitleMargin, "flex", "justify-between");
const cnValueBase = classnames("flex", "justify-between", "pl-3", "mt-1", "border-l-4", "border-gray-300", "ml-5");
const cnButton = classnames("text-left", "hover:text-primary");
const cnRels = classnames("pl-8", "text-right");

const bindClick = (node) => {
	document.querySelector(`#id-${node.replace(/[^\w ]+/g, '').replace(/ /g, '-')}`).dispatchEvent(new Event('click'));
}

const Top10 = ({ top }) => {
	const cnTexts = classnames("flex", "justify-between", "mt-1");

	return (
		<DSAccordion title={LANG.network.topTen.title} className='mb-8'>
			<div className={cnTexts}>
				<span><strong>{LANG.network.topTen.list.keyword}</strong></span>
				<span><strong>{LANG.network.topTen.list.value}</strong></span>
			</div>
			{
				top && (
					<ul>
						{
							top.map(node =>
								<li key={node.id} className={cnTexts}>
									<button type='button' className={cnButton} onClick={() => bindClick(node.id)}>{node.id}</button>
									<span className={cnRels}>{node.rels}</span>
								</li>
							)
						}
					</ul>
				)
			}
		</DSAccordion>
	)
}

Top10.propTypes = {
	top: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			rels: PropTypes.number,
		})
	)
}
Top10.defaultProps = {
	top: null
}

const KeywordParents = ({ parents }) =>
	<>
		<div className={cnTitleMargin}><strong>{LANG.graphs.columns.parents} [{parents.length}]</strong></div>
		<ul>
			{
				parents.map((node) =>
					<li key={node} className={cnValueBase}>
						<button type='button' className={cnButton} onClick={() => bindClick(node)}>{node}</button>
					</li>
				)
			}
		</ul>
	</>

KeywordParents.propTypes = {
	parents: PropTypes.shape([])
}
KeywordParents.defaultProps = {
	parents: null
}

const KeywordChildren = ({ childs }) =>
	<>
		<div className={cnChildrenTitle}>
			<span><strong>{LANG.graphs.columns.children.title} [{childs.length}]</strong></span>
			{ childs.filter((child) => child.rels).length > 0 && <span><strong>{LANG.graphs.columns.children.weight}*</strong></span> }
		</div>
		<ul>
			{
				childs.sort((a, b) => b.rels - a.rels).map((node) =>
					<li key={node.name} className={cnValueBase}>
						<button type='button' className={cnButton} onClick={() => bindClick(node.name)}>{node.name}</button>
						{ node.rels && <span className={cnRels}>{node.rels}%</span> }
					</li>
				)
			}
		</ul>
		<p className='font-bold mt-8'>{LANG.network.legend.title}</p>
		<p>{LANG.network.legend.description}</p>
	</>

KeywordChildren.propTypes = {
	childs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			node: PropTypes.number,
		})
	)
}
KeywordChildren.defaultProps = {
	childs: null
}

const Suggestion = ({ service, suggestions }) => {
	const cnTitle = classnames("pl-5", "text-sm", "font-bold");
	const cnValue = classnames("pl-8", "mt-1");

	return (
		<DSAccordion title={`${service} [${suggestions.length}]`} classes={{ container: 'mt-2 pb-2', button: { text: cnTitle, icon: 'w-6 h-6 text-primary space-y-0' } }}>
			<ul>
				{
					suggestions.sort((a, b) => a.localeCompare(b)).map((suggestion) =>
						<li key={suggestion} className={cnValue}>{suggestion}</li>
					)
				}
			</ul>
		</DSAccordion>
	)
}

Suggestion.propTypes = {
	service: PropTypes.string,
	suggestions: PropTypes.arrayOf(
		PropTypes.string,
	)
}
Suggestion.defaultProps = {
	service: null,
	suggestions: null,
}

const RightColumn = ({ selected, suggestions, categories, pills }) => {
	const suggestionsList = suggestions.reduce((acc, elem) => {
		if (elem.key === selected) {
			if (acc[elem.service] === undefined) {
				acc[elem.service] = [];
			}
			acc[elem.service].push(...elem.value);
		}
		return acc;
	}, {});

	const categoriesList = categories.filter((cat) => cat.key === selected)[0]?.categories;
	const pillsList = pills?.filter((cat) => cat.key === selected)[0]?.pills;

	const getService = ({ service }) =>
		LANG.graphs.columns.suggestions.values.filter((val) => val.value === service)[0]?.label;

	return (
		<div className={cnRightColumn}>
			{ categoriesList && categoriesList.length > 0 && (
				<>
					<div className={cnTitleMargin}><strong>{LANG.graphs.columns.categories.title}:</strong></div>
					<ul className='mt-2 pl-5 justify-between relative gap-3'>
					{ categoriesList.map((cat) =>
						<li className='flex mt-1 items-center gap-1' key={cat}>
							{cat}
						</li>
					) }
				</ul>
				</>
			) }
			{ pillsList && pillsList.length > 0 && (
				<>
					<div className={cnTitleMargin}><strong>{LANG.graphs.columns.pills.title}:</strong></div>
					<ul className='mt-2 pl-5 justify-between relative gap-3'>
					{ pillsList.map((pill) =>
						<li className='flex mt-1 items-center gap-1' key={pill}>
							{pill}
						</li>
					) }
				</ul>
				</>
			) }
			{ (Object.keys(suggestionsList).length === 0) ?
				<div key='suggestions' className={cnTitleMargin}><strong>{LANG.graphs.columns.suggestions.empty}</strong></div>
			:
				<>
					<div key='suggestions' className={cnTitleMargin}><strong>{LANG.graphs.columns.suggestions.title}:</strong></div>
					{
						Object.entries(suggestionsList)
							.sort(([a], [b]) => a.localeCompare(b))
							.map(([key, value]) =>
							value.length > 0 	&&
								<Suggestion key={key} service={getService({ service: key })} suggestions={value} />
						)
					}
				</>
			}
		</div>
	)
}

RightColumn.propTypes = {
	selected: PropTypes.string,
	suggestions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			service: PropTypes.string,
			value: PropTypes.arrayOf(
				PropTypes.string,
			),
		})
	),
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.arrayOf(
				PropTypes.string,
			),
		})
	),
	pills: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.arrayOf(
				PropTypes.string,
			),
		})
	),
}
RightColumn.defaultProps = {
	selected: null,
	suggestions: null,
	categories: null,
	pills: null,
};

const NetworkColumn = ({ className, mainKeywordInfo, graphKeywordInfo }) => {
	const baseClassName = classnames(cnLeftColumn, className);

	return (
		<>
			<div className={baseClassName}>
				{
					mainKeywordInfo.top10 ?
						<Top10 top={mainKeywordInfo.top10} />
					:
						<p>{LANG.network.noData}</p>
				}
				{
					graphKeywordInfo.parents && graphKeywordInfo.parents.length > 0 && (
						<KeywordParents parents={graphKeywordInfo.parents} />
					)
				}
				{
					graphKeywordInfo.selected && (
						<div className={cnTitleMargin}><strong>{LANG.graphs.columns.selected}:</strong> {graphKeywordInfo.selected.id}</div>
					)
				}
				{
					graphKeywordInfo.children && graphKeywordInfo.children.length > 0 && (
						<KeywordChildren childs={graphKeywordInfo.children} />
					)
				}
			</div>
			{
				mainKeywordInfo.suggestions && mainKeywordInfo.suggestions.length > 0 && graphKeywordInfo.selected && (
					<RightColumn selected={graphKeywordInfo.selected.id} suggestions={mainKeywordInfo.suggestions} categories={mainKeywordInfo.categories} pills={mainKeywordInfo.pills} />
				)
			}
		</>
	);
};

NetworkColumn.propTypes = {
  className: PropTypes.string,
	mainKeywordInfo: PropTypes.shape({
		origin: PropTypes.string,
		top10: PropTypes.arrayOf(
			PropTypes.shape({})
		),
		suggestions: PropTypes.arrayOf(
			PropTypes.shape({})
		),
		categories: PropTypes.arrayOf(
			PropTypes.shape({})
		),
		pills: PropTypes.arrayOf(
			PropTypes.shape({})
		),
	}).isRequired,
	graphKeywordInfo: PropTypes.shape({
		selected: PropTypes.shape({
			id: PropTypes.string
		}),
		parents: PropTypes.arrayOf(
			PropTypes.string
		),
		children: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string
			})
		),
		suggestions: PropTypes.shape({
			amazon: PropTypes.shape({
				keyword: PropTypes.string,
				suggestions: PropTypes.arrayOf(
					PropTypes.string
				),
			}),
			google: PropTypes.shape({
				keyword: PropTypes.string,
				suggestions: PropTypes.arrayOf(
					PropTypes.string
				),
			}),
			youtube: PropTypes.shape({
				keyword: PropTypes.string,
				suggestions: PropTypes.arrayOf(
					PropTypes.string
				),
			}),
		}),
	})
};

NetworkColumn.defaultProps = {
  className: "",
	graphKeywordInfo: null
};

export default NetworkColumn;
