import React from "react";
import PropTypes from "prop-types";
import { classnames } from "tailwindcss-classnames";

const cnBase = classnames("p-6", "bg-white", "rounded");

const DSCard = ({ children, className }) => {
  const cn = classnames(cnBase, className);
  return <div className={cn}>{children}</div>;
};

DSCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
};

DSCard.defaultProps = {
  className: null,
};

export default DSCard;
