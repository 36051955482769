import axios from "axios";
import manageStorage from "hooks/manageStorage";
import refreshToken from "hooks/refreshToken";

const { get } = manageStorage();

const getKeywords = (params) => {
  const urlSearchParams = new URLSearchParams(params);

  refreshToken();

  return axios
    .get(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/keywords?${urlSearchParams}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${get().data.tokens.access.token}`,
          "content-type": "application/json",
        },
      }
    )
    .then((res) => res);
};


const getKeyword = ({ id }) => {
  refreshToken();

  return axios
    .get(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/keywords/${id}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${get().data.tokens.access.token}`,
          "content-type": "application/json",
        },
      }
    )
    .then((res) => res);
};

const getGraph = async (values) => {
  refreshToken();

  return axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/keywords/graph`,
      null,
      {
        params: values,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${get().data.tokens.access.token}`,
        },
      }
    )
    .then((res) => res);
};

const getGraphJson = async (data) => axios.get(`${process.env.REACT_APP_SPLINTER_ENDPOINT}/${data}`).then((res) => res);

const getXls = async (values) => {
  refreshToken();

  return axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/keywords/xls`,
      null,
      {
        params: values,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${get().data.tokens.access.token}`,
        },
      }
    )
    .then((res) => res);
};

const getSuggestions = (params) => {
  const { keyword, service } = params;
  const urlSearchParams = new URLSearchParams(params);

  refreshToken();

  return axios
    .get(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/keywords/suggestions?${urlSearchParams}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${get().data.tokens.access.token}`,
          "content-type": "application/json",
        },
      }
    )
    .then((res) => ({ res, parent: keyword, service }))
    .catch((err) => console.error(err));
}

const getTopRelations = ({ id }) => {
  refreshToken();

  return axios
    .get(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/keywords/topRelations/${id}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${get().data.tokens.access.token}`,
          "content-type": "application/json",
        },
      }
    )
    .then((res) => res);
}


export { getKeywords, getKeyword, getGraph, getGraphJson, getXls, getSuggestions, getTopRelations };
