
/* eslint no-unused-vars: "off" */
import * as d3 from 'd3';

import './PeopleAlsoAskGraph.css';

const textData = (depth, recursion) => {
	let text;
	if (depth === 0) {
		text = {
			class: 'text--origin',
			textAnchor: 'middle',
		};
	} else if (depth < recursion + 1) {
		text = {
			class: 'text--intermediate',
			textAnchor: 'middle',
		};
	} else {
		text = {
			class: 'text--end',
			textAnchor: 'start',
		};
	}
	return text;
};

const findParents = ({ arr = [], node }) => {
	if (node && node.parent) {
		arr.unshift(node.parent);
		findParents({ arr, node: node.parent });
	}
	return arr;
};

const PeopleAlsoAskGraph = (element, data, onChange) => {
	const margin = {top: 20, right: 150, bottom: 20, left: 150};

	const height = element.parentNode.scrollHeight - margin.top - margin.bottom;
	const width = element.parentNode.scrollWidth;

	const highlightSelected = ({ node, parents }) => {
		const highlighted = element.querySelectorAll('.node--highlighted')
		const oldSelected = element.querySelector('.node--selected')
		const selected = element.querySelector(`.node--group#node--${node.data.id}`);
		const p = parents && parents.length > 0 ? element.querySelectorAll(`.node--group${parents.map((d) => `#node--${d.data.id} `)}`) : parents;
		const c = node && node.children?.length > 0 ? element.querySelectorAll(`.node--group${node.children.map((d) => `#node--${d.data.id}`)}`) : [];
		if (highlighted && highlighted.length > 0) {
			highlighted.forEach((d) => d.classList.remove('node--highlighted'));
			highlighted.forEach((d) => d.classList.remove('node--parent'));
		}
		if (oldSelected) {
			oldSelected.classList.remove('node--selected');
		}
		p.forEach((par) => par.classList.add('node--parent'));
		[selected, ...p, ...c].forEach((d) => d.classList.add('node--highlighted'))
		selected.classList.add('node--selected');
	};

	d3.select(element).selectAll('g').remove();

	// append the svg object to the body of the page
	// appends a 'group' element to 'svg'
	// moves the 'group' element to the top left margin
	const svg = d3.select(element)
			.attr("width", width + margin.right + margin.left)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
				.attr("transform", `translate(${margin.left},${margin.top})`);


	const handleZoom = (e) => {
		d3.select('svg g')
			.attr('transform', e.transform);
	}

	const zoom = d3.zoom()
		.interpolate(d3.interpolate)
		.on('zoom', handleZoom);

	const initZoom = () => {
		svg.call(zoom);
	}

	initZoom();

	let i = 0;
	const duration = 750;

	// declares a tree layout and assigns the size
	const treemap = d3.tree().size([height, width]);

	// Assigns parent, children, height, depth
	const root = d3.hierarchy(data, (d) => d.children);
	root.x0 = height / 2;
	root.y0 = width / 2;

	// Collapse after the second level
	root.children.forEach(collapse);

	update(root);

	// Collapse the node and all it's children
	function collapse(d) {
		if(d.children) {
			d._children = d.children
			d._children.forEach(collapse)
			d.children = null
		}
	}

	function update(source) {
		// Assigns the x and y position for the nodes
		const treeData = treemap(root);

		// Compute the new tree layout.
		const nodes = treeData.descendants();
		const links = treeData.descendants().slice(1);

		// Normalize for fixed-depth.
		nodes.forEach((d) => d.y = d.depth * 180);

		// ****************** Nodes section ***************************

		// Update the nodes...
		const node = svg.selectAll('g.node')
				.data(nodes, (d) => { i += 1; return d.id || (d.id = i); });

		// Enter any new modes at the parent's previous position.
		const nodeEnter = node.enter().append('g')
				.attr('class', 'node node--group')
				.attr("transform", (d) => `translate(${source.y0},${source.x0})`)
				.attr('id', d => `node--${d.data.id}` )
			.on('click', click);

		// Add Circle for the nodes
		nodeEnter.append('circle')
				.attr('class', 'node')
				.attr('r', 1e-6)
				.style("fill", (d) =>  d._children ? "#ffffff" : "#e60000");

		// Add labels for the nodes
		nodeEnter
			.append('text')
			.attr("style", "padding: 0 10px;")
			.attr("dy", ".35em")
			.attr("x", (d) => d.children || d._children ? -18 : 18)
			.attr("text-anchor", (d) => d.children || d._children ? "end" : "start")
			.text((d) => d.data.name);

		// UPDATE
		const nodeUpdate = nodeEnter.merge(node);

		/* eslint no-underscore-dangle: "off" */
		/* eslint no-return-assign: "off" */
		/* eslint no-use-before-define: "off" */
		/* eslint no-param-reassign: "off" */

		// Transition to the proper position for the node
		nodeUpdate.transition()
			.duration(duration)
			.attr("transform", (d) => `translate(${d.y},${d.x})`);

		// Update the node attributes and style
		nodeUpdate.select('circle.node')
			.attr('r', 10)
			.style("fill", (d) => d._children ? "#ffffff" : "#e60000")
			.attr('cursor', 'pointer');

		// Remove any exiting nodes
		const nodeExit = node.exit().transition()
				.duration(duration)
				.attr("transform", (d) => `translate(${source.y},${source.x})`)
				.remove();

		// On exit reduce the node circles size to 0
		nodeExit.select('circle')
			.attr('r', 1e-6);

		// On exit reduce the opacity of text labels
		nodeExit.select('text')
			.style('fill-opacity', 1e-6);

		// ****************** links section ***************************

		// Update the links...
		const link = svg.selectAll('path.link')
				.data(links, (d) => d.id);

		// Enter any new links at the parent's previous position.
		const linkEnter = link.enter().insert('path', "g")
				.attr("class", "link")
				.attr('d', (d) => {
					const o = { x: source.x0, y: source.y0 };
					return diagonal(o, o);
				});

		// UPDATE
		const linkUpdate = linkEnter.merge(link);

		// Transition back to the parent element position
		linkUpdate.transition()
				.duration(duration)
				.attr('d', (d) => diagonal(d, d.parent));

		// Remove any exiting links
		const linkExit = link.exit().transition()
				.duration(duration)
				.attr('d', (d) => {
					const o = { x: source.x, y: source.y };
					return diagonal(o, o);
				})
				.remove();

		// Store the old positions for transition.
		nodes.forEach((d) => {
			d.x0 = d.x;
			d.y0 = d.y;
		});

		// Creates a curved (diagonal) path from parent to the child nodes
		function diagonal(s, d) {
			const path = `M ${s.y} ${s.x}
							C ${(s.y + d.y) / 2} ${s.x},
								${(s.y + d.y) / 2} ${d.x},
								${d.y} ${d.x}`
			return path
		}

		// Toggle children on click.
		function click(event, d) {
			if (d.children) {
				d._children = d.children;
				d.children = null;
			} else {
				d.children = d._children;
				d._children = null;
			}
			update(d);
		}

		function centerNode(d) {
			const t = d3.zoomTransform(svg.node());
			let x = -d.y0;
			let y = -d.x0;
			x = x * t.k + width / 2;
			y = y * t.k + height / 2;
			svg.transition().duration(duration).call(zoom.transform, d3.zoomIdentity.translate(x,y));
		}

		highlightSelected({ node: source, parents: findParents({ node: source }) });
		centerNode(source);
		onChange({ node: source });
	}

	return svg.node();
}

export default PeopleAlsoAskGraph;
