import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "tailwindcss-classnames";
import { useQuery } from "react-query";
import { getKeywordRelations } from "api/keywordRelations";
import { DSIcon } from "components/DS";
import SankeyContext from "routes/Sankey/SankeyContext";
import services from "routes/Sankey/enums/services";
import { LANG } from 'hooks/manageLanguage';

const serviceIcon = Object.freeze({
  [services.GOOGLE_SUGGEST]: {
    title:'Sugerencias de Google',
    icon: "info",
  },
  [services.YOUTUBE]: {
    title: 'Youtube',
    icon: "school",
  },
  [services.AMAZON]: {
    title: 'Amazon',
    icon: "shopping-cart",
  },
  'also_asked': {
    title: 'Preguntas en Google',
    icon: "peopleAlsoAsk",
  }
});

const getCategoryIcon = ({ cat }) =>
  LANG.graphs.columns.categories.values.filter((val) => val.stored === cat)[0].icon;

const getServiceData = ({ service, element }) => {
  const suggestion = LANG.graphs.columns.suggestions.values.filter((val) => val.value === service);
  if (suggestion.length > 0)
    return suggestion[0][element];
  return undefined;
}

const SankeyKwInfo = ({ className }) => {
  const { state } = useContext(SankeyContext);

  const keyword = state.columns[state.columns.length - 1];

  const { data, isLoading, isError, error } = useQuery(["keywordCategories", keyword, state.id], () =>
    getKeywordRelations({ keyword_id: state.id, keyword }));

  const baseClassName = classnames(
    "border-l",
    "bg-white",
    "border-gray-300",
    "overflow-y-scroll",
    "p-6",
    className
  );

  return (
    <div className={baseClassName}>
      <div className="border-l-4 border-primary pl-4">
        <h2 className="text-4xl font-bold mb-4">{keyword}</h2>
      </div>
      <div className="space-y-4">
        {isLoading && <p className="p-3">{LANG.graphs.filters.loading}</p>}
        {isError && <>{error?.message}</>}
        {!isLoading && !isError && (
          <div className="flex flex-col gap-4">
            { !isLoading && (
              (data?.data?.results[0]?.categories?.length > 0) ||
              data?.data?.results[0]?.extra)?.pills && JSON.parse(data?.data?.results[0]?.extra)?.pills?.length > 0
             &&
              <>
                <div>
                  <div className="flex space-x-2 items-center mb-2">
                    <DSIcon id='search' className='w-6 h-6' />
                    <h3 className="text-2xl font-bold">{data?.data?.results[0]?.categories?.length > 0 ? LANG.graphs.columns.categories.title : LANG.graphs.columns.pills.title}</h3>
                  </div>
                  <ul>
                   { data.data.results[0].categories.length > 0 ?
                      data.data.results[0].categories.map((cat) =>
                        <li className='flex items-center gap-1 pl-8 mb-1' key={cat}>
                          <DSIcon id={getCategoryIcon({ cat })} className='w-4 h-4' />
                          {cat}
                        </li>
                      )
                      :
                      JSON.parse(data.data.results[0].extra).pills.map((pill) =>
                        <li className='flex items-center gap-1 pl-8 mb-1' key={pill}>
                          <span className='py-2 px-4 border border-gray-400 rounded-3xl'>{pill}</span>
                        </li>
                      )
                    }
                  </ul>
                </div>
              </>
            }
            {
              data.data.results[0].also_asked.length > 0 &&
              <div key='also_asked'>
                <div className="flex space-x-2 items-center mb-2">
                  <DSIcon id={serviceIcon.also_asked.icon} className='w-6 h-6' />
                  <h3 className="text-2xl font-bold">{serviceIcon.also_asked.title}</h3>
                </div>
                <ul>
                  { data.data.results[0].also_asked.sort((a, b) => a.localeCompare(b)).map((value) => (
                    <li className='pl-8' key={value}>{value}</li>
                  ))}
                </ul>
              </div>
            }
            { data.data.results[0].services &&
              Object.entries(JSON.parse(data.data.results[0].services))
                .sort(([a], [b]) => a.localeCompare(b))
                .map(([service, values]) => (
                  values.length > 0 &&
                  <div key={service}>
                    <div className="flex space-x-2 items-center mb-2">
                      <DSIcon id={getServiceData({ service, element: 'icon' })} className='w-6 h-6' />
                      <h3 className="text-2xl font-bold">{getServiceData({ service, element: 'label' })}</h3>
                    </div>
                    <ul>
                      {values.sort((a, b) => a.localeCompare(b)).map((value) => (
                        <li className='pl-8 mb-1' key={value}>{value}</li>
                      ))}
                    </ul>
                  </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

SankeyKwInfo.propTypes = {
  className: PropTypes.string,
};

SankeyKwInfo.defaultProps = {
  className: "",
};

export default SankeyKwInfo;
