/* eslint no-unused-vars: "off" */
import React, { useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { classnames } from 'tailwindcss-classnames';

import { getKeywords, getGraph, getGraphJson } from "api/keywords";

import Layout from 'components/Layout/Layout';
import GraphFilters from 'components/Common/GraphFilters/GraphFilters';
import PeopleAlsoAskGraph from 'routes/PeopleAlsoAsk/components/PeopleAlsoAskGraph';
import PeopleAlsoAskColumn from 'routes/PeopleAlsoAsk/components/PeopleAlsoAskColumn';
import { LANG } from 'hooks/manageLanguage';

const cnContainer = classnames("flex", "flex-col", "w-full");
const cnContent = classnames("flex", "flex-grow", "bg-gray-50", "h-1", "overflow-hidden");
const cnColumn = classnames("flex-shrink-0", "w-96");
const cnGraphContainer = classnames("w-full", "mr-80");
const cnGraph = classnames("w-full");

const PeopleAlsoAsk = () => {
	const { id, slug } = useParams();
	const [ columnData, setColumnData] = useState(undefined);
	const [ filters, setFilters ] = useState(undefined);
	const { data: filtersData, isLoading: isLoadingFilters } = useQuery(["filtersKeyword", slug], () => getKeywords({
		slug,
		sortBy: 'created_at:asc',
	}));
	const { data: relationsData, isLoading: isLoadingRelations } = useQuery(["alsoAskedRelations", id], () => getGraph({
		graph: `peoplealsoask`,
		slug,
		keyword_id: id,
	}));
	const { data: dataJson, isLoading: isLoadingJson } = useQuery(["alsoAskedGraphJson", slug, id], () => getGraphJson(relationsData.data), {
		enabled: !isLoadingRelations,
	});
	const ref = useRef(null);

	const onChange = ({ node }) => {
		setColumnData({ value: node.data.name, parent: node.parent, childs: node.children || null, categories: node.data.categories || null });
	};

	useEffect(() => {
		if (dataJson)
			PeopleAlsoAskGraph(ref.current, dataJson.data, onChange);
	}, [dataJson]);


	useEffect(() => {
		if (filtersData)
			setFilters({
				...filtersData,
				data: {
					...filtersData.data,
					results: filtersData.data.results.filter((el) => el.alsoasked),
				}
			});
	}, [filtersData]);

	return (
		<Layout>
			{
				isLoadingFilters && isLoadingRelations || isLoadingJson ? <p>{LANG.peopleAlsoAsk.loading}</p>
				:
				<>
					<div className={cnContainer}>
						<GraphFilters graph='people-also-ask' data={filters} isLoading={isLoadingFilters} xls={false} selector={false} />
						<div className={cnContent}>
							<PeopleAlsoAskColumn className={cnColumn} data={columnData} />
							<div className={cnGraphContainer}>
								<svg key={`${slug}${id}`} className={cnGraph} ref={ref}  />
							</div>
						</div>
					</div>
				</>
			}
		</Layout>
	)
}

export default PeopleAlsoAsk;