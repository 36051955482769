import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import LoginContext from "context/LoginContext";

import Layout from "components/Layout/Layout";
import { useMutation, useQuery } from "react-query";

import { getUsersKeywords, updateUsersKeywords } from "api/usersKeywords";

import { DSGlossaryKeyword, DSSelect, DSFormCheckbox, DSInput } from 'components/DS';

import { LANG } from 'hooks/manageLanguage';

const roleInfo = Object.freeze({
  'admin': true,
  'user': false,
});

const limit = 300;

let inputTextTimeout;

const Glossary = () => {
  const [ kwList, setKwList ] = useState(undefined);
  const [ filters, setFilters ] = useState({});
  const [ isUpdating, setIsUpdating ] = useState(false);
  const [ page, setPage ] = useState(1);

  const { userLogin } = useContext(LoginContext);
  const { user } = userLogin.data;
  const { id, role } = user;

  const formik = useFormik({
    initialValues: {
      order: LANG.glossary.form.sort.values[0].value,
      keyword: '',
      mine: false,
    },
  });

  const { data, isFetching, refetch } = useQuery(["glossary", filters, page], () => getUsersKeywords({ ...filters, limit, page }));

  const mutation = useMutation((values) => updateUsersKeywords(values), {
    onSuccess() {
      setIsUpdating(false);
      refetch();
    },
  });

  const checkState = ({ keyword }) => keyword.users.filter((kwUser) => kwUser.id === id).length > 0;

  const updateKeyword = ({ keyword, state }) => {
    setIsUpdating(true);

    const params = { id: keyword.id };
    let { users } =  keyword;

    if (state) users.push(user);
    else users = users.filter((kwUser) => kwUser.id !== id);

    const values = { users };
    mutation.mutate({ params, values });
  }

  const Pagination = ({ total }) => (
      <ul className='flex px-10 pt-10 pb-20 justify-center'>
        { [...Array(total)].map((el, idx) =>
          <li key={`pagination-${Math.random()}`}>
            <button type='button' className={`text-primary px-6 py-4 hover:text-gray-500 ${idx + 1 === page ? 'font-bold text-gray-800' : ''}`} onClick={() => setPage(idx + 1)}>{idx + 1}</button>
          </li>
        ) }
      </ul>
    );

  Pagination.propTypes = {
    total: PropTypes.number.isRequired,
  };

  useEffect(() => {
    clearTimeout(inputTextTimeout);

    const time = formik.values.keyword !== filters.value ? 1000 : 0;

    const obj = { sortBy: formik.values.order };
    if (formik.values.keyword) obj.value = formik.values.keyword;
    if (formik.values.keyword) obj.like = 'value';
    if (formik.values.mine) obj.id = id;

    inputTextTimeout = setTimeout(() => {
      setFilters(obj);
    }, time);
  }, [formik.values]);

  useEffect(() => {
    if (data) setKwList(data.data.results);
  }, [data]);


  return (
    <Layout>
      <section className='mt-24 w-full overflow-auto'>
      <div className='flex justify-between items-end gap-10 bg-white fixed top-11 w-full px-10 pt-3 pb-3.5 border-b border-gray-300'>
        <DSSelect
          label={LANG.glossary.form.sort.title}
          id="order"
          name="order"
          value={formik.values.order}
          onChange={formik.handleChange}
          options={LANG.glossary.form.sort.values}
          autocomplete="off"
          disabled={isFetching}
        />
        <DSInput
          label={LANG.glossary.form.keyword}
          id="keyword"
          type="text"
          name="keyword"
          onChange={formik.handleChange}
          value={formik.values.keyword}
          error={formik.touched.keyword && !!formik.errors.keyword}
          errorHint={formik.errors.keyword}
          disabled={isFetching}
        />
        <DSFormCheckbox
          label={LANG.glossary.form.favourites}
          id="mine"
          name="mine"
          value={formik.values.mine}
          onChange={formik.handleChange}
          classes="w-full text-left"
          disabled={isFetching}
        />
      </div>
      { isFetching && <p className='py-20 px-10 w-full text-center'>{LANG.glossary.loading}</p> }
      { !isFetching &&
        (kwList && kwList.length > 0 ?
          <>
            <ul className='py-20 px-10 grid grid-cols-3 lg:grid-cols-4 gap-10'>
              { kwList.map((el) =>
                el.crawled &&
                  <li key={el.id}>
                    <DSGlossaryKeyword
                      keyword={el}
                      state={checkState({ keyword: el })}
                      disabled={isFetching || isUpdating}
                      featured={el.users.length > 0}
                      onClickUpdate={updateKeyword}
                      showUsers={roleInfo[role]}
                    />
                  </li>
              ) }
            </ul>
            { data.data.totalPages > 1 && <Pagination total={data.data.totalPages} /> }
          </>
          : <p className='py-20 px-10 w-full text-center'>{LANG.glossary.form.noResults}</p>
        )
      }
    </section>
    </Layout>
  )
};

export default Glossary;