import axios from "axios";
import manageStorage from "hooks/manageStorage";
import refreshToken from "hooks/refreshToken";

const { get } = manageStorage();

const registerUser = (values) => {
  refreshToken();

  return axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/users`,
      values,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${get().data.tokens.access.token}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => err);
};

const getUsers = (params) => {
  const urlSearchParams = new URLSearchParams(params);

  refreshToken();

  return axios
    .get(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/users?${urlSearchParams}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${get().data.tokens.access.token}`,
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);
};

const getUser = (id) => {
  refreshToken();

  return axios
    .get(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/users/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${get().data.tokens.access.token}`,
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);
};

const updateUser = (id, values) => {
  refreshToken();

  return axios
    .patch(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/users/${id}`,
      values,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${get().data.tokens.access.token}`,
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);
};

const deleteUser = (id) => {
  refreshToken();

  return axios
    .delete(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/users/${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${get().data.tokens.access.token}`,
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);
};

const reactivateUser = (token) =>
  axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/auth/reactivate/${token}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);

const activateUser = (token) =>
  axios
    .patch(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/auth/activate/${token}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);

const resetPassword = ({ values, token }) =>
  axios
    .post(
      `${process.env.REACT_APP_SPLINTER_ENDPOINT}/v${process.env.REACT_APP_SPLINTER_ENDPOINT_VERSION}/auth/reset-password?token=${token}`,
      values,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
    .then((res) => res)
    .catch((error) => error);

export { registerUser, getUsers, getUser, updateUser, deleteUser, reactivateUser, activateUser, resetPassword };
