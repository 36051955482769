import React from 'react';
import PropTypes from 'prop-types';
import { classnames } from 'tailwindcss-classnames';

const cnBase = classnames("flex", "flex-col", "gap-2", "border", "rounded", "py-3", "px-2", "relative");
const cnInput = classnames("absolute", "opacity-0", "left-0", "top-0");
const cnTitle = classnames("text-sm", "font-bold");
const cnImage = classnames("w-full", "rounded")
const cnDescription = classnames("text-sm", "text-left");

const GraphRadio = ({ title, description, image, value, name, onChange, checked, className }) => {
  const cnComponent = classnames(cnBase, className, {
    "border-gray-300 bg-gray-100 cursor-pointer": !checked,
    "border-primary": checked,
  });

  const handleInput = (e) => {
    onChange(e);
  };

  return (
    <>
      <input type='radio' value={value} id={`${name}-${value}`} name={name} onChange={handleInput} className={cnInput} />
      <label htmlFor={`${name}-${value}`} className={cnComponent}>
        <p className={cnTitle}>{title}</p>
        <img className={cnImage} src={image} alt='Imagen de gráfico' />
        <p className={cnDescription}>{description}</p>
      </label>
    </>
  );
};

GraphRadio.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

GraphRadio.defaultProps = {
  className: '',
}

export default GraphRadio;